import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion";
import { Tooltip as Mensaje } from "react-tooltip";
import { cambiarEstadoInscYOMensu, eliminarAfiliado, registrarAcum, registrarAsistencia, registrarDatosAfiliado, registrarDebiendo, registrarInscripYMens, registrarNinguno, registrarNumeracionFactura, registrarPagado, registrarPrecios, retirarAfiliacion } from '../controllers/configuracionFirebase';
import { facturacion } from '../controllers/pdfsFacturacion';
import { saveAs } from 'file-saver';
import { exportToExcel } from '../controllers/xlsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';

function Afiliacion({ afiliados, preciosEstablecidos, numeroFactura, usuario }) {

  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ edadRegistrada, setEdadRegistrada ] = useState(0);
  const [ afiliadoPago, setAfiliadoPago ] = useState();
  const [ descripcion, setDescripcion ] = useState('');
  const [ renovacion, setRenovacion ] = useState();
  const [ globalFilter, setGlobalFilter ] = useState(null);
  const [ pdfUrl, setPdfUrl ] = useState();
  const [ dialogafiliacion, setDialogAfiliacion ] = useState(false);
  const [ dialogFacturaP, setDialogFacturaP ] = useState(false);
  const [ dialogPrecios, setDialogPrecios ] = useState(false);
  const [ dialogAsistencias, setDialogAsistencias ] = useState(false);
  const [ selectedAfiliado, setSelectedAfiliado ] = useState(null);
  const [ date, setDate ] = useState(null);
  const fechaActual = new Date();

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ];

  addLocale('es', {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  });

  const dateTemplate = (date) => {
    let markedDate = false;
    if(selectedAfiliado){
      if(selectedAfiliado.asistencias){
        if (selectedAfiliado.asistencias.length !== 0) {
          const auxDateCalendar = (date.month + 1) + '/' + date.day + '/' + date.year;
          const buscarDate = selectedAfiliado.asistencias.filter((sele)=>{return sele === auxDateCalendar});
          if(buscarDate.length !== 0){
            markedDate = true;
          }
        }
        
        if (markedDate) {
          return <strong className='shadow' style={{ background: 'rgb(49, 123, 207)', borderRadius: '50%', width: '2.5rem', height: '2.5rem',
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'
           }}>{date.day}</strong>;
        }
      }
    }

    return date.day;
  }

  const arreglarAsistencias = () =>{
    if(selectedAfiliado){
      if(selectedAfiliado.asistencias){
        const auxAsistenciaObj = {asistencias: 0}
        selectedAfiliado.asistencias.forEach((sele)=>{
          const separarFecha = sele.split('/');
          const mes = meses[parseInt(separarFecha[0]) - 1];
          auxAsistenciaObj.mes = mes;
          auxAsistenciaObj.asistencias++;
        })
        return <p className='fs-5'>{`${selectedAfiliado? selectedAfiliado.nombre : ''} Asistió ${auxAsistenciaObj.asistencias} clases en el mes de ${auxAsistenciaObj.mes}.`}</p>
      }
    }
  }

  const reiniciarAsistenciasEHistorial = () =>{
    afiliados.forEach((resu)=>{
      delete resu.totalAPagar;

      if(!resu.modoMensu){
        resu.modoMensu = '1xsemana';
      }

      resu.pagoInsc = 'No';
      resu.pagoMensu = 'No';
      resu.fechaPago = '2025-1-15';
      resu.asistencias = [];
      resu.hist = [];
      for (let i = 1; i <= 12; i++) {
      if(i === 12){
        resu.hist.push({
          title: 'Ninguno',
          mes: meses[i - 1]
        });
      }else{
        resu.hist.push({
          title: 'Ninguno',
          mes: meses[i - 1]
        });
      }
      registrarDatosAfiliado(resu, resu.ti);
      }
    })
  }

  const registroAsistencia = (date) =>{
    const auxSelectedAfi = selectedAfiliado;
    setDate(date);
    const auxDate = new Date(date);
    const arreglarDate = auxDate.toLocaleString('en-US',{ 
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'});
    
    if(selectedAfiliado.asistencias){
      const buscarAsistencia = selectedAfiliado.asistencias.filter((asi)=>{return asi === arreglarDate});
      if(buscarAsistencia.length === 0){
        const envioAsistencias = [...selectedAfiliado?.asistencias, arreglarDate];
        registrarAsistencia(selectedAfiliado, envioAsistencias, 'registrada');
        auxSelectedAfi.asistencias = envioAsistencias;
        setSelectedAfiliado(auxSelectedAfi);
      }else{
        const buscarDiferentesAsistencias = selectedAfiliado.asistencias.filter((asi)=>{return asi !== arreglarDate});
        registrarAsistencia(selectedAfiliado, buscarDiferentesAsistencias, 'eliminada');
        auxSelectedAfi.asistencias = buscarDiferentesAsistencias;
        setSelectedAfiliado(auxSelectedAfi);
      }
    }else{
      registrarAsistencia(selectedAfiliado, [arreglarDate], 'registrada');
      auxSelectedAfi.asistencias = [arreglarDate];
      setSelectedAfiliado(auxSelectedAfi);
    }
  }

  const detalles = [{
    detalle: 'Inscripciones PS.', cant: afiliados.filter((afi)=>{return afi.pagoInsc === 'Si'}).length, valor: (afiliados.filter((afi)=>{return afi.pagoInsc === 'Si'}).length * (preciosEstablecidos ? parseInt(preciosEstablecidos.insc) : 0)).toLocaleString(), color: 'bg-success', bg: 'rgb(25, 135, 84)'
  },{
    detalle: 'Mensualidades PS.', cant: afiliados.filter((afi)=>{return afi.pagoMensu === 'Si'}).length, valor: (afiliados.filter((afi)=>{return afi.pagoMensu === 'Si'}).length * (preciosEstablecidos ? parseInt(preciosEstablecidos.mensu) : 0)).toLocaleString(), color: 'bg-success', bg: 'rgb(25, 135, 84)'
  },{
    detalle: 'Inscripciones Pend.', cant: afiliados.filter((afi)=>{return afi.pagoInsc === 'No' || !afi.pagoInsc}).length, valor: (afiliados.filter((afi)=>{return afi.pagoInsc === 'No' || !afi.pagoInsc}).length * (preciosEstablecidos ? parseInt(preciosEstablecidos.insc) : 0)).toLocaleString(), color: 'bg-danger', bg: 'rgb(220, 53, 69)'
  },{
    detalle: 'Mensualidades Pend.', cant: afiliados.filter((afi)=>{return afi.pagoMensu === 'No' || !afi.pagoMensu}).length, valor: (afiliados.filter((afi)=>{return afi.pagoMensu === 'No' || !afi.pagoMensu}).length * (preciosEstablecidos ? parseInt(preciosEstablecidos.mensu) : 0)).toLocaleString(), color: 'bg-danger', bg: 'rgb(220, 53, 69)'
  },{
    detalle: 'Paz y Salvo.', cant: afiliados.filter((afi)=>{return afi.pagoInsc === 'Si' && afi.pagoMensu === 'Si'}).length, valor: ((afiliados.filter((afi)=>{return afi.pagoInsc === 'Si' && afi.pagoMensu === 'Si'}).length * (preciosEstablecidos ? parseInt(preciosEstablecidos.insc) : 0)) + (afiliados.filter((afi)=>{return afi.pagoInsc === 'Si' && afi.pagoMensu === 'Si'}).length * (preciosEstablecidos ? parseInt(preciosEstablecidos.mensu) : 0))).toLocaleString(), color: 'bg-success', bg: 'rgb(25, 135, 84)'
  }]

  const calendario = [
    {columna: [
      {fechaMes: ['Enero', 'Abril', 'Julio', 'Octubre'],
      fechaAbreMes: ['Ene', 'Abr', 'Jul', 'Oct']}
    ]},
    {columna: [
      {fechaMes: ['Febrero','Mayo', 'Agosto', 'Noviembre'],
      fechaAbreMes: ['Feb','May', 'Ago', 'Nov']}
    ]},
    {columna: [
      {fechaMes: ['Marzo', 'Junio', 'Septiembre', 'Diciembre'],
      fechaAbreMes: ['Mar', 'Jun', 'Sep', 'Dic']}
    ]}
  ]

  const header = (
    <div className="d-flex justify-content-start align-items-center">
      <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
      </span>
    </div>
  );
  
  function calcularEdad(fecha) {
    var hoy = new Date();
    var fechaNacimiento = new Date(fecha);
    var edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    var m = hoy.getMonth() - fechaNacimiento.getMonth();

    // Verificar si ya cumplió años en el mes actual
    if (m < 0 || (m === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
      edad--;
    }

    return edad;
  }

  function registrarNadadores(e){
    const fecha = document.getElementById('fechaNacimiento').value

    if(fecha !== ""){
      setEdadRegistrada(calcularEdad(fecha));
    }
  }

  function onSubmit(e){
    e.preventDefault();
    const fecha = new Date();
    const fechaPago = fecha.toLocaleString('en-US',{
      year: 'numeric',
      day: 'numeric',
      month: 'numeric'
    })

    let fechaFormateada = new Date(fechaPago);
    fechaFormateada.setMonth(fechaFormateada.getMonth() + 1);
    fechaFormateada.setDate(5);
    fechaFormateada = fechaFormateada.toLocaleString('en-US',{
      year: 'numeric',
      day: 'numeric',
      month: 'numeric'
    })
    const afiliado = {
      nombre: e.target.nombre.value,
      ti: e.target.identificacion.value,
      cel: e.target.cel.value,
      fechaNacimiento: e.target.fechaNacimiento.value,
      edad: edadRegistrada,
      genero: e.target.genero.value,
      afiliado: true,
      fechaPago:  e.target.fechaPago.value,
      observ: e.target.observacion.value,
      acum: renovacion ? renovacion.acum: '0',
      hist: renovacion ? renovacion.hist : [],
      asistencias: renovacion ? renovacion.asistencias : []
    }

    for (let i = 1; i <= 12; i++) {
      if(i === 12){
        afiliado.hist.push({
          title: 'Ninguno',
          mes: meses[i - 1]
        });
      }else{
        afiliado.hist.push({
          title: 'Ninguno',
          mes: meses[i - 1]
        });
      }
    }

    registrarDatosAfiliado(afiliado, afiliado.ti);
    setEdadRegistrada(0);
    setRenovacion();
    e.target.reset();
  }

  function generarNumeroFactura(numeroFactura) {
    let numeroActual = parseInt(numeroFactura.split('-')[1]);

    // Incrementa el número de factura
    numeroFactura = numeroActual + 1;
  
    // Formatea el número con ceros a la izquierda para que tenga 4 dígitos
    let numeroFormateado = numeroFactura.toString().padStart(4, '0');
  
    // Construye la numeración completa con el prefijo
    let numeracionCompleta = `ORCV-${numeroFormateado}`;
  
    return numeracionCompleta;
  }

  function numeracionFactura(){
    let numero = numeroFactura;
    if(numero !== 'ninguna'){
      numero = generarNumeroFactura(numeroFactura);
    }else{
      numero = 'ORCV-0000';
    }
    return numero;
  }

  function descargar(){
    const fecha = new Date();
    const mes_anio = fecha.toLocaleString('ES-es', {
      month: 'short',
      year: 'numeric'
    })

    switch (descripcion) {
      case 'Inscripcion':
        saveAs(pdfUrl, `Inscripcion año 2025.pdf`);
        break;
      case 'Mensualidad':
        saveAs(pdfUrl, `Mensualidad ${mes_anio}.pdf`);
        break;
      case 'Entradas':
        saveAs(pdfUrl, `Entradas ${mes_anio}.pdf`);
        break;
      case `Inscripcion y\nMensualidad`:
        saveAs(pdfUrl, `Mensualidad ${mes_anio} - Inscripcion año 2025.pdf`);
        break;
      case `Inscripcion y\nMensualidad y\nAbono`:
        saveAs(pdfUrl, `Mensualidad - Abono ${mes_anio} - Inscripcion año 2025.pdf`);
        break;
      case `Inscripcion y\nMensualidad y\nEntradas`:
        saveAs(pdfUrl, `Mensualidad - Entradas ${mes_anio} - Inscripcion año 2025.pdf`);
        break;
      case `Abono`:
        saveAs(pdfUrl, `Abonó ${mes_anio}.pdf`);
        break;
      case `Mensualidad y\nEntradas`:
        saveAs(pdfUrl, `Mensualidad - Entradas ${mes_anio}.pdf`);
        break;
      case `Inscripcion y\nEntradas`:
        saveAs(pdfUrl, `Entradas ${mes_anio} - Inscripcion año 2025.pdf`);
        break;
      case 'personalizada':
        saveAs(pdfUrl, `Factura Personalizada.pdf`);
        break
      default:
        break;
    }
  }

  function restablecerPdf(afi){
    setDialogFacturaP(true);
    setAfiliadoPago(afi);
    setPdfUrl();
  }

  function acccionesFacturaE(){
    registrarNumeracionFactura(numeracionFactura());
    registrarInscripYMens(descripcion, afiliadoPago.ti);
  }

  function arreglarAcum(acum, ti, observ, bono){
    const saldo = parseInt(acum);
    const auxBono = parseInt(bono === '' ? 0 : bono);
    
    let auxAcum = saldo;
    if(auxBono !== 0){
      auxAcum = saldo - auxBono;
    }

    if(auxAcum < 0){
      auxAcum = 0;
    }

    registrarAcum(auxAcum, ti, observ);
    afiliadoPago.acum = auxAcum;
    setAfiliadoPago(afiliadoPago);
  }

  function generarYactualizar(acum, ti, observ, bono, entrada){
    arreglarAcum(acum, ti, observ, bono);
    facturacion(selectedAfiliado, setPdfUrl, numeracionFactura(), `Inscripcion y\nMensualidad`, setDescripcion, setAfiliadoPago, 
            {insc: document.getElementById('insc').value,
            mensu: document.getElementById('mensu').value}, 
            document.getElementById('personalizadoDesc').value, document.getElementById('bono').value, entrada);
  }

  function onSubmitPrecios(e){
    e.preventDefault();

    const insc = e.target.insc.value;
    const mensu = e.target.mensu.value;

    const precios = {
      insc,
      mensu
    }

    registrarPrecios(precios);
  }

  const agregarAfiliado = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button icon="pi pi-plus" severity="success" raised tooltip='Afiliar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogAfiliacion(true)}/>
        <Button icon="pi pi-dollar" className='ms-1' tooltip='Modificar Precios' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} raised severity='secondary' onClick={() => setDialogPrecios(true)}></Button>
        <Button icon="pi pi-file-export" tooltip='Exportar xlsx' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} className='ms-1' style={{borderRadius: '50%'}} raised severity='primary' onClick={() => exportToExcel(afiliados)}></Button>
        {usuario.nombreclub === 'PROWEBSPORTS' ?(
          <Button icon="pi pi-times" tooltip='Reiniciar (Asis, Hist)' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} className='ms-1' style={{borderRadius: '50%'}} raised severity='danger' onClick={() => reiniciarAsistenciasEHistorial()}></Button>
        ):(
          <></>
        )}
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={[data]} tableStyle={{ maxWidth: '60rem', margin: 'auto'}}>
        <Column field="fechaNacimiento" header="Fecha Nto" style={{ width: '110px'}}></Column>
        <Column field="edad" header="Edad" style={{ width: '80px'}}></Column>
        <Column field="genero" header="Sexo" style={{ width: '80px'}}></Column>
        <Column field="afiliado" header="Afiliado" body={(afi)=> <span className={afi.afiliado ? 'pi pi-check-circle text-success' : 'pi pi-times-circle text-danger'}></span>} style={{ width: '80px'}}></Column>
        <Column field="fechaPago" header="Vence" style={{ width: '110px'}}></Column>
      </DataTable>
    );
  };

  let items = [
    {label: 'Facturación', items: [
      { label: 'Factura', icon: 'pi pi-file-pdf', command: () => restablecerPdf(selectedAfiliado)},
    ]},
    {label: 'Acciones', items:[
      { label: 'Asistencias', icon: 'pi pi-calendar-times', command: () => setDialogAsistencias(true)},
      { label: 'Retirar', icon: 'pi pi-ban', command: () => retirarAfiliacion(selectedAfiliado)},
      { label: 'Modificar', icon: 'pi pi-user-edit', command: () => modificacionesAfiliado()},
      { label: 'Eliminar', icon: 'pi pi-times', command: () => eliminarAfiliado(selectedAfiliado.ti, setSelectedAfiliado)},
    ]},  
  ];

  function styleCalendario(ms){
    let estilo = '';
    if(selectedAfiliado){
      selectedAfiliado.hist.forEach((historial)=>{
        if(historial.mes === ms){
          switch (historial.title) {
            case 'Pagado':
              estilo = 'pago';
              break;
            case 'Debiendo':
              estilo = 'debe';
              break;
            case 'Ninguno':
              estilo = 'ninguno';
              break;
          
            default:
              break;
          }
        }
      })
    }
    return estilo;
  }

  function modificarEstadoPago(ms){
    if(selectedAfiliado){
      selectedAfiliado.hist.forEach((his, indexHis)=>{
        if(his.mes === ms){
          switch (his.title) {
            case 'Pagado':
              registrarDebiendo(afiliadoPago, afiliadoPago.ti, indexHis);
              break;
            case 'Debiendo':
              registrarNinguno(afiliadoPago, afiliadoPago.ti, indexHis);
              break;
            case 'Ninguno':
              registrarPagado(afiliadoPago, afiliadoPago.ti, indexHis);
              break;
            default:
              break;
          }
        }
      })
    }
  }

  const valueTemplate = (value) => {
    return (
        <React.Fragment>
          {value}/<b>{afiliados.length}</b>
        </React.Fragment>
    );
  };

  function modificacionesAfiliado(){
    setRenovacion(selectedAfiliado);
    setDialogAfiliacion(true);
  }

  useEffect(() =>{
    if(!dialogafiliacion){
      setRenovacion();
      setSelectedAfiliado(null);
    }
  },[dialogafiliacion])

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='bg-light shadow p-2 position-relative'>
          <div className='d-flex flex-wrap justify-content-around aling-items-center'>
            {detalles.map((det, indexDet)=>{
              return <div key={indexDet} className='p-1 m-1' style={{width: '200px', border: '1px solid gray', borderRadius: '20px', boxShadow: '-1px 2px 2px black'}}>
                <h6 style={{fontFamily: "Poppins"}}>{det.detalle}</h6>
                <ProgressBar value={det.cant} displayValueTemplate={valueTemplate} color={det.bg} style={{ height: '16px', fontSize: '14px' }}></ProgressBar>
                <p style={{fontFamily: "Poppins"}}>{'$' + det.valor}</p>
              </div>
            })}
          </div>
          <Toolbar left={agregarAfiliado}></Toolbar>
          <Message severity="info" text={`Tenemos ${afiliados.length} afiliados al club y el numero actual de la factura es ${numeracionFactura()}`} />
          <DataTable value={afiliados} selectionMode="single" selection={selectedAfiliado} onSelectionChange={(e) => setSelectedAfiliado(e.value)} dragSelection expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} header={header} globalFilter={globalFilter} size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '65rem', margin: 'auto'}}>
            <Column expander style={{ width: '3em' }} />
            <Column field="nombre" sortable header="Nombre" body={(insc) => insc.afiliado ? insc.nombre : <span>{insc.nombre}<Tag icon='pi pi-times-circle' value='Retirado/a' severity='danger'></Tag></span>} style={{ width: '160px', fontWeight: 'bold' }}></Column>
            <Column field="ti" sortable header="Identificación" style={{ width: '80px'}}></Column>
            <Column field="totalAPagar" sortable header="Debe" body={(afi) => afi.totalAPagar ? `$${afi.totalAPagar}` : '$0'} style={{ width: '60px'}}></Column>
            <Column field="acum" sortable header="Saldo" body={(afi) => afi.acum ? `$${afi.acum}` : '$0'} style={{ width: '60px'}}></Column>
            <Column field="observ" sortable header="Observaciones" style={{ width: '60px'}}></Column>
          </DataTable>
        </div>
        <Dialog visible={dialogafiliacion} style={{ width: '46rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={renovacion ? 'Modificaciones' : 'Afiliación Al Club'} modal className="p-fluid" onHide={() => setDialogAfiliacion(false)}>
          <div className="field">
            <form onSubmit={onSubmit}>
              <div className='d-flex flex-wrap justify-content-between'>
                <div className="form-floating mb-2">
                  <input type="text" maxLength={25} className='form-control' id="floatingInput" name="nombre" defaultValue={renovacion ? renovacion.nombre : ''} placeholder='Nombre Completo' required/>
                  <label htmlFor="floatingInput">Nombre Completo</label>
                </div>
                <div className="form-floating mb-2">
                  <input type="number" className='form-control' id="floatingInput" name="identificacion" defaultValue={renovacion ? renovacion.ti : ''} placeholder='Identificación' required/>
                  <label htmlFor="floatingInput">Identificación</label>
                </div>
                <div className="form-floating mb-2">
                  <input type="date" className='form-control' id="fechaNacimiento" name="fechaNacimiento" defaultValue={renovacion ? renovacion.fechaNacimiento : ''} placeholder='Fecha de Nacimiento' required/>
                  <label htmlFor="fechaNacimiento">Fecha Nacimiento</label>
                </div>
                <div className="form-floating mb-2">
                  <select name="genero" className='form-select' id="floatingInput" onChange={registrarNadadores} required>
                    <option value="">Elige</option>
                    <option value="Femenino">Femenino</option>
                    <option value="Masculino">Masculino</option>
                  </select>
                  <label htmlFor="floatingInput">Genero</label>
                </div>
                <div className="form-floating mb-2">
                  <input type="number" className='form-control' id="floatingInput" name="cel" defaultValue={renovacion ? renovacion.cel : ''} placeholder='Numero de celular' required/>
                  <label htmlFor="floatingInput">Numero Celular</label>
                </div>
                <div className="form-floating mb-2">
                  <input type="text" className='form-control' id="floatingInput" name="observacion" defaultValue={renovacion ? renovacion.observ : ''} placeholder='Observación Pend.' required/>
                  <label htmlFor="floatingInput">Observación Pend.</label>
                </div>
                <div className="form-floating mb-2">
                  <input type="date" className='form-control' id="floatingInput" name="fechaPago" defaultValue={renovacion ? renovacion.fechaPago : ''} placeholder='Vence Mensualidad' required/>
                  <label htmlFor="floatingInput">Vence Mensualidad</label>
                </div>
              </div>
              <Button label={renovacion ? 'Guardar Cambios' : 'Afiliar'} severity='success' raised style={{borderRadius: '10px'}}></Button>
              {renovacion ? (
                <Button type='button' className='mt-2' label='Cancelar' severity='secondary' raised style={{borderRadius: '10px'}} onClick={() => setRenovacion()}></Button>
              ):(
                <></>
              )}
            </form>
            <hr />
            <span>{`La inscripción esta en $${preciosEstablecidos ? parseInt(preciosEstablecidos.insc).toLocaleString() : 0} y la mensualidad en $${preciosEstablecidos ? parseInt(preciosEstablecidos.mensu).toLocaleString() : 0}`}</span>
            <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
              <b>Importante:</b> Para asegurar el mejor funcionamiento al calcular la edad, evita hacer copiar y pegar o escribir directamente en el campo de la fecha de nacimiento. En su lugar, te recomendamos buscar directamente la fecha de nacimiento del deportista en el calendario para asegurarte de que la información sea precisa y se ingrese correctamente.
            </div>
          </div>
        </Dialog>
        <Dialog visible={selectedAfiliado ? true : false} style={{ width: 'auto' }} header="Configuraciones" modal className="p-fluid" onHide={() => setSelectedAfiliado()}>
          <div className="field">
            <Menu model={items}/>
          </div>
        </Dialog>
        <Dialog visible={pdfUrl ? true : false} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Factura" modal className="p-fluid" onHide={() => setPdfUrl()}>
          <div className="field">
            <div className='mb-2' style={{width: '100%', height: '555px'}}>
              <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <Button label='Confirmar Factura' icon="pi pi-check" severity='success' className='p-2' style={{borderRadius: '10px'}} onClick={() => acccionesFacturaE()}></Button>
              <Button icon="pi pi-download" className='p-2' text tooltip='Descargar Factura' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '10px'}} onClick={() => descargar()}></Button>
            </div>
            <div className="alert alert-success" role="alert">
              <h4 className="alert-heading">RECOMENDACIONES!</h4>
              <p>Para garantizar que el número de facturas no se repita al descargar y enviar el documento, primero confirme la factura. Esto actualizará el estado de la inscripción o la mensualidad, indicando que el alumno ha realizado el pago. Por último, haga clic en "Descargar" para enviar la factura.
              </p>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogFacturaP} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`Factura ${numeracionFactura()}`} modal className="p-fluid" onHide={() => setDialogFacturaP(false)}>
          <div className="field">
            <div className='d-flex flex-wrap justify-content-around'>
              <div className="form-floating mb-1">
                <input type="number" className='form-control' id="insc" defaultValue={0}/>
                <label htmlFor="insc">$ ¿Pago inscripción?</label>
              </div>
              <div className="form-floating mb-1">
                <input type="number" className='form-control' id="mensu" defaultValue={0}/>
                <label htmlFor="mensu">$ ¿Pago mensualidad?</label>
              </div>
              <div className="form-floating mb-1">
                <input type="number" className='form-control' id="entra" defaultValue={0}/>
                <label htmlFor="entra">$ ¿Pago entradas?</label>
              </div>
              <div className="form-floating mb-1">
                <input type="number" className='form-control' id="saldo" defaultValue={selectedAfiliado ? selectedAfiliado.acum : 0}/>
                <label htmlFor="saldo">$ ¿Saldo pendiente?</label>
              </div> 
              <div className="form-floating mb-1">
                <input type="number" className='form-control' id="bono" defaultValue={0}/>
                <label htmlFor="bono">$ ¿Cuanto va abonar al sdo?</label>
              </div> 
              <div className="form-floating mb-1">
                <input type="text" className='form-control' id="personalizadoDesc" defaultValue={selectedAfiliado ? selectedAfiliado.observ : ''}/>
                <label htmlFor="personalizadoDesc">Observación Pend.</label>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <Button className='mb-1 p-2 btn btn-success' icon='pi pi-check-circle' type="button" label='Generar Factura y Actualizar Saldo.' onClick={() => generarYactualizar(document.getElementById('saldo').value, selectedAfiliado.ti, document.getElementById('personalizadoDesc').value, document.getElementById('bono').value, document.getElementById('entra').value)} style={{borderRadius: '10px', maxWidth: '350px'}}/>
            </div>
            <hr className='m-1'/>
            <div className='d-flex flex-wrap justify-content-around'>
              <Button className='mb-1 p-2 btn btn-success' icon='pi pi-file-pdf' type="button" label='Generar Factura' onClick={() => facturacion(selectedAfiliado, setPdfUrl, numeracionFactura(), `Inscripcion y\nMensualidad`, setDescripcion, setAfiliadoPago, 
              {insc: document.getElementById('insc').value,
              mensu: document.getElementById('mensu').value}, 
              document.getElementById('personalizadoDesc').value, document.getElementById('bono').value, document.getElementById('entra').value)} style={{borderRadius: '10px', maxWidth: '180px'}}/>
              <Button className='mb-1 p-2 btn btn-secondary' icon='pi pi-dollar' type="button" label='Actualizar Saldo' onClick={() => arreglarAcum(document.getElementById('saldo').value, selectedAfiliado.ti, document.getElementById('personalizadoDesc').value, document.getElementById('bono').value)} style={{borderRadius: '10px', maxWidth: '180px'}}/>
            </div>
            <div className='d-flex flex-wrap justify-content-around'>
              <Button className={selectedAfiliado?.pagoInsc === 'Si' ? 'btn btn-success' : 'btn btn-danger'} icon='pi pi-dollar' label={selectedAfiliado?.pagoInsc === 'Si' ? 'Pago Inscripción' : 'Debe Inscripción'} style={{borderRadius: '10px', maxWidth: '190px'}}
              onClick={() => cambiarEstadoInscYOMensu('Inscripcion', selectedAfiliado?.ti, selectedAfiliado, setSelectedAfiliado)}></Button>
              <Button className={selectedAfiliado?.pagoMensu === 'Si' ? 'btn btn-success' : 'btn btn-danger'} icon='pi pi-dollar' label={selectedAfiliado?.pagoMensu === 'Si' ? 'Pago Mensualidad' : 'Debe Mensualidad'} style={{borderRadius: '10px', maxWidth: '200px'}}
              onClick={() => cambiarEstadoInscYOMensu('Mensualidad', selectedAfiliado?.ti, selectedAfiliado, setSelectedAfiliado)}></Button>
            </div>
            <div className="alert alert-success mt-2" role="alert">
              <h5 className='mt-2'>{`El afiliado tiene un saldo de $${selectedAfiliado ? selectedAfiliado.acum : 0}`}</h5>
            </div>
            <hr className='mb-1'/>
            <div className='mb-2'>
              <div className='d-flex flex-wrap justify-content-around mb-1'>
                <div className='h-100 d-flex align-items-center' style={{color: '#C6FFDD', textShadow: '0 0 2px black'}}>
                  <i className='pi pi-check-circle'></i>
                  <span>Pagados</span>
                </div>
                <div className='h-100 d-flex align-items-center' style={{color: '#FFC6C6', textShadow: '0 0 2px black'}}>
                  <i className='pi pi-times-circle'></i>
                  <span>Debiendo</span>
                </div>
                <div className='h-100 d-flex align-items-center' style={{color: 'rgb(192, 192, 192)', textShadow: '0 0 2px black'}}>
                  <i className='pi pi-circle'></i>
                  <span>Ninguno</span>
                </div>
              </div>
              <div className='p-2' style={{border: '1px solid #D6D6D6', borderRadius: '5px'}}>
                <div className='d-flex justify-content-center align-items-center'>
                  <span className='pt-1' style={{fontWeight: 'bold'}}>{fechaActual.getFullYear()}</span>
                </div>
                <hr className='mt-1'/>
                <div className='d-flex justify-content-around'>
                  {calendario.map((calen, indexCalen)=>{
                    return <div key={indexCalen} className='w-100 d-flex flex-column'>
                      {calen.columna[0].fechaAbreMes.map((ms, indexMs)=>{
                        return <span key={indexMs} className={`w-100 p-2 meses text-center ${styleCalendario(calen.columna[0].fechaMes[indexMs])}`} style={{borderRadius: '5px', cursor: 'pointer'}} 
                        onClick={() => modificarEstadoPago(calen.columna[0].fechaMes[indexMs])}>
                          <i className={styleCalendario(calen.columna[0].fechaMes[indexMs]) === 'pago' ? 'pi pi-check-circle me-1' : 'pi pi-times-circle me-1'}></i>
                          {ms}
                        </span>
                      })}
                  </div>
                  })}
                </div>  
              </div>
            </div>
            <div className="alert alert-success mt-2" role="alert">
              <h4 className="alert-heading">RECOMENDACIONES!</h4>
              <p>Para llevar un registro de los pagos en el calendario, simplemente selecciona el mes en el que el alumno realizó el pago y luego haz clic. Los cuadros en color verde indican que el mes está pagado, el color rojo indica que aún está debiendo, y el color gris indica que no cuenta como pagado ni debiendo.
              </p>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogPrecios} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Precios establecidos" modal className="p-fluid" onHide={() => setDialogPrecios(false)}>
          <div className="field">
            <div className="mb-2">
              <form onSubmit={onSubmitPrecios}>
                <div className="form-floating mb-2">
                  <input type="number" className='form-control' id="inscc" name='insc' defaultValue={preciosEstablecidos ? preciosEstablecidos.insc : 0} required/>
                  <label htmlFor="inscc">$ Inscripción</label>
                </div>
                <div className="form-floating mb-2">
                  <input type="number" className='form-control' id="mensuu" name='mensu' defaultValue={preciosEstablecidos ? preciosEstablecidos.mensu : 0} required/>
                  <label htmlFor="mensuu">$ Mensualidad</label>
                </div>
                <input type="submit" className='btn btn-success' value='Guardar Cambios'/>
              </form>
            </div>
            <div className="alert alert-success" role="alert">
              <h4 className="alert-heading">RECOMENDACIONES!</h4>
              <p>Asegúrese de completar todos los campos obligatorios; en caso de no desear cobrar inscripción o mensualidad, ingrese el valor "0" y proceda a guardar los cambios haciendo clic en el botón correspondiente.
              </p>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogAsistencias} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Asistencias" modal className="p-fluid" onHide={() => setDialogAsistencias(false)}>
          <div className="field">
            <div className="mb-2">
              <h1 className='text-center'>{selectedAfiliado ? selectedAfiliado.nombre : ''}</h1>
              <Calendar value={date} onChange={(e) => registroAsistencia(e.value)} inline showWeek locale="es" dateTemplate={dateTemplate}/>
              {
                /*
                <div className="alert alert-success" role="alert">
                  <h4 className="alert-heading">¡ASISTENCIAS!</h4>
                  {arreglarAsistencias()}
                </div>
                
                */
              }
            </div>
          </div>
        </Dialog>
      </motion.div>
    </>
  )
}

export default Afiliacion