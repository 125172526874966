import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { consolidado } from '../controllers/configuracionFirebase';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { datosAdmin } from '../controllers/administrador';

const Consolidado = ({ idTorneoParticipando, torneoEspecifico, pruebasCompletas, planilla, fechaRegistroProgramacion, listadoCategorias, usuario }) => {

  const [ clubes, setClubes ] = useState([]);
  const [ consolidadoGeneral, setConsolidadoGeneral ] = useState({});
  const [ varones, setVarones ] = useState([]);
  const [ damas, setDamas ] = useState([]);
  const [ mixto, setMixto ] = useState([]);
  const [ generos, setGeneros ] = useState([]);
  const [ totales, setTotales ] = useState([]);
  const [ cantLugaresPrueba, setCantLugaresPrueba ] = useState(2);
  const [ medalleriaPrueba, setMedalleriaPrueba ] = useState([]);
  const [ contadorMedalleriaPrueba, setContadorMedalleriaPrueba ] = useState(0);
  const [ cantLugaresSerie, setCantLugaresSerie ] = useState(2);
  const [ medalleriaSerie, setMedalleriaSerie ] = useState([]);
  const [ contadorMedalleriaSerie, setContadorMedalleriaSerie ] = useState(0);

  const parseEdad = (edad) => {
    if (edad.includes('-')) {
      // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
      return parseInt(edad.split('-')[1], 10);
    }
    if (edad.includes('y Mas')) {
      // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
      return parseInt(edad.split('-')[0], 10);
    }
    // Si es un número único, lo convertimos a entero
    return parseInt(edad, 10);
  };

  function restarCantPrueba(){
    let auxNum = cantLugaresPrueba;
    if(auxNum === 0){
      auxNum = 0;
    }else{
      auxNum--;
    }
    setCantLugaresPrueba(auxNum);
  }

  function sumarCantPrueba(){
    let auxNum = cantLugaresPrueba;
    if(auxNum === parseInt(torneoEspecifico.carril) - 1){
      auxNum = parseInt(torneoEspecifico.carril) - 1;
    }else{
      auxNum++;
    }
    setCantLugaresPrueba(auxNum);
  }

  function restarCantSerie(){
    let auxNum = cantLugaresSerie;
    if(auxNum === 0){
      auxNum = 0;
    }else{
      auxNum--;
    }
    setCantLugaresSerie(auxNum);
  }

  function sumarCantSerie(){
    let auxNum = cantLugaresSerie;
    if(auxNum === parseInt(torneoEspecifico.carril) - 1){
      auxNum = parseInt(torneoEspecifico.carril) - 1;
    }else{
      auxNum++;
    }
    setCantLugaresSerie(auxNum);
  }

  function encabezadoMedallas(n){
    let nombre = '';
    switch (parseInt(n)) {
      case 0:
        nombre = 'Oro';
        break;
      case 1:
        nombre = 'Plata';
        break;
      case 2:
        nombre = 'Bronce';
        break;
      case 3:
        nombre = '4to';
        break;
      case 4:
        nombre = '5to';
        break;
      case 5:
        nombre = '6to';
        break;
      case 6:
        nombre = '7mo';
        break;
      case 7:
        nombre = '8vo';
        break;
      case 8:
        nombre = '9no';
        break;
      case 9:
        nombre = '10mo';
        break;
    
      default:
        break;
    }

    return nombre;
  }

  useEffect(() => {
    consolidado(idTorneoParticipando, setClubes);
  }, [setClubes, idTorneoParticipando])

  useEffect(() => {
    function sacarPruebas() {
      const auxTotalesClub = [];
      const auxVarones = [];
      const auxDamas = [];
      const auxGeneros = new Set();
      const auxDamasEdades = new Set();
      const auxVaronesEdades = new Set();
      const auxClubes = {}

      clubes.sort((a,b)=>{
        if(a.nombreclub < b.nombreclub){
          return -1;
        }
        if(a.nombreclub > b.nombreclub){
          return 1;
        }
        return 0;
      });

      clubes.forEach((club)=>{
        if(!auxClubes[club.nombreclub]){
          auxClubes[club.nombreclub] = {};
        }
        
        club.nadadores.forEach((nad) => {
          auxGeneros.add(nad.genero);
          
          if (!auxClubes[nad.nombreclub]) {
            auxClubes[nad.nombreclub] = {};
          }
        
          if (!auxClubes[nad.nombreclub][nad.genero]) {
            auxClubes[nad.nombreclub][nad.genero] = {};
          }
        
          if (!auxClubes[nad.nombreclub][nad.genero][nad.categoria]) {
            auxClubes[nad.nombreclub][nad.genero][nad.categoria] = {
              categoria: nad.categoria,
              cantidad: 0
            };
          }
        
          // Incrementar el contador de cantidad para la categoría correspondiente
          auxClubes[nad.nombreclub][nad.genero][nad.categoria].cantidad++;
        });

        club.nadadores.forEach((nad)=>{
          if(nad.genero === 'Femenino'){
            if(!auxDamasEdades.has(nad.categoria)){
              auxDamasEdades.add(nad.categoria);
              auxDamas.push({edad: nad.categoria});
            }
          }else{
            if(!auxVaronesEdades.has(nad.categoria)){
              auxVaronesEdades.add(nad.categoria);
              auxVarones.push({edad: nad.categoria});
            }
          }
        })
      })

      // Objeto para almacenar los totales por género y categoría
      const totalGeneros = {
        Femenino: {},
        Masculino: {}
      };

      // Recorremos el objeto auxClubes
      Object.entries(auxClubes).forEach(([club, generos]) => {
        Object.entries(generos).forEach(([genero, categorias]) => {
          // Si el género no existe en el objeto de totales, lo inicializamos
          if (!totalGeneros[genero]) {
            totalGeneros[genero] = {};
          }
        
          Object.entries(categorias).forEach(([categoria, data]) => {
            // Si la categoría dentro del género no existe, la inicializamos en 0
            if (!totalGeneros[genero][categoria]) {
              totalGeneros[genero][categoria] = 0;
            }
          
            // Sumamos la cantidad de nadadores de esa categoría para el género correspondiente
            totalGeneros[genero][categoria] += data.cantidad;
          });
        });
      });

      Object.entries(totalGeneros).forEach(([generos, generosValue])=>{
        Object.entries(generosValue).forEach(([categoria, categoriaValue])=>{
          auxTotalesClub.push(categoriaValue);
        })
      });

      // Obtener las claves y ordenarlas
      const sortedKeys = Object.keys(auxClubes).sort();

      // Crear un nuevo objeto con las claves ordenadas
      const sortedObj = {};
      sortedKeys.forEach(key => {
        sortedObj[key] = auxClubes[key];
      });
      setTotales(auxTotalesClub);
      setDamas(auxDamas.sort((a, b) => parseEdad(a.edad) - parseEdad(b.edad)));
      setVarones(auxVarones.sort((a, b) => parseEdad(a.edad) - parseEdad(b.edad)));
      setGeneros(Array.from(auxGeneros).sort());
      setConsolidadoGeneral(sortedObj);
    }
    sacarPruebas();
  }, [clubes])

  useEffect(() =>{
    const auxPruebas = [];
    pruebasCompletas.forEach((prue)=>{
      auxPruebas.push(...prue);
    })

    let auxMedalleriaPrueba = {};
    for (let i = 0; i <= parseInt(torneoEspecifico.carril) - 1; i++) {
      auxMedalleriaPrueba[i] = 0;
    }

    auxPruebas.forEach((auxPrue, indexAuxPrue)=>{
      const juntosOSeparados = listadoCategorias.filter((lisCate)=>{return lisCate.valor === auxPrue.categoriaDeportistas});
      if(juntosOSeparados.length !== 0){
        if(juntosOSeparados[0].premiacion === 'separados'){
          if(planilla[indexAuxPrue]){
            juntosOSeparados[0].grupos.forEach((gru)=>{
              let contadorNadadores = 0;
              planilla[indexAuxPrue].forEach((pla)=>{
                pla.nadadores.forEach((nad)=>{
                  if(nad.categoria === gru.nombre){
                    contadorNadadores+=1;
                  }
                });
              })

              if(contadorNadadores !== 0){
                if(contadorNadadores <= parseInt(torneoEspecifico.carril)){
                  for (let i = 0; i < contadorNadadores; i++) {
                    auxMedalleriaPrueba[i] += 1;
                  }
                }else{
                  for (let i = 0; i < parseInt(torneoEspecifico.carril); i++) {
                    auxMedalleriaPrueba[i] += 1;
                  }
                }
              }
            })
          }
        }else{
          if(planilla[indexAuxPrue]){
            planilla[indexAuxPrue].forEach((pla)=>{
              let contadorNadadores = pla.nadadores.length;
              if(contadorNadadores !== 0){
                if(contadorNadadores <= parseInt(torneoEspecifico.carril)){
                  for (let i = 0; i < contadorNadadores; i++) {
                    auxMedalleriaPrueba[i] += 1;
                  }
                }else{
                  for (let i = 0; i < parseInt(torneoEspecifico.carril); i++) {
                    auxMedalleriaPrueba[i] += 1;
                  }
                }
              }
            })
          }
        }
      }
    })

    let contadorMedalleriaPrueba = 0;
    for (let i = 0; i <= cantLugaresPrueba; i++) {
      contadorMedalleriaPrueba += auxMedalleriaPrueba[i];
    }

    setContadorMedalleriaPrueba(contadorMedalleriaPrueba);
    setMedalleriaPrueba(auxMedalleriaPrueba);
  },[cantLugaresPrueba])

  useEffect(() =>{
    const auxPruebas = [];
    pruebasCompletas.forEach((prue)=>{
      auxPruebas.push(...prue);
    })

    let auxMedalleriaSerie = {};
    for (let i = 0; i <= parseInt(torneoEspecifico.carril) - 1; i++) {
      auxMedalleriaSerie[i] = 0;
    }

    auxPruebas.forEach((_, indexAuxPrue)=>{
      if(planilla[indexAuxPrue]){
        planilla[indexAuxPrue].forEach((pla)=>{
          let contadorNadadores = 0;
          pla.nadadores.forEach((nad)=>{
            if(nad.nombre[0]){
              contadorNadadores++;
            }
          })
          for (let i = 0; i < contadorNadadores; i++) {
            auxMedalleriaSerie[i] += 1;
          }
        })
      }
    })

    let contadorMedalleriaSerie = 0;
    for (let i = 0; i <= cantLugaresSerie; i++) {
      contadorMedalleriaSerie += auxMedalleriaSerie[i];
    }

    setContadorMedalleriaSerie(contadorMedalleriaSerie);
    setMedalleriaSerie(auxMedalleriaSerie);
  },[cantLugaresSerie])

  return (
    <>
      <motion.div className="pb-3"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='bg-light p-2 shadow table-responsive-xl'>
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th className="titulo-consolidado" colSpan={16}>ESTADISTICAS DE CATEGORIAS</th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                {generos.map((gene, indexGene)=>{
                  return <th key={indexGene} colSpan={gene === 'Femenino' ? damas.length : gene === 'Masculino' ? varones.length : mixto.length}>{gene === 'Femenino' ? 'Damas' : gene === 'Masculino' ? 'Varones' : 'Mixto'}</th>
                })}
                <th></th>
              </tr>
              <tr>
                <th className="clubes" colSpan={2}>Clubes</th>
                {
                  damas.map(function(cate, indexCate){
                    return <th className="categorias-damas" key={indexCate}>{cate.edad}</th>
                  })
                }
                {
                  varones.map(function(cate, indexCate){
                    return <th className="categorias-varones" key={indexCate}>{cate.edad}</th>
                  })
                }
                {
                  mixto.map(function(cate, indexCate){
                    return <th className="categorias-varones" key={indexCate}>{cate.edad}</th>
                  })
                }
                <th className="total">Total</th>
              </tr>
            </thead>
            <tbody>
              {
                clubes.map(function(club, indexClub){
                  return club.nadadores.length !== 0 ?(
                  <tr key={indexClub}>
                    <td><Avatar className="flex align-items-center justify-content-center mr-2 shadow" image={club.photo} label={club.photo !== null && club.photo.endsWith(".pdf") ? '' : club.nombreclub[0] + club.nombreclub[1]}/></td>
                    <td className="nombreclub">{club.nombreclub}</td>
                    {damas.length !== 0 ? (
                      damas.map((dam, indexDam)=>{
                        return <td key={indexDam}>{consolidadoGeneral[club.nombreclub] ? consolidadoGeneral[club.nombreclub]['Femenino'] ? consolidadoGeneral[club.nombreclub]['Femenino'][dam.edad] ? consolidadoGeneral[club.nombreclub]['Femenino'][dam.edad].cantidad : 0 : 0 : 0}</td>
                      })
                    ):(
                      <></>
                    )}
                    {varones.length !== 0 ? (
                      varones.map((varo, indexVaro)=>{
                        return <td key={indexVaro}>{consolidadoGeneral[club.nombreclub] ? consolidadoGeneral[club.nombreclub]['Masculino'] ? consolidadoGeneral[club.nombreclub]['Masculino'][varo.edad] ? consolidadoGeneral[club.nombreclub]['Masculino'][varo.edad].cantidad : 0 : 0 : 0}</td>
                      })
                    ):(
                      <></>
                    )}
                    <td className="rojo">{club.nadadores.length}</td>
                  </tr>
                  ):(
                    <></>
                  )
                })
              }
              {
                <tr>
                  <th className="total total-clubes" colSpan={2}>Total</th>
                  {totales.length !== 0 ?(
                    totales.map((tol, indexTol)=>{
                      return <td key={indexTol}>{tol}</td>
                    })
                  ):(
                    <></>
                  )}
                  <th className="total-1">{totales.reduce((acumulado, valor) => acumulado + valor, 0)}</th>
                </tr>
              }
            </tbody>
          </table>
          {usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS' ?(
            <>
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                <h4 className="mb-0">¡ULTIMO REGISTRO PROGRAMACIÓN!</h4>
                <p className="mb-0"><b>{fechaRegistroProgramacion}</b></p>
                <p style={{textAlign: 'justify'}}>
                  Con esta herramienta podrás visualizar fácilmente cuántas medallas necesitas para tu evento deportivo. 
                  Podrás realizar el cálculo tanto por prueba como por serie, asegurándote de tener la cantidad exacta. 
                  Se recomienda agregar entre 5 y 10 medallas adicionales por puesto para cubrir empates u otras eventualidades.
                  Es importante que la programación del evento esté registrada previamente para que el cálculo sea preciso. 
                  Además, puedes ajustar la cantidad de medallas necesarias por puesto utilizando los botones para aumentar o disminuir según lo requerido.
                </p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th colSpan={parseInt(torneoEspecifico.carril) + 1}>
                      MEDALLERIA NECESARIA POR PRUEBA <br />
                      <div className='d-flex justify-content-center align-items-center'>
                        <Button icon='pi pi-minus' severity='success' raised size="small" style={{borderRadius: '50%'}} onClick={() => restarCantPrueba()}></Button>
                        <h5 className='me-2 ms-2'>{encabezadoMedallas(cantLugaresPrueba).toUpperCase()}</h5>
                        <Button icon='pi pi-plus' severity='danger' raised size="small" style={{borderRadius: '50%'}}onClick={() => sumarCantPrueba()}></Button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(medalleriaPrueba).map((medalla, index)=>{
                      return <th key={index}>{encabezadoMedallas(medalla)}</th>
                    })}
                    <th>Total</th>
                  </tr>
                  <tr>
                    {Object.keys(medalleriaPrueba).map((medalla, index)=>{
                      return <td key={index}>{cantLugaresPrueba >= index ? medalleriaPrueba[medalla] : 0}</td>
                    })}
                    <td>{contadorMedalleriaPrueba}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th colSpan={parseInt(torneoEspecifico.carril) + 1}>
                      MEDALLERIA NECESARIA POR SERIE <br />
                      <div className='d-flex justify-content-center align-items-center'>
                        <Button icon='pi pi-minus' severity='success' raised size="small" style={{borderRadius: '50%'}} onClick={() => restarCantSerie()}></Button>
                        <h5 className='me-2 ms-2'>{encabezadoMedallas(cantLugaresSerie).toUpperCase()}</h5>
                        <Button icon='pi pi-plus' severity='danger' raised size="small" style={{borderRadius: '50%'}}onClick={() => sumarCantSerie()}></Button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(medalleriaSerie).map((medalla, index)=>{
                      return <th key={index}>{encabezadoMedallas(medalla)}</th>
                    })}
                    <th>Total</th>
                  </tr>
                  <tr>
                    {Object.keys(medalleriaSerie).map((medalla, index)=>{
                      return <td key={index}>{cantLugaresSerie >= index ? medalleriaSerie[medalla] : 0}</td>
                    })}
                    <td>{contadorMedalleriaSerie}</td>
                  </tr>
                </tbody>
              </table>
            </>
          ):(
            <></>
          )}
        </div>
      </motion.div>
    </>
  );
}

export default Consolidado;