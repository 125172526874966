import React from 'react';
import { motion } from "framer-motion";
import { registrarTablasPlanilla } from '../controllers/configuracionFirebase';
import { toast } from 'react-hot-toast';
import { Button } from 'primereact/button';
import { arreglarTiempoEstandar } from '../controllers/pdfs';

const TablaPruebas = ({pruebas, jornada, nadadores, idTorneoParticipando, invitado, usuario, fechaHoraRegistro, colorPdf}) => {

  function registrarTablaIndividual(idPrueba){
    registrarTablasPlanilla(idTorneoParticipando, pruebas.filter((prue)=>{return prue.id === idPrueba})[0], nadadores[idPrueba - 1], fechaHoraRegistro);
    toast.success('Tabla registrada correctamente.');
  }

  return (
    <>
      <div className="alert alert-dismissible fade show" role="alert" style={{background: 'rgba(255,255,255,0.4)'}}>
        <h1 className='alert-heading'>{`Jornada ${jornada}`}</h1>    
      </div>
      <div className='d-flex flex-wrap justify-content-center'>
        {pruebas.map((prue, indexPrue)=>{
          return <form key={indexPrue} className='formulario-tablas'>
          <motion.table
          className='table table-bordered table-light shadow tabla-tiempos'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{duration: 1}}
          style={{width: '440px', margin: '4px'}}
          >
            <thead>
              <tr>
              <th className='id fs-4'>{prue.id}</th>
                <th colSpan={4}>{`${prue.categoria}`}</th>
              </tr>
              <tr>
                <th colSpan={5}>{prue.prueba.prueba}</th>
              </tr>
            </thead>
            {
              nadadores[prue.id - 1] ?
              nadadores[prue.id - 1].length !== 0 ?
              nadadores[prue.id - 1].map((nadador, nadadorIndex)=>{
                return <tbody key={nadadorIndex}>
                  <tr>
                    <th colSpan={5}>{`Serie ${nadador.Serie}`}</th>
                  </tr>
                  {
                    nadador.nadadores.map((user, index)=>{
                      return <tr key={index}>
                        <td style={{background: `${invitado ? usuario.nombreclub === user.nombreclub[0] ? `rgb(${colorPdf.r + ',' + colorPdf.g + ',' + colorPdf.b})` : '' : ''}`}}>{index + 1}</td>
                        {user.equipo ?(
                        <td style={{background: `${invitado ? usuario.nombreclub === user.nombreclub[0] ? `rgb(${colorPdf.r + ',' + colorPdf.g + ',' + colorPdf.b})` : '' : ''}`}}>
                          <span className='btn btn-secondary btn-sm dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          {user.nombre}</span>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            {user.equipo.map((eq, indexEq)=>{
                              return <li key={indexEq} className="dropdown-item">{eq + '/' + user.edades[indexEq]}</li>
                            })}
                          </ul>
                        </td>
                      ):(
                        <td style={{background: `${invitado ? usuario.nombreclub === user.nombreclub[0] ? `rgb(${colorPdf.r + ',' + colorPdf.g + ',' + colorPdf.b})` : '' : ''}`}}>{user.nombre}</td>
                      )}
                      <td style={{background: `${invitado ? usuario.nombreclub === user.nombreclub[0] ? `rgb(${colorPdf.r + ',' + colorPdf.g + ',' + colorPdf.b})` : '' : ''}`}}>{`${user.edad}`}</td>
                      <td style={{background: `${invitado ? usuario.nombreclub === user.nombreclub[0] ? `rgb(${colorPdf.r + ',' + colorPdf.g + ',' + colorPdf.b})` : '' : ''}`}}>{user.records ?  user.records.filter((rec)=>{return rec.prueba === prue.prueba.id}).length !== 0 ?
                      arreglarTiempoEstandar(user.records.filter((rec)=>{return rec.prueba === prue.prueba.id})[0].record) : '' : ''}</td>
                      <td style={{background: `${invitado ? usuario.nombreclub === user.nombreclub[0] ? `rgb(${colorPdf.r + ',' + colorPdf.g + ',' + colorPdf.b})` : '' : ''}`}}>{user.club}</td>
                    </tr>
                    })
                  }
                </tbody>
              })
              :
              <tbody>
                <tr>
                  <th colSpan={3}>Serie 1</th>
                </tr>
                <tr>
                  <td colSpan={3}>No hay nadadores aún</td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr>
                  <th colSpan={3}>Serie 1</th>
                </tr>
                <tr>
                  <td colSpan={3}>No hay nadadores aún</td>
                </tr>
              </tbody>
            }
            {
              !invitado ?(
                <tbody>
                  <tr>
                    <td colSpan={5}>
                      <Button type='button' className='btn btn-success' icon='pi pi-plus' label='Registrar Tabla' disabled={nadadores[prue.id - 1] ? nadadores[prue.id - 1].length !== 0 ? false: true : true} onClick={()=> registrarTablaIndividual(prue.id)}></Button>
                    </td>  
                  </tr>
                </tbody>
              ):(
                <></>
              )
            }
          </motion.table>
          </form>
        })}
      </div>
    </>
  );
}

export default TablaPruebas;