import React from 'react';
import { motion } from "framer-motion";
import { registrarTiempos, registrarRecords, registrarRecordsPws, registrarTiemposFamilia } from '../controllers/configuracionFirebase';

const TablaTiempos = ({pruebas, nadadores, idTorneoParticipando, torneoEspecifico, tiemposRegistrados, setTiemposRegistrados, listadoCategorias, formulariosRef, seriesRef}) => {

  function separarEdades(edad, auxNadadores, nad){
    if (!auxNadadores[edad]) {
      auxNadadores[edad] = [];
    }
    auxNadadores[edad].push(nad);
  }

  function manejarRegistroTiempos(nadadorObj, idTabla, auxTiemposRegistrados){
    for (let i = 0; i < nadadorObj.nadadores.length; i++) {
      const auxNadador = {
        nombre: nadadorObj.nadadores[i].nombre,
        club: nadadorObj.nadadores[i].club,
        identificacion: nadadorObj.nadadores[i].identificacion,
        edad: nadadorObj.nadadores[i].edad,
        nombreclub: nadadorObj.nadadores[i].nombreclub,
        equipo: nadadorObj.nadadores[i].equipo,
        record: nadadorObj.nadadores[i].record,
        tiempo: nadadorObj.nadadores[i].tiempoMin + ':' + nadadorObj.nadadores[i].tiempoSeg + ':' + nadadorObj.nadadores[i].tiempoMili
      }
      nadadorObj.nadadores.splice(i, 1, auxNadador);
      //registrarRecords(auxNadador.identificacion, nadadorObj.prueba, auxNadador.tiempo, auxNadador.record, i);
      registrarRecordsPws(auxNadador.identificacion, nadadorObj.prueba, auxNadador.tiempo, auxNadador.record, i, torneoEspecifico.carril, torneoEspecifico.piscina);
    }
    registrarTiempos(idTorneoParticipando, idTabla - 1, nadadorObj, auxTiemposRegistrados);
    registrarTiemposFamilia(idTorneoParticipando, idTabla - 1, nadadorObj);
  }

  function cambiarNombrePrueba(nuevoNombre, titulo, id){
    let auxNuevoNombre = '';
    if(titulo !== ''){
      auxNuevoNombre = pruebas[id].categoria.replace(titulo, nuevoNombre);
    }else{
      auxNuevoNombre = pruebas[id].categoria.replace(/\((M|F|X)\) \w+/g, (match) =>{
        return match.replace('/\b\w+\b/', nuevoNombre)});
    }
    return auxNuevoNombre;
  }

  function onSubmit(e){
    e.preventDefault();
    const id = e.target.id.value;
    const auxTiemposRegistrados = [...tiemposRegistrados];
    const idTabla = e.target.idTabla.value;
    auxTiemposRegistrados[parseInt(idTabla - 1)] = auxTiemposRegistrados[parseInt(idTabla - 1)] ? auxTiemposRegistrados[parseInt(idTabla - 1)] + 1 : 1;
    const nadadorObj = {
      id: parseInt(idTabla) - 1,
      categoriaDeportistas: pruebas[id].categoriaDeportistas,
      categoria: pruebas[id].categoria,
      prueba: pruebas[id].prueba,
      nadadores: [],
    }

    const auxCantSerie = auxTiemposRegistrados[parseInt(idTabla - 1)] <= nadadores[parseInt(idTabla - 1)].length ? auxTiemposRegistrados[parseInt(idTabla - 1)] : nadadores[parseInt(idTabla - 1)].length;
    for (let i = 0; i < parseInt(torneoEspecifico.carril); i++) {
      for (let j = 0; j < auxCantSerie; j++) {
        const nadador = document.getElementById(`${idTabla - 1}nombre${i}serie${j + 1}`).value;
        const club = document.getElementById(`${idTabla - 1}club${i}serie${j + 1}`).value;
        const nombreclub = document.getElementById(`${idTabla - 1}nombreclub${i}serie${j + 1}`).value;
        const cc = document.getElementById(`${idTabla - 1}identificacion${i}serie${j + 1}`).value;
        const edad = document.getElementById(`${idTabla - 1}edad${i}serie${j + 1}`).value;
        const record = document.getElementById(`${idTabla - 1}record${i}serie${j + 1}`).value;
        const equipo = document.getElementById(`${idTabla - 1}equipo${i}serie${j + 1}`) ? document.getElementById(`${idTabla - 1}equipo${i}serie${j + 1}`).value : '';
        const tiempoMin = document.getElementById(`${idTabla - 1}tiempo${i}minserie${j + 1}`).value;
        const tiempoSeg = document.getElementById(`${idTabla - 1}tiempo${i}segserie${j + 1}`).value;
        const tiempoMili = document.getElementById(`${idTabla - 1}tiempo${i}miliserie${j + 1}`).value;
        const categoria = document.getElementById(`${idTabla - 1}categoria${i}serie${j + 1}`).value;

        if(nadador !== '' && club !== ''){
          const auxNadador = {
            nombre: nadador,
            club,
            identificacion: cc,
            edad,
            record,
            equipo: equipo !== '' ? equipo.split(',') : null,
            tiempoMin: parseInt(tiempoMin),
            tiempoSeg: parseInt(tiempoSeg),
            tiempoMili: parseInt(tiempoMili),
            nombreclub,
            categoria
          }
          nadadorObj.nadadores.push(auxNadador);
        }
      }
    }

    nadadorObj.nadadores.sort(function(a, b) {
      var tiempoA = a.tiempoMin * 60 * 1000 + a.tiempoSeg * 1000 + a.tiempoMili;
      var tiempoB = b.tiempoMin * 60 * 1000 + b.tiempoSeg * 1000 + b.tiempoMili;

      if(a.tiempoMin === 0 && a.tiempoSeg === 0 && a.tiempoMili === 0){
        return 1;
      }else if(b.tiempoMin === 0 && b.tiempoSeg === 0 && b.tiempoMili === 0){
        return -1;
      }else{
        return tiempoA - tiempoB;
      }
    });

    const auxCategoria = listadoCategorias.filter((listCate)=>{return listCate.valor === nadadorObj.categoriaDeportistas});
    if(auxCategoria.length !== 0){
      if(auxCategoria[0].premiacion === 'separados'){
        if(auxCategoria[0].grupos.length !== 0){
          const auxNadadores = {};
          for (let i = 0; i < nadadorObj.nadadores.length; i++) {
            const edad = nadadorObj.nadadores[i].categoria;
            const auxGrupo = auxCategoria[0].grupos.filter((gru)=>{return gru.nombre === edad});
            if(auxGrupo.length !== 0){
              if(parseInt(auxGrupo[0].edadInicio) <= parseInt(nadadorObj.nadadores[i].edad) && parseInt(auxGrupo[0].edadFin) >= parseInt(nadadorObj.nadadores[i].edad)){
                separarEdades(edad, auxNadadores, nadadorObj.nadadores[i]);
              }
            }
          }
          let contadorIndex = 0;
          auxCategoria[0].grupos.forEach((ed) =>{
            if(auxNadadores[ed.nombre]){
              const objNadador = {
                id: parseFloat((idTabla - 1) + '.' + contadorIndex),
                categoriaDeportistas: pruebas[id].categoriaDeportistas,
                categoria: cambiarNombrePrueba(`${ed.nombre} Años`, auxCategoria[0].titulo, id),
                prueba: pruebas[id].prueba,
                nadadores: [],
              }

              const guardarNadadoresSeparados = [];
              auxNadadores[ed.nombre].forEach((auxNad, indexAuxNad)=>{
                const auxNadador = {
                  nombre: auxNad.nombre,
                  club: auxNad.club,
                  identificacion: auxNad.identificacion,
                  edad: auxNad.edad,
                  nombreclub: auxNad.nombreclub,
                  categoria: auxNad.categoria,
                  equipo: auxNad.equipo,
                  record: auxNad.record,
                  tiempo: auxNad.tiempoMin + ':' + auxNad.tiempoSeg + ':' + auxNad.tiempoMili
                }

                objNadador.categoriaEdad = auxNadador.categoria;
                guardarNadadoresSeparados.push(auxNadador);
                registrarRecordsPws(auxNadador.identificacion, objNadador.prueba, auxNadador.tiempo, auxNadador.record, indexAuxNad, torneoEspecifico.carril, torneoEspecifico.piscina);
              })
              objNadador.nadadores = guardarNadadoresSeparados;
              registrarTiempos(idTorneoParticipando, idTabla - 1, objNadador, auxTiemposRegistrados);
              registrarTiemposFamilia(idTorneoParticipando, idTabla - 1,objNadador);
              contadorIndex++;
            }
          })
        }else{
          manejarRegistroTiempos(nadadorObj, idTabla, auxTiemposRegistrados);
        }
      }else{
        manejarRegistroTiempos(nadadorObj, idTabla, auxTiemposRegistrados);
      }
    }
    setTiemposRegistrados(auxTiemposRegistrados);
  }

  function validarMostrarBackup(user){
    let mostrar = false;
    if(user.tiempoBackup){
      if(user.nombre.length !== 0 && (parseInt(user.tiempoBackup[0]) !== 0 || parseInt(user.tiempoBackup[1]) !== 0 || parseInt(user.tiempoBackup[2]) !== 0)){
        mostrar = true;
      }
    }
    return mostrar;
  }

  return (
    <>
      <div className='d-flex flex-wrap justify-content-center'>
        {pruebas.map((prue, indexPrue)=>{
          return <form key={indexPrue} ref={(el) => (formulariosRef.current[prue.id] = el)} onSubmit={onSubmit} className='formulario-tablas'>
            <motion.table
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{duration: 1}}
            className='table table-striped table-hover table-bordered table-light shadow tabla-tiempos'
            style={{margin: '4px', width: '640px'}}
            >
              <thead>
                <tr>
                  <th className='id fs-4'>
                    <input type="hidden" name='idTabla' defaultValue={prue.id}/>
                    <input type="hidden" name='id' defaultValue={indexPrue}/>{prue.id}
                  </th>
                  <th colSpan={3}>{prue.categoria}</th>
                </tr>
                <tr>
                  <th colSpan={4}>{prue.prueba.prueba}</th>
                </tr>
              </thead>
              {
                nadadores[prue.id - 1] ?
                nadadores[prue.id - 1].length !== 0 ?
                nadadores[prue.id - 1].map((nadador, nadadorIndex)=>{
                  return <tbody key={nadadorIndex} ref={(el) => (seriesRef.current[`${prue.id}-${nadador.Serie}`] = el)}>
                    <tr>
                      <th colSpan={3}>{`Serie ${nadador.Serie}: ${tiemposRegistrados[prue.id - 1] ? tiemposRegistrados[prue.id - 1] >= nadador.Serie ? 'Registrada' : 'No se ha registrado aún' : 'No se ha registrado aún'}`}</th>
                      <td colSpan={1} className='p-0'>
                        <button type="submit" disabled={nadadores[prue.id - 1] ? nadadores[prue.id - 1].length !== 0 ? false: true : true} className='btn p-0'>
                          <img id={`registrarTiempos${indexPrue}`} src={require('../img/icon-tiempos.png')} style={{width: '36px', filter: `drop-shadow(0 0 1px ${tiemposRegistrados[prue.id - 1] ? tiemposRegistrados[prue.id - 1] >= nadador.Serie ? 'green' : 'red' : 'red'})`}}/>
                        </button>
                      </td>  
                    </tr>
                    {
                      nadador.nadadores.map((user, index)=>{
                        return <tr key={index}>
                          <td>{index + 1}</td>
                          {user.equipo ?(
                            <td className='dropdown'>
                              <span className='btn btn-secondary btn-sm dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                              <input type="hidden" id={`${prue.id - 1}nombre${index}serie${nadador.Serie}`} defaultValue={user.nombre}/>{user.nombre}</span>
                              <input type="hidden" id={`${prue.id - 1}equipo${index}serie${nadador.Serie}`} defaultValue={user.equipo}/>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                {user.equipo.map((eq, indexEq)=>{
                                  return <li key={indexEq} className="dropdown-item">{eq + '/' + user.edades[indexEq]}</li>
                                })}
                              </ul>
                            </td>
                            ):(
                            <td><input type="hidden" id={`${prue.id - 1}nombre${index}serie${nadador.Serie}`} defaultValue={user.nombre}/>{user.nombre}</td>
                          )}
                          <td><input type="hidden" id={`${prue.id - 1}club${index}serie${nadador.Serie}`} defaultValue={user.club}/>{user.club}</td>
                          <td style={{display: 'none'}}><input type="hidden" id={`${prue.id - 1}nombreclub${index}serie${nadador.Serie}`} defaultValue={user.nombreclub}/></td>
                          <td style={{display: 'none'}}><input type="hidden" id={`${prue.id - 1}identificacion${index}serie${nadador.Serie}`} defaultValue={user.identificacion}/></td>
                          <td style={{display: 'none'}}><input type="hidden" id={`${prue.id - 1}edad${index}serie${nadador.Serie}`} defaultValue={user.edad}/></td>
                          <td style={{display: 'none'}}><input type="hidden" id={`${prue.id - 1}record${index}serie${nadador.Serie}`} defaultValue={user.record}/></td>
                          <td style={{display: 'none'}}><input type="hidden" id={`${prue.id - 1}categoria${index}serie${nadador.Serie}`} defaultValue={user.categoria}/></td>
                          <td style={{width: '40%'}}>
                            {validarMostrarBackup(user)?(
                              <div className='text-end me-2' style={{fontFamily: 'Lora'}}>
                                <span className='fs-6'>{user.tiempoBackup ? parseInt(user.tiempoBackup[0]) : '0'}:</span>
                                <span className='fs-6'>{user.tiempoBackup ? parseInt(user.tiempoBackup[1]) : '0'}.</span>
                                <span className='fs-6'>{user.tiempoBackup ? parseInt(user.tiempoBackup[2]) : '0'}</span>
                              </div>
                            ):(
                              <></>
                            )}
                            <div>
                              <input className='text-center cro' disabled={user.nombre.length !== 0 ? false : true} style={{width: '30%'}} type="number" min='0' id={`${prue.id - 1}tiempo${index}minserie${nadador.Serie}`} defaultValue={user.tiempoBackup ? parseInt(user.tiempoBackup[0]) : '0'} required/>:
                              <input className='text-center cro' disabled={user.nombre.length !== 0 ? false : true} style={{width: '30%'}} type="number" id={`${prue.id - 1}tiempo${index}segserie${nadador.Serie}`} defaultValue={user.tiempoBackup ? parseInt(user.tiempoBackup[1]) : '0'} required/>.
                              <input className='text-center cro' disabled={user.nombre.length !== 0 ? false : true} style={{width: '30%'}} type="number" min='0' id={`${prue.id - 1}tiempo${index}miliserie${nadador.Serie}`} defaultValue={user.tiempoBackup ? parseInt(user.tiempoBackup[2]) : '0'} required/>
                            </div>
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                })
                :
                <tbody>
                  <tr>
                    <th colSpan={3}>Serie 1</th>
                  </tr>
                  <tr>
                    <td colSpan={3}>No hay nadadores aún</td>
                  </tr>
                </tbody>
                :
                <tbody>
                  <tr>
                    <th colSpan={3}>Serie 1</th>
                  </tr>
                  <tr>
                    <td colSpan={3}>No hay nadadores aún</td>
                  </tr>
                </tbody>
              }
            </motion.table>
          </form>
        })}
      </div>
    </>
  );
}

export default TablaTiempos;