import axios from 'axios';

export const loadGoogleMapsAPI = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('googleMaps');

    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = 'googleMaps';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`;
    script.defer = true;
    script.async = true;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error('No se pudo cargar la API de Google Maps.'));
    };

    document.body.appendChild(script);
  });
};

export const buscarLatitudYLongitud = (direccion) => {
  try {
    const geocoder = new window.google.maps.Geocoder();
    
    // Devolver una promesa desde la geocodificación
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: direccion }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const { location } = results[0].geometry;
            const nuevaUbicacion = `${location.lat()}, ${location.lng()}`;
            
            // Geocodificación inversa para obtener el nombre de la ciudad
            const latlng = new window.google.maps.LatLng(location.lat(), location.lng());
            geocoder.geocode({ location: latlng }, (results, status) => {
              if (status === 'OK') {
                if (results[0]) {
                  const ciudad = results[0].address_components.find(component => 
                    component.types.includes('locality') || component.types.includes('administrative_area_level_1')
                  )?.long_name || "Ciudad no encontrada";
                  
                  resolve({ ubicacion: nuevaUbicacion, ciudad });
                } else {
                  reject('No se encontraron resultados para la geocodificación inversa.');
                }
              } else {
                reject(`Error en la geocodificación inversa: ${status}`);
              }
            });
          } else {
            reject('No se encontraron resultados para esa dirección.');
          }
        } else {
          reject(`Error: ${status}`);
        }
      });
    });
  } catch (error) {
    console.error(error);
  }
}

export const getAccuWeatherLocationKey = async (lL) => {
  const primaryApiKey = process.env.REACT_APP_PRIMARY_KEY;
  const secondaryApiKey = process.env.REACT_APP_SECONDARY_KEY;
  const url = `https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?q=${lL}`;
  try {
    const response = await axios.get(`${url}&apikey=${primaryApiKey}`);
    return response.data.Key;
  } catch (error) {
    // Intento con el apiKey secundario
    try {
      const response = await axios.get(`${url}&apikey=${secondaryApiKey}`);
      return response.data.Key;
    } catch (secondaryError) {
      return null;
    }
  }
};

export const getClima = async (locationKey, torneo, setClimaHoy, setClimaEvento) => {
  const primaryApiKey = process.env.REACT_APP_PRIMARY_KEY;
  const secondaryApiKey = process.env.REACT_APP_SECONDARY_KEY;
  const urlHoy = `https://dataservice.accuweather.com/currentconditions/v1/${locationKey}`;
  const urlDias = `https://dataservice.accuweather.com/forecasts/v1/daily/5day/${locationKey}`;

  try {
    const responseHoy = await axios.get(`${urlHoy}?apikey=${primaryApiKey}&language=es&metric=true`);
    const responseDias = await axios.get(`${urlDias}?apikey=${primaryApiKey}&language=es&metric=true`);
    
    const climaActual = responseHoy.data[0];
    const climaDias = responseDias.data;

    const iconoClima = climaActual.WeatherIcon;
    climaActual.iconoUrl = `https://developer.accuweather.com/sites/default/files/${
      iconoClima < 10 ? '0' + iconoClima : iconoClima
    }-s.png`;
    climaActual.ciudad = torneo.ciudad;

    climaDias.DailyForecasts.forEach(cliDias => {
      if (cliDias.Date === torneo.fechaTorneo + 'T07:00:00-05:00') {
        const isDay = climaActual.IsDayTime;
        const dayOrNight = isDay ? cliDias.Day : cliDias.Night;
        const iconoUrl = `https://developer.accuweather.com/sites/default/files/${
          dayOrNight.Icon < 10 ? '0' + dayOrNight.Icon : dayOrNight.Icon
        }-s.png`;
        dayOrNight.iconoUrl = iconoUrl;
        dayOrNight.tempmax = cliDias.Temperature.Maximum.Value;
        dayOrNight.tempmin = cliDias.Temperature.Minimum.Value;
        dayOrNight.WeatherText = dayOrNight.IconPhrase;
        dayOrNight.ciudad = torneo.ciudad;
        setClimaEvento(dayOrNight);
      }
    });

    setClimaHoy(climaActual);
  } catch (error) {
    try {
      const responseHoy = await axios.get(`${urlHoy}?apikey=${secondaryApiKey}&language=es&metric=true`);
      const responseDias = await axios.get(`${urlDias}?apikey=${secondaryApiKey}&language=es&metric=true`);
      
      // Repetimos el mismo proceso con el apiKey secundario.
      const climaActual = responseHoy.data[0];
      const climaDias = responseDias.data;

      const iconoClima = climaActual.WeatherIcon;
      climaActual.iconoUrl = `https://developer.accuweather.com/sites/default/files/${
        iconoClima < 10 ? '0' + iconoClima : iconoClima
      }-s.png`;
      climaActual.ciudad = torneo.ciudad;

      climaDias.DailyForecasts.forEach(cliDias => {
        if (cliDias.Date === torneo.fechaTorneo + 'T07:00:00-05:00') {
          const isDay = climaActual.IsDayTime;
          const dayOrNight = isDay ? cliDias.Day : cliDias.Night;
          const iconoUrl = `https://developer.accuweather.com/sites/default/files/${
            dayOrNight.Icon < 10 ? '0' + dayOrNight.Icon : dayOrNight.Icon
          }-s.png`;
          dayOrNight.iconoUrl = iconoUrl;
          dayOrNight.tempmax = cliDias.Temperature.Maximum.Value;
          dayOrNight.tempmin = cliDias.Temperature.Minimum.Value;
          dayOrNight.WeatherText = dayOrNight.IconPhrase;
          dayOrNight.ciudad = torneo.ciudad;
          setClimaEvento(dayOrNight);
        }
      });
      setClimaHoy(climaActual);
    } catch (secondaryError) {
      
    }
  }
};