import React, { useState, useEffect } from 'react';
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { buscarNombresAutorizados, deportistaEliminadoPWS, deportistaRegistradoPWS, eliminarNadador, generarId, organizarPorTiempos, registrarClubAlTorneo, registrarDeportistaGeneral, registrarDirectivosClub, registrarFacturacionParticipacion, registrarNadador, registrarPlanillaPSC, sacarEdadDeLaCategoria, sacarNadadoresDeLosClubs, sendEmailDocumento, traerClubes, traerNadadores, traerPlanillaPSC, traerPruebasParaRegistroJPC}  from "../controllers/configuracionFirebase";
import { Tooltip as Mensaje } from "react-tooltip";
import { acomodarTextoCategoria } from '../controllers/estilos';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import Swal from 'sweetalert2';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { clubesInscritosToPdf, reporteInscritos } from '../controllers/pdfs';
import { saveAs } from 'file-saver';
import { datosAdmin } from '../controllers/administrador';
import { facturacion, facturacionPagado } from '../controllers/facturaTorneo';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Tag } from 'primereact/tag';

const ControlClubs = ({ 
  usuario,
  idTorneoParticipando, 
  torneoEspecifico,
  setListPruebas,
  listPruebas,
  pruebasCompletas,
  listadoCategorias}) => {

  const [ nadadores, setNadadores ] = useState([]);
  const [ nadadoresRelevo, setNadadoresRelevo ] = useState([]);
  const [ globalFilter, setGlobalFilter ] = useState(null);
  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ dialogInscripcion, setDialogInscripcion ] = useState(false);
  const [ dialogPSC, setDialogPSC ] = useState(false);
  const [ dialogDirectivos, setDialogDirectivos ] = useState(false);
  const [ dialogEnviarCorreo, setDialogEnviarCorreo ] = useState(false);
  const [ mostrar, setMostrar ] = useState(false);
  const [ pruebasTiempos, setPruebasTiempos ] = useState([]);
  const [ pruebasId, setPruebasId ] = useState([]);
  const [ mostrarCategorias, setMostrarCategorias ] = useState([]);
  const [ sexo, setSexo ] = useState('');
  const [ edadRegistrada, setEdadRegistrada ] = useState();
  const [ mostrarJornadas, setMostrarJornadas ] = useState([]);
  const [ deportistas, setDeportistas ] = useState([]);
  const [ deportistaGeneral, setDeportistaGeneral ] = useState();
  const [ modificar, setModificar ] = useState(false);
  const [ clubes, setClubes ] = useState([]);
  const [ clubElegido, setClubesElegido ] = useState();
  const [ inscribirEliminar, setInscribirEliminar ] = useState('');
  const [ pdfUrl, setPdfUrl ] = useState();
  const [ directivos, setDirectivos ] = useState([{nombre: '', cel: '', rol: ''}]);
  const [ nombresAutorizados, setNombresAutorizados ] = useState([]);
  const [ nadadoresSinPruebas, setNadadoresSinPruebas ] = useState([]);

  const [ contabilidad, setContabilidad ] = useState();
  const [ descripcion, setDescripcion ] = useState('Factura');
  const [ expandedRowsContabilidad, setExpandedRowsContabilidad ] = useState(null);
  const [ archivo, setArchivo ] = useState({size: 0});

  function descargar(){
    saveAs(pdfUrl, descripcion === 'Factura' ? `Factura de participacion ${torneoEspecifico.titulo}.pdf` : `Reporte Inscripción ${clubElegido ? clubElegido.nombreclub : ''}.pdf`);
  }

  function calcularEdad(fecha) {
    if(torneoEspecifico.edCumplida === 'no'){
      let hoy = new Date();
      let fechaNacimiento = fecha;
      let cumple = fechaNacimiento.split('-');
      let cumpleanosDia = parseInt(cumple[2]);
      let cumpleanosMes = parseInt(cumple[1])
      let cumpleanosAno = parseInt(cumple[0]);
      let edad = hoy.getFullYear() - cumpleanosAno
      let m = (hoy.getMonth() + 1) - cumpleanosMes;
  
      if((cumpleanosMes) <= 12){
        edad--;
      }
  
      if(cumpleanosMes > 12){
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanosDia)) {
          edad--;
        }
      }
  
      return edad;
    }
    if(torneoEspecifico.edCumplida === 'si'){
      let hoy = new Date();
      let fechaNacimiento = fecha;
      let cumple = fechaNacimiento.split('-');
      let cumpleanosAno = parseInt(cumple[0]);
      let edad = hoy.getFullYear() - cumpleanosAno;

      return edad;
    }
  }
  
  function sacarEdadyGenero(){
    const fecha = document.getElementById('fechaNacimiento').value;
    const auxSexo = document.getElementById('genero').value;
    setSexo(auxSexo);
    if(fecha !== ""){
      if(parseInt(edadRegistrada) !== calcularEdad(fecha)){
        setMostrar(false);
      }
      setEdadRegistrada(calcularEdad(fecha));
    }
  }

  function sacarCategoriaInscita(){
    let auxCategoria = new Set();
    listPruebas.forEach((lisPrue)=>{
      const relevo = lisPrue.prueba.match(/Relevo/g);
      if(!relevo){
        lisPrue.requisitos.forEach((req)=>{
          if(req.genero === 'Mixto'){
            if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
              auxCategoria.add(lisPrue.categoria);
            }
          }else{
            if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada && req.genero === sexo){
              auxCategoria.add(lisPrue.categoria);
            }
          }
        })
      }
    })
    return Array.from(auxCategoria);
  }

  function elegirPruebas(){
    setMostrar(true);
    if(!edadRegistrada || sexo === ''){
      toast.error('Por favor, llena primero los campos.');
    }else{
      const mostrarJornadas = [];
      const categorias = new Set();
      torneoEspecifico.jornadas.forEach((jor)=>{
        const auxPruebas = [];
        const auxJornada = {}
        jor.categoria.forEach((cate)=>{
          const sacarCategoria = sacarCategoriaInscita();
          if(sacarCategoria.includes(cate)){
            categorias.add(cate);
            jor.pruebas.forEach((prue)=>{
              const auxPrueba = listPruebas.filter((listPrue)=>{return listPrue.id === prue.idPrueba});
              if(auxPrueba.length !== 0){
                const auxP = new Set();
                const relevo = auxPrueba[0].prueba.match(/Relevo/g);
                if(!relevo){
                  auxPrueba[0].requisitos.forEach((req)=>{
                    if(req.genero === 'Mixto'){
                      if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
                        auxP.add(auxPrueba[0]);
                      }
                    }else{
                      if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada && req.genero === sexo){
                        auxP.add(auxPrueba[0]);
                      }
                    }
                  })
                }
                auxPruebas.push(...Array.from(auxP));
              }
            })
          }
        })

        const sacarPruebasRepetidas = new Set();
        const auxIdsPruebas = new Set();

        auxPruebas.forEach((prue)=>{
          if(!auxIdsPruebas.has(prue.id)){
            auxIdsPruebas.add(prue.id);
            sacarPruebasRepetidas.add(prue);
          }
        })

        if(Array.from(sacarPruebasRepetidas).length !== 0){
          auxJornada.jornada = jor.jornada;
          auxJornada.pruebas = Array.from(sacarPruebasRepetidas);
          mostrarJornadas.push(auxJornada);
        }
      })

      if(mostrarJornadas.length === 0){
        toast.error('La categoria que intenta registrar, no compite en este torneo.');
      }
      
      setMostrarJornadas(mostrarJornadas);
      setMostrarCategorias(Array.from(categorias));
    }
  }

  function registrarFirebase(id, deportistaARegistrar){
    if(clubElegido){
      let auxArr = [];
      const auxNadadores = [];
      nadadores.forEach((nad)=>{
        if(nad.identificacion !== deportistaARegistrar.identificacion){
          auxNadadores.push(nad);
        }
      })
  
      if(auxNadadores.length !== 0){
        if(auxNadadores.length < 1){
          auxArr = [deportistaARegistrar, auxNadadores];
          registrarNadador(deportistaARegistrar.nombre, id, auxArr, clubElegido.idClub);
        }else{
          auxArr = [deportistaARegistrar, ...auxNadadores];
          registrarNadador(deportistaARegistrar.nombre, id, auxArr, clubElegido.idClub);
        }
      }else{
        auxArr = [deportistaARegistrar];
        registrarNadador(deportistaARegistrar.nombre, id, auxArr, clubElegido.idClub);
      }
    }else{
      toast.error('No has elegido un club aún.');
    }
  }

  function nombreNadadorMayuscula(nombre){
    const nombreMinuscula = nombre.toLowerCase();
    const nombrecompleto = nombreMinuscula.split(' ');
    let nombreMayuscula = '';
    for (let i = 0; i < nombrecompleto.length; i++) {
      nombreMayuscula += nombrecompleto[i].charAt(0).toUpperCase() + nombrecompleto[i].slice(1) + " ";
    }
    return nombreMayuscula.trim();
  }

  function sacarPruebas(){
    const auxPruebas = [];
    const auxPruebasTiempos = [];
    const pruebasTimes = [];
    const pruebas = [];
    for (let i = 0; i < torneoEspecifico.jornadas.length; i++) {
      for (let j = 0; j < parseInt(torneoEspecifico.jornadas[i].cantPruebas); j++) {
        const prueba = document.getElementById(`jornada${i}prueba${j}`) !== null ? document.getElementById(`jornada${i}prueba${j}`).value : '';
        const pruebaTiempoMin = document.getElementById(`jornada${i}prueba${j}TiempoMinutos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoMinutos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoMinutos`).value : '0' : '';
        const pruebaTiempoSeg = document.getElementById(`jornada${i}prueba${j}TiempoSegundos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoSegundos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoSegundos`).value : '0' : '';
        const pruebaTiempoMili = document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`).value : '0' : '';
        
        const auxTiempos = { 
          prueba: prueba,
          record: pruebaTiempoMin + ':' + pruebaTiempoSeg + ':' + pruebaTiempoMili
        }
        
        auxPruebas.push(prueba);
        auxPruebasTiempos.push(auxTiempos);
      }
    }

    auxPruebas.forEach((auxPrue)=>{
      if(auxPrue !== ''){
        pruebas.push(auxPrue);
      }
    })
    
    auxPruebasTiempos.forEach((auxPrue)=>{
      if(auxPrue.prueba !== ''){
        pruebasTimes.push(auxPrue);
      }
    })
    
    setPruebasTiempos(pruebasTimes);
    setPruebasId(pruebas);
  }

  function validarPruebas(arr) {
    const conjunto = new Set();
    for (const elemento of arr) {
      // Verificar si el elemento no es vacío
      if (elemento !== "") {
        if (conjunto.has(elemento)) {
          // Elemento duplicado encontrado
          return true;
        }
        conjunto.add(elemento);
      }
    }
    // No se encontraron elementos duplicados
    return false;
  }

  function sacarIdentificaciones(ti){
    let identificacion = false;
    if(!modificar){
      deportistas.forEach((deport)=>{
        if(deport.identificacion === ti){
          identificacion = true;
        }
      })
    }

    return identificacion;
  }

  function validarIdentificacion(ti){
    let valido = true;
    if(ti.length < 6 || ti.length > 10 || /^(\d)\1+$/.test(ti)){
      valido = false;
    }
    return valido;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const auxNombreClub = document.getElementById('clubPers') ? document.getElementById('clubPers').value : '';
    const deportistaARegistrar = {
      club: document.getElementById('abrev') ? document.getElementById('abrev').value : '',
      nombre: nombreNadadorMayuscula(e.target.nombre.value),
      identificacion: e.target.identificacion.value,
      fechaNacimiento: e.target.fechaNacimiento.value,
      edad: parseInt(e.target.edad.value),
      genero: e.target.genero.value,
      nombreclub: auxNombreClub.toUpperCase(),
      pruebas: pruebasId,
      categoria: sacarEdadDeLaCategoria(sacarCategoriaInscita(), edadRegistrada, listadoCategorias, listPruebas, pruebasId),
      verificado: e.target.verificacion.value
    }
    if(validarPruebas(deportistaARegistrar.pruebas)){
      toast.error('Al parecer intentas registrar al deportista en 2 o mas pruebas iguales, eso no es posible, por favor verifica para poder continuar.');
    }else if(deportistaARegistrar.pruebas.length === 1 && deportistaARegistrar.pruebas[0] === ''){
      toast.error('No has seleccionado las pruebas todavía. En caso de que las hayas elegido y el sistema no te permita avanzar. Por favor, recarga la plataforma.');
    }else if(!validarPruebas(deportistaARegistrar.pruebas) && deportistaARegistrar.pruebas.length <= parseInt(torneoEspecifico.jornadas[0].cantPruebas)){
      if(sacarIdentificaciones(deportistaARegistrar.identificacion) || !validarIdentificacion(deportistaARegistrar.identificacion)){
        if(sacarIdentificaciones(deportistaARegistrar.identificacion)){
          toast('Ya existe un/una deportista con esta identificación ' + deportistaARegistrar.identificacion,{
            icon: '🤨'
          })
        }
        if(!validarIdentificacion(deportistaARegistrar.identificacion)){
          toast('Esta identificación ' + deportistaARegistrar.identificacion + ' no es valida',{
            icon: '🤨'
          })
        }
      }else{
        if(modificar){
          if(deportistaARegistrar.pruebas.length === 0){
            toast('Debes hacer una modificación en las pruebas o darle clic en una de las pruebas.',{
              icon: '🤨'
            })
          }else{
            organizarPorTiempos(deportistaARegistrar.identificacion, pruebasTiempos, mostrarJornadas, torneoEspecifico?.piscina).then((org)=>{
              deportistaARegistrar.records = org;
              registrarFirebase(idTorneoParticipando, deportistaARegistrar);
            });
            if(!modificar && clubElegido){
              deportistaRegistradoPWS(datosAdmin.conexion, torneoEspecifico.id);
            }
            setEdadRegistrada();
            setDeportistaGeneral();
            setMostrarJornadas([]);
            setModificar(false);
            e.target.reset();
          }
        }else{
          organizarPorTiempos(deportistaARegistrar.identificacion, pruebasTiempos, mostrarJornadas, torneoEspecifico?.piscina).then((org)=>{
            deportistaARegistrar.records = org;
            registrarFirebase(idTorneoParticipando, deportistaARegistrar);
          });
          if(!modificar && clubElegido){
            deportistaRegistradoPWS(datosAdmin.conexion, torneoEspecifico.id);
          }
          setEdadRegistrada();
          setDeportistaGeneral();
          setMostrarJornadas([]);
          setModificar(false);
          e.target.reset();
        }
      }
    }else{
      toast(`Has excedido el límite máximo de pruebas permitidas, máximo (${torneoEspecifico.jornadas[0].cantPruebas}).`,{
        icon: '🤨'
      })
    }
  };

  function anadirCamposDirectivo(index){
    const auxDirectivos = [...directivos];
    const auxObjDirectivo = {nombre: '', cel: '', rol: ''};
    auxDirectivos.splice(index + 1, 0, auxObjDirectivo);
    setDirectivos(auxDirectivos);
  }

  function quitarCamposDirectivo(index){
    const auxDirectivos = [...directivos];
    if(auxDirectivos.length > 1){
      auxDirectivos.splice(index, 1);
      setDirectivos(auxDirectivos);
    }
  }

  function registrarDirectivos(){
    if(clubElegido){
      const auxDirectivos = [...directivos];
      for (let i = 0; i < directivos.length; i++) {
        const nombreDire = document.getElementById(`nombreDirectivo${i}`) !== null ? document.getElementById(`nombreDirectivo${i}`).value : '';
        const celDire = document.getElementById(`cel${i}`) !== null ? document.getElementById(`cel${i}`).value : '';
        const rolDire = document.getElementById(`rol${i}`) !== null ? document.getElementById(`rol${i}`).value : '';
  
        auxDirectivos[i].nombre = nombreNadadorMayuscula(nombreDire);
        auxDirectivos[i].cel = celDire;
        auxDirectivos[i].rol = rolDire;
      }
  
      setDirectivos(auxDirectivos);
      registrarDirectivosClub(clubElegido.idClub, auxDirectivos, torneoEspecifico.id);
    }else{
      toast.error('No has elegido un club aún.');
    }
  }

  function sacarDirectivos(dire){
    if(dire){
      if(dire.length !== 0){
        return <div className={`dropdown`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
          <span className='btn btn-secondary btn-sm dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            Directivos {'(' + dire.length + ')'}</span>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {dire.map((di, indexDi)=>{
              return <li key={indexDi} className="dropdown-item">{di.nombre + ' - ' + di.rol + ' - ' + di.cel}</li>
            })}
          </ul>
        </div>
      }
    }
  }

  function onSubmitPlanilla(e){
    e.preventDefault();
    const noPrueba = e.target.id.value;
    const nombre = e.target.nombre.value;
    const identificacion = e.target.identificacion.value !== '' ? e.target.identificacion.value : '0000000000';
    const club = e.target.club.value;
    const abreviatura = clubes.filter((auxClub)=>{return auxClub.nombreclub === club})[0]?.abreviatura;
    const edad = parseInt(e.target.edad.value);
    const serie = parseInt(e.target.serie.value);
    const carril = parseInt(e.target.carril.value);
    const records = {prueba: '', record: ''};
    const categoria = sacarEdadDeLaCategoria(sacarCategoriaInscita(), edadRegistrada, listadoCategorias, listPruebas, pruebasId);

    traerPlanillaPSC(idTorneoParticipando, noPrueba).then((planilla) =>{
      if(inscribirEliminar === 'inscribir'){
        if(planilla){
          if(planilla.planilla.length !== 0 && planilla.planilla[serie - 1]){
            if(planilla.planilla[serie - 1].nadadores[carril - 1].nombre.length !== 0){
              planilla.planilla[serie - 1].nadadores[carril - 1].nombre = [nombre];
              planilla.planilla[serie - 1].nadadores[carril - 1].nombreclub = [club];
              planilla.planilla[serie - 1].nadadores[carril - 1].identificacion = [identificacion];
              planilla.planilla[serie - 1].nadadores[carril - 1].edad = [edad];
              planilla.planilla[serie - 1].nadadores[carril - 1].club = [abreviatura];
              planilla.planilla[serie - 1].nadadores[carril - 1].records = [records];
              planilla.planilla[serie - 1].nadadores[carril - 1].categoria = categoria;

              const alerta = Swal.mixin({
                toast: true,
                showCancelButton: true
              })
              alerta.fire({
                html: `<div class="w-100 position-relative">
                <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
                <p style="z-index: 10; font-family: Poppins" class="mb-0">el carril ${carril} ya esta ocupado. </br> ¿Desea reemplazar?</p>
                </div>`,
                confirmButtonText: 'Reemplazar',
                confirmButtonColor: 'rgb(18, 53, 248)',
                padding: 8,
                cancelButtonText: 'Cancelar',
              }).then((respuesta) =>{
                if(respuesta.isConfirmed){
                  registrarPlanillaPSC(idTorneoParticipando, noPrueba, planilla);
                }
              })
            }else{
              planilla.planilla[serie - 1].nadadores[carril - 1].nombre = [nombre];
              planilla.planilla[serie - 1].nadadores[carril - 1].nombreclub = [club];
              planilla.planilla[serie - 1].nadadores[carril - 1].identificacion = [identificacion];
              planilla.planilla[serie - 1].nadadores[carril - 1].edad = [edad];
              planilla.planilla[serie - 1].nadadores[carril - 1].club = [abreviatura];
              planilla.planilla[serie - 1].nadadores[carril - 1].records = [records];
              planilla.planilla[serie - 1].nadadores[carril - 1].categoria = categoria;
              registrarPlanillaPSC(idTorneoParticipando, noPrueba, planilla);
            }
          }else{
            toast.error('La serie no esta registrada en la programación.');
          }
        }else{
          toast.error('La prueba no esta registrada en la programación.');
        }
      }else{
        planilla.planilla[serie - 1].nadadores[carril - 1].nombre = [];
        planilla.planilla[serie - 1].nadadores[carril - 1].nombreclub = [];
        planilla.planilla[serie - 1].nadadores[carril - 1].identificacion = [];
        planilla.planilla[serie - 1].nadadores[carril - 1].edad = [];
        planilla.planilla[serie - 1].nadadores[carril - 1].club = [];
        planilla.planilla[serie - 1].nadadores[carril - 1].records = [];
        planilla.planilla[serie - 1].nadadores[carril - 1].categoria = [];
        registrarPlanillaPSC(idTorneoParticipando, noPrueba, planilla);
      }
    })
  }

  function onSubmitEnviarCorreo(e){
    e.preventDefault();
    const modoArchivo = e.target.modoArchivo.value;
    const toastId = toast.loading(`Enviando ${modoArchivo.toUpperCase()}...`);
    const archivo = e.target.documento.files[0];
    const auxFechaTorneo = new Date(torneoEspecifico.fechaTorneo);
    const fechaTorneo = auxFechaTorneo.toLocaleString('Es-es', {
      day: 'numeric',
      weekday: 'long',
      month: 'long',
      year: 'numeric'
    })
    let text = '';
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64 = reader.result.split(",")[1];
      const auxEmails = [];
      clubes.forEach((club) =>{
        if(club.nadadores.length !== 0){
          auxEmails.push(club.email);
        }
      })
      
      if(modoArchivo === 'resultados'){
        text = `
        <p><b>Cordial saludos,</b> 👋</p>

        <p>
          Espero que te encuentres bien. Te adjunto los resultados finales del evento <b>${torneoEspecifico.titulo.toUpperCase()} - ${datosAdmin.nombre}</b> 🏅 realizado el <b>${fechaTorneo}</b> 🗓️. Puedes encontrar la información detallada en el archivo adjunto.
        </p>

        <p>Si tienes alguna pregunta o necesitas más información, no dudes en contactarnos 📩.</p>
        <p><b>¡Gracias por tu participación y apoyo!</b> 🙌</p>

        <p style="margin: 0"><b>Admon. </b>${datosAdmin.encargado}</p>
        <p style="margin: 0"><b>${datosAdmin.nombre}</b></p>
        <p style="margin: 0"><b>Wpp: ${datosAdmin.celCorto}</b></p>
        <p style="margin-top: 0"><a href="${datosAdmin.plataforma}" target="_blank">${datosAdmin.plataforma}</a></p>

        <p style="margin: 0"><b>Ing. </b>Emanuel Barbosa Labrada</p>
        <p style="margin: 0"><b>PROWEBSPORTS</b></p>
        <p style="margin: 0"><b>Wpp: 3245884041</b></p>
        <p style="margin: 0"><a href="https://www.prowebsports.com.co" target="_blank">https://www.prowebsports.com.co</a></p>
        `;
        sendEmailDocumento(base64, `RESULTADOS ${torneoEspecifico.titulo.toUpperCase()}`, text, auxEmails, archivo.name).then(() =>{
          toast.dismiss(toastId);
          toast.success('Resultados enviados correctamente.');
        }).catch((err)=>{
          toast.dismiss(toastId);
          toast.success('¡UPS! Ocurrio un error. Intentalo de nuevo.');
        });
      }
      
      if(modoArchivo === 'programacion'){
        text = `
        <p><b>Cordial saludos,</b> 👋</p>

        <p>
          Espero que te encuentres bien. Te adjunto la programación del evento <b>${torneoEspecifico.titulo.toUpperCase()} - ${datosAdmin.nombre}</b> 🏊‍♂️. Puedes encontrar la información detallada en el archivo adjunto.
        </p>

        <p>Si tienes alguna pregunta o necesitas más información, no dudes en contactarnos 📩.</p>
        <p><b>¡Gracias por tu colaboración y apoyo!</b> 🙌</p>

        <p style="margin: 0"><b>Admon. </b>${datosAdmin.encargado}</p>
        <p style="margin: 0"><b>${datosAdmin.nombre}</b></p>
        <p style="margin: 0"><b>Wpp: ${datosAdmin.celCorto}</b></p>
        <p style="margin-top: 0"><a href="${datosAdmin.plataforma}" target="_blank">${datosAdmin.plataforma}</a></p>

        <p style="margin: 0"><b>Ing. </b>Emanuel Barbosa Labrada</p>
        <p style="margin: 0"><b>PROWEBSPORTS</b></p>
        <p style="margin: 0"><b>Wpp: 3245884041</b></p>
        <p style="margin: 0"><a href="https://www.prowebsports.com.co" target="_blank">https://www.prowebsports.com.co</a></p>
        `;
        sendEmailDocumento(base64, `PROGRAMACIÓN ${torneoEspecifico.titulo.toUpperCase()}`, text, auxEmails, archivo.name).then(() =>{
          toast.dismiss(toastId);
          toast.success('Programación enviada correctamente.');
        }).catch((err)=>{
          toast.dismiss(toastId);
          toast.success('¡UPS! Ocurrio un error. Intentalo de nuevo.');
        });
      }
    }
    reader.readAsDataURL(archivo);
    setArchivo({size: 0});
    e.target.reset();
  }

  function onChangeClubElegido(club){
    const auxClubElegido = clubes.filter((clu)=>{return clu.nombreclub === club})
    if(auxClubElegido.length !== 0){
      setClubesElegido(auxClubElegido[0]);
      if(auxClubElegido[0].directivos){
        setDirectivos(auxClubElegido[0].directivos);
      } 
    }
  }

  function registrarClubPersonalizado(e){
    e.preventDefault();
    const club = {
      idClub: generarId(),
      abreviatura: '',
      email: '',
      nadadores: [],
      nombreclub: (e.target.nombreClubPers.value).toUpperCase(),
      photo: null
    }
    const auxClubEncontrado = clubes.filter((auxClub) => {return auxClub.nombreclub === club.nombreclub});
    if(club.nombreclub !== ''){
      if(auxClubEncontrado.length === 0){
        registrarClubAlTorneo(idTorneoParticipando, club , club.idClub, club.photo, club, torneoEspecifico);
        toast.success('Club ' + club.nombreclub + ', registrado correctamente.');
      }else{
        toast.error('El club ' + club.nombreclub + ', ya esta inscrito.');
      }
    }else{
      toast.error('No has seleccionado ningún club. Si no encuentras el club en la lista, dirígete a la sección de autorizaciones para registrarlo. Luego, regresa y podrás buscar el club nuevamente.');
    }
    e.target.reset();
  }

  function pruebasExtras(cant, prueba, indexPrueba){
    const auxCantPruebas = Array.from({ length: cant}, (_, index) => index);
     return auxCantPruebas.map((items, indexPrueExtra)=>(
      <tr key={indexPrueExtra}>
        <td>
          <div className="form-floating mb-2">
            <select style={{minWidth: '100px'}} className='form-select' id={`jornada${indexPrueba}prueba${indexPrueExtra}`} defaultValue={modificar ? deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].prueba : '' : '0'} onChange={() => sacarPruebas()}>
              <option value=''>{`Ninguna`}</option>
              {
                prueba.pruebas.map((prue, indexPrue)=>{
                  return <option key={indexPrue} value={prue.id}>{prue.prueba + ' - ' + prue.categoria}</option>
                })
              }
            </select>
            <label htmlFor="genero">{prueba.pruebas.length} Pruebas Disponibles</label>
          </div>
        </td>
        <td className='d-flex justify-content-around align-items-center'>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoMinutos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[0] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Min.</label>
          </div>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoSegundos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[1] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Seg.</label>
          </div>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoMilisegundos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[2] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Mili.</label>
          </div>
        </td>
      </tr>
     ))
  }

  const header = (
    <div className="d-flex justify-content-start align-items-center">
      <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
      </span>
    </div>
  );

  const agregarInscrito = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button className='me-1' icon="pi pi-plus" severity="success" raised tooltip='Inscribir' tooltipOptions={{position: 'right', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogInscripcion(true)}/>
        <Button icon="pi pi-file-edit" severity="primary" raised tooltip='PSC' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogPSC(true)}/>
        <Button className='ms-1' icon="pi pi-file-pdf" severity="danger" raised tooltip='Reporte .pdf' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => clubesInscritosToPdf(clubes, setPdfUrl, torneoEspecifico, usuario, setDescripcion)}/>
        <Button className='ms-1' icon="pi pi-envelope" severity="secondary" raised tooltip='Enviar Correo' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogEnviarCorreo(true)}/>
      </div>
    );
  };

  const reporteInscrito = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button className='ms-1 me-1' icon="pi pi-file-pdf" severity="danger" raised tooltip='Reporte .pdf' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => reporteInscritos(pruebasCompletas, clubElegido, nadadores, setPdfUrl, torneoEspecifico)}/>
        <Button icon="pi pi-users" severity="primary" raised tooltip='Directivos' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogDirectivos(true)}/>
      </div>
    );
  };

  function sacarPruebasTiempos(nadador){
    const auxPrueba = [];
    nadador.records.forEach((prue)=>{
      const prueba = listPruebas.filter((listPrue)=>{return listPrue.id === prue.prueba});
      prue.nombre = prueba[0].prueba;
      auxPrueba.push(prue);
    })
    return auxPrueba;
  }

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={sacarPruebasTiempos(data)} emptyMessage="No hay pruebas registradas.">
        <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
        <Column field="nombre" header="Prueba" style={{ width: '110px'}}></Column>
        <Column field="record" header="Tiempo" style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  const acciones = (insc) => {
    return (
      <div>
        <Button icon="pi pi-pencil" text severity="secondary" tooltip='Editar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => modificarInscrito(insc)}/>
        <Button className='me-1' text icon="pi pi-times" severity="danger" tooltip='Eliminar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => clickNadador(insc)}/>
      </div>
    );
  };

  function clickNadador(insc){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">Esta seguro de eliminar a el/la deportista ${insc.nombre}
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{ 
      if(respuesta.isConfirmed){
        const auxNadadores = [...nadadores]
        const index = auxNadadores.findIndex((nad)=> nad.identificacion === insc.identificacion);
        if(index !== -1){
          auxNadadores.splice(index, 1);
          eliminarNadador(insc.nombre, idTorneoParticipando, usuario.id, auxNadadores);
          sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
          deportistaEliminadoPWS(datosAdmin.conexion, torneoEspecifico.id);
        }
      }
    })
  }

  function modificarInscrito(insc){
    setDialogInscripcion(true);
    torneoEspecifico.jornadas.forEach((jor, indexJor)=>{
      const auxRecords = []; 
      jor.pruebas.forEach((prueJor)=>{
        insc.records.forEach((inscPrue)=>{
          if(prueJor.idPrueba === inscPrue.prueba){
            auxRecords.push(inscPrue);
          }
        })
      })

      insc['records' + indexJor] = auxRecords;
      insc['records' + indexJor].forEach((rec)=>{
        rec.record = rec.record.length !==  3 ? rec.record.split(':') : rec.record;
      })
    })
    setEdadRegistrada(calcularEdad(insc.fechaNacimiento));
    setDeportistaGeneral(insc);
    setModificar(true);
  }

  const footerGroupDeportista = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={8} footerStyle={{ textAlign: 'right' }} />
        <Column footer={nadadores.length !== 0 ? ((nadadores.length * (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista) : 0)) + (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorClub) : 0)).toLocaleString() :(0)} />
      </Row>
    </ColumnGroup>
  );

  function sacarCantidadPruebas(jor){
    let auxPruebas = 0;
    jor.requisitos.forEach((req)=>{
      if(parseInt(req.edadStart) <= edadRegistrada && parseInt(req.edadEnd) >= edadRegistrada){
        auxPruebas = parseInt(req.cantPruebas);
      }
    })
    return auxPruebas;
  }
  
  const content = (
    <div className="d-flex align-items-center"> 
      <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
      <div className="ms-2 text-danger">Sin Pruebas: <b>{nadadoresSinPruebas.toLocaleString()}</b></div>
    </div>
  );

  useEffect(() => {
    traerNadadores(idTorneoParticipando, clubElegido?.idClub, setNadadores, setNadadoresRelevo);
  }, [clubElegido])

  useEffect(() => {
    if(!dialogInscripcion && !dialogPSC){
      setEdadRegistrada();
      setDeportistaGeneral();
      setMostrarJornadas([]);
      setModificar(false);
      setClubesElegido();
    }
  }, [dialogInscripcion, dialogPSC])

  useEffect(() => {
    traerPruebasParaRegistroJPC(setListPruebas);
  }, [idTorneoParticipando])

  useEffect(() => {
    traerClubes(idTorneoParticipando, setClubes);
  }, [idTorneoParticipando])

  useEffect(() => {
    sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
  }, [idTorneoParticipando, clubes])

  useEffect(() =>{
    if(modificar && !dialogInscripcion || !pdfUrl){
      const auxNadadores = [...nadadores];
      const nuevoNadadores = [];
      auxNadadores.forEach((auxNad)=>{
        const objNadador = {
          club: auxNad.club,
          edad: auxNad.edad,
          fechaNacimiento: auxNad.fechaNacimiento,
          genero: auxNad.genero,
          identificacion: auxNad.identificacion,
          nombre: auxNad.nombre,
          nombreclub: auxNad.nombreclub,
          pruebas: auxNad.pruebas,
          records: [],
          categoria: auxNad.categoria,
          verificado: auxNad.verificado
        }
        auxNad.records.forEach((rec)=>{
          const objRecord = {
            prueba: rec.prueba
          }
          if(rec.record.length === 3){
            objRecord.record = `${rec.record[0]}:${rec.record[1]}:${rec.record[2]}`
          }else{
            objRecord.record = rec.record;
          }

          objNadador.records.push(objRecord);
        })

        nuevoNadadores.push(objNadador);
      })

      setNadadores(nuevoNadadores);
    }
  },[dialogInscripcion, modificar, pdfUrl])
  
  //Contabilidad
  function cantClubesPagar(club){
    let numero = 0;
    club.props.value.forEach((clu)=>{
      if(clu.nadadores.length !== 0){
        numero++;
      }
    })
    return numero;
  }

  const rowExpansionTemplateContabilidad = (data) => {
    return (
      <DataTable value={data.nadadores} footerColumnGroup={footerGroupDeportistaContabilidad} emptyMessage="No hay deportistas inscritos aún.">
        <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
        <Column body={(insc)=> <label><i title={insc.verificado === 'Verificado' ? 'Verificado' : 'No Verificado'} className={`pi pi-id-card ${insc.verificado === 'Verificado' ? 'text-success' : 'text-danger'}`}></i></label>} style={{ width: '10px'}}></Column>
        <Column field="nombre" header="Deportista" style={{ width: '110px'}}></Column>
        <Column field="identificacion" header="Identificación" style={{ width: '80px'}}></Column>
        <Column field="fechaNacimiento" header="Fecha Nto" style={{ width: '80px'}}></Column>
        <Column field="edad" header="Edad" style={{ width: '80px'}}></Column>
        <Column field="genero" header="Genero" style={{ width: '80px'}}></Column>
        <Column header="Pruebas" body={(nadador)=>{return nadador.pruebas.length}} style={{ width: '80px'}}></Column>
        <Column header="Aporte" body={()=>{return `$${torneoEspecifico.length !== 0 ? (parseInt(torneoEspecifico.valorDeportista)).toLocaleString() : 0}`}} style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={6} footerStyle={{ textAlign: 'right' }} />
        <Column footer={(club)=>{return `$${torneoEspecifico.length !== 0 ? (parseInt(torneoEspecifico.valorClub) * cantClubesPagar(club)).toLocaleString() : 0}`}} />
        <Column footer={`$${parseInt(contabilidad).toLocaleString()}`} />
        <Column />
      </Row>
    </ColumnGroup>
  );

  const footerGroupDeportistaContabilidad = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={8} footerStyle={{ textAlign: 'right' }} />
        <Column footer={(club)=>{return `$${torneoEspecifico.length !== 0 ? (parseInt(torneoEspecifico.valorDeportista) * club.props.value.length).toLocaleString() : 0}`}} />
      </Row>
    </ColumnGroup>
  );

  const accionesContabilidad = (club) => {
    return (
      <div className='d-flex'>
        <Checkbox onChange={e => registrarFacturacionParticipacion(idTorneoParticipando, club.idClub, e.checked)} checked={club.pago ? club.pago : false}></Checkbox>
      </div>
    );
  };

  useEffect(() => {
    function evaluacionDelTorneo(){
      let contadorClubes = 0;
      let contadorDeportistas = 0;
      clubes.forEach((club)=>{
        if(club.nadadores.length !== 0){
          contadorClubes += 1;
          club.nadadores.forEach((nad)=>{
            contadorDeportistas += 1;
          })
        }
      })
      const total = ((contadorClubes * (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorClub) : 0)) + (contadorDeportistas * (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista) : 0)));
      setContabilidad(total);
    }

    evaluacionDelTorneo();
  }, [clubes])

  useEffect(() =>{
    buscarNombresAutorizados(setNombresAutorizados);
  },[])

  useEffect(() =>{
    const auxNadadoresSinPruebas = [];
    nadadores.forEach((nad)=>{
      if(nad.pruebas.length === 0){
        auxNadadoresSinPruebas.push(nad.nombre)
      }
    })

    if(auxNadadoresSinPruebas.length !== 0){
      setNadadoresSinPruebas(auxNadadoresSinPruebas);
    }else{
      setNadadoresSinPruebas([]);
    }
  },[nadadores, clubElegido])

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='bg-light'>
          <form onSubmit={registrarClubPersonalizado}>
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <h1>{torneoEspecifico.titulo ? torneoEspecifico.titulo.toUpperCase() : ''}</h1>
              <p style={{textAlign: 'justify'}}>
              Asegúrate de que el club esté registrado en el sistema de autorizaciones. 
              Si no lo está, la abreviatura no será registrada y se mostrará sin ella. 
              Además, el nombre del club que estás registrando debe coincidir exactamente con el registrado en el sistema de autorizaciones; 
              de lo contrario, la abreviatura tampoco se mostrará.
              </p>
            </div>
            <div className='d-flex flex-wrap justify-content-center'>
              <div className="form-floating mb-2">
                <select name="nombreClubPers" id="nombreClubPers" className='form-select' aria-label="Default select example">
                  <option value="">Elige Club</option>
                  {nombresAutorizados.length !== 0 ?(
                    nombresAutorizados.map((club, indexClub) =>{
                      return <option key={indexClub} value={club.nombre}>{club.nombre}</option>
                    })
                  ):(
                    <></>
                  )}
                </select>
                <label htmlFor="nombreClubPers">Nombre Club</label>
              </div>
            </div>
            <Button type='submit' icon='pi pi-check-circle' className='btn btn-success mb-2' label="Inscribir Club" style={{borderRadius: '10px'}} />
          </form>
        </div>
        <Toolbar left={agregarInscrito}></Toolbar>
        <DataTable value={clubes.filter((club) => club.nadadores.length !== 0)} size='small' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} stripedRows emptyMessage="No hay clubes inscritos aún."
        expandedRows={expandedRowsContabilidad} onRowToggle={(e) => setExpandedRowsContabilidad(e.data)} rowExpansionTemplate={rowExpansionTemplateContabilidad} footerColumnGroup={footerGroup} tableStyle={{ minWidth: '72rem', margin: 'auto'}}>
          <Column expander style={{ width: '30px' }} />
          <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
          <Column header="Directivos" style={{ width: '160px'}} body={(club) => {return sacarDirectivos(club.directivos)}}></Column>
          <Column field="nombreclub" header="Club" style={{ width: '130px'}}></Column>
          <Column field="email" header="E-mail" style={{ width: '120px'}}></Column>
          <Column header="Deportistas" body={(club) => {return club.nadadores.length}} style={{ width: '120px'}}></Column>
          <Column header="Aporte" body={(club) => {return <span>{'$ ' + (torneoEspecifico.length !== 0 ? club.nadadores.length !== 0 ? parseInt(torneoEspecifico.valorClub).toLocaleString() : 0 : 0)}</span>}} style={{ width: '120px'}}></Column>
          <Column header="Total" body={(club)=>{return <span>{`$${club.nadadores.length !== 0 ? ((club.nadadores.length * (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista) : 0)) + (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorClub) : 0)).toLocaleString():(0)}`}</span>}} style={{ width: '80px'}}></Column>
          <Column header="Pagado" body={(club) =>{return accionesContabilidad(club)}} style={{ width: '120px'}}></Column>
        </DataTable>
        <Dialog visible={dialogInscripcion} maximizable style={{ width: '46rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header='INSCRIPCIÓN PERSONALIZADA' modal className="p-fluid" onHide={() => setDialogInscripcion(false)}>
          <div className="field">
            <div className="form-floating mb-1">
              <select name="clubPers" id="clubPers" className='form-select' aria-label="Default select example" onChange={(e) => onChangeClubElegido(e.target.value)}>
                <option value="">Elige Club</option>
                {clubes.length !== 0 ?(
                  clubes.map((club, indexClub) =>{
                    return <option key={indexClub} value={club.nombreclub}>{club.nombreclub}</option>
                  })
                ):(
                  <></>
                )}
              </select>
              <label htmlFor="clubPers">Nombre Club</label>
            </div>
            <div>
              <div className="form-floating">
                <input type="text" disabled id='abrev' className='form-control mb-2' defaultValue={clubElegido ? clubElegido.abreviatura : ''}/>
                <label htmlFor="abrev">Abreviatura</label>
              </div>
            </div>
            <hr className='m-1'/>
            <form onSubmit={onSubmit} className='text-center'>
              <input className='form-control' type="hidden" name="edad" id='edad' value={edadRegistrada || ''}/>
              <div className="alert alert-warning alert-dismissible fade show mb-1 p-1" role="alert">
                <b>Edad Inscrito: </b>{deportistaGeneral?.verificado === 'Verificado' ? <Tag severity={'success'} icon={'pi pi-user'} value={(edadRegistrada || '0') + ' Años Verificado'}></Tag> : <Tag severity={'danger'} icon={'pi pi-user'} value={(edadRegistrada || '0') + ' Años No Verificado'}></Tag>}
              </div>
              <div className='d-flex flex-wrap justify-content-between'>
                <div className="form-floating">
                  <input className='form-control mb-2' type="text" maxLength={29} name="nombre" id='nombre' required defaultValue={deportistaGeneral ? deportistaGeneral.nombre : ''} style={{width: '270px'}} />
                  <label htmlFor="nombre">Nombre Completo</label>
                </div>
                <div className="form-floating">
                  <input className='form-control mb-2' type="number" name="identificacion" id='identificacion' required disabled={modificar} defaultValue={deportistaGeneral ? deportistaGeneral.identificacion : ''} style={{width: '150px'}}/>
                  <label htmlFor="identificacion">Identificación</label>
                </div>
                <div className="form-floating">
                  <input className='form-control mb-2' type="date" name="fechaNacimiento" id='fechaNacimiento' required defaultValue={deportistaGeneral ? deportistaGeneral.fechaNacimiento : ''} onChange={sacarEdadyGenero} style={{width: '190px'}}/>
                  <label htmlFor="fechaNacimiento">Fecha De Nacimiento</label>
                </div>
                <div className="form-floating mb-2">
                  <select name="genero" id="genero" className='form-select' aria-label="Default select example" required onChange={sacarEdadyGenero} style={{width: '190px'}}>
                    <option value="">Elige</option>
                    <option value="Femenino">Femenino</option>
                    <option value="Masculino">Masculino</option>
                  </select>
                  <label htmlFor="genero">Género</label>
                </div>
                <input className='form-control mb-2' type="hidden" name="verificacion" id='verificacion' required defaultValue={deportistaGeneral ? deportistaGeneral.verificado : ''} style={{width: '190px'}}/>
              </div>
              <Button type='button' icon='pi pi-list' label='Elegir Pruebas' className='btn btn-primary' style={{borderRadius: '10px'}} onClick={() => elegirPruebas()}></Button>
              <Mensaje
                anchorId={`nombre`}
                place="bottom"
                content={'Solo se admiten 29 caracteres incluyendo el espacio.'}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
              <div className="alert alert-info alert-dismissible fade show mt-1 mb-0 p-1" role="alert">
                <b>Máximo {parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0)} Pruebas:</b> <Tag severity={parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0) < pruebasId.length ? 'danger' : 'success'} icon={parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0) < pruebasId.length ? 'pi pi-times-circle' : 'pi pi-check-circle'} value={pruebasId.length + ' Pruebas'}></Tag>
              </div>
              {mostrar ?(
                mostrarJornadas.length !== 0 ? (
                  mostrarJornadas.map((prueba, indexPrueba)=>{
                      return <table key={indexPrueba} className="table table-striped table-hover table-bordered">
                      <thead>
                        <tr>
                          <th colSpan={4}>{`Jornada ${prueba.jornada + 1}`}</th>
                        </tr>
                        <tr>
                          <th colSpan={4}>{acomodarTextoCategoria(mostrarCategorias, listadoCategorias)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pruebasExtras(sacarCantidadPruebas(torneoEspecifico.jornadas[prueba.jornada]), prueba, indexPrueba)}
                      </tbody>
                    </table>
                  })
                ):(
                  <></>
                )
              ):(
                <></>
              )}
              {mostrar ?(
                <div className="alert alert-info alert-dismissible fade show mt-1 mb-0 p-1" role="alert">
                  <b>Máximo {parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0)} Pruebas:</b> <Tag severity={parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0) < pruebasId.length ? 'danger' : 'success'} icon={parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantPruebas : 0) < pruebasId.length ? 'pi pi-times-circle' : 'pi pi-check-circle'} value={pruebasId.length + ' Pruebas'}></Tag>
                </div>
              ):(
                <></>
              )}
              <Button type='submit' icon='pi pi-user-plus' label={`${deportistaGeneral ? deportistaGeneral.records ? 'Modificar' : 'Inscribir Al Evento' : 'Inscribir Al Evento'}`} className='btn btn-success mt-1' style={{borderRadius: '10px'}} onClick={() => sacarPruebas()}></Button>
            </form>
            <hr className='m-1'/>
            <Toolbar left={reporteInscrito}></Toolbar>
            {nadadoresSinPruebas.length !== 0 ? (
              <div className="card">
                <Message
                  style={{
                      border: 'solid #696cff',
                      borderWidth: '0 0 0 6px',
                      color: '#696cff'
                  }}
                  className="border-danger justify-content-start"
                  severity="error"
                  content={content}
              />
              </div>
            ):(
              <></>
            )}
            <DataTable value={nadadores} selectionMode="single" dragSelection expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} header={header} globalFilter={globalFilter} size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No hay inscritos aún." 
            footerColumnGroup={footerGroupDeportista} tableStyle={{ minWidth: '62rem', margin: 'auto'}}>
              <Column expander style={{ width: '30px' }} />
              <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
              <Column body={(insc)=> <label><i title={insc.verificado === 'Verificado' ? 'Verificado' : 'No Verificado'} className={`pi pi-id-card ${insc.verificado === 'Verificado' ? 'text-success' : 'text-danger'}`}></i></label>} style={{ width: '10px'}}></Column>
              <Column field="nombre" header="Nombre" body={(nadador) => nadador.pruebas.length !== 0 ? nadador.nombre : <>{nadador.nombre}<i className='ms-1 pi pi-times-circle text-danger'/></>} style={{ width: '180px', fontWeight: 'bold' }}></Column>
              <Column field="identificacion" header="Identificación" style={{ width: '70px'}}></Column>
              <Column field="fechaNacimiento" header="Fecha Nto" style={{ width: '110px'}}></Column>
              <Column field="edad" header="Edad" style={{ width: '50px'}}></Column>
              <Column field="genero" header="Sexo" style={{ width: '50px'}}></Column>
              <Column header="Aporte" body={() => {return `$${torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista).toLocaleString() : 0}`}} style={{ width: '80px'}}></Column>
              <Column body={(insc)=> acciones(insc)} style={{ width: '106px'}}></Column>
            </DataTable>
            <hr />
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              <b>Importante:</b> Para asegurar el mejor funcionamiento al calcular la edad, evita hacer copiar y pegar o escribir directamente en el campo de la fecha de nacimiento. En su lugar, te recomendamos buscar directamente la fecha de nacimiento del deportista en el calendario para asegurarte de que la información sea precisa y se ingrese correctamente.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogDirectivos} style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Registro Directivos`} modal className="p-fluid" onHide={() => setDialogDirectivos(false)}>
          <div className="field">
            <div className="alert alert-info alert-dismissible fade show mb-1" role="alert">
              <b>Máximo {parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantDirectivos : 0)} Directivos.</b>
            </div>
            {directivos.map((dire, indexDire) =>{
              return <div key={dire.nombre !== '' ? `${dire.nombre}-${dire.rol}` : indexDire} className='d-flex flex-wrap justify-content-between'>
              <div className="form-floating">
                <input className='form-control mb-2' type="text" maxLength={29} id={`nombreDirectivo${indexDire}`} defaultValue={dire.nombre} />
                <label htmlFor={`nombreDirectivo${indexDire}`}>Nombre Completo</label>
              </div>
              <div className="form-floating">
                <input className='form-control mb-2' type="number" id={`cel${indexDire}`} style={{width: '140px'}} defaultValue={dire.cel}/>
                <label htmlFor={`cel${indexDire}`}>No. Celular</label>
              </div>
              <div className="form-floating mb-2">
                <select id={`rol${indexDire}`} className='form-select' defaultValue={dire.rol}>
                  <option value="">Elige</option>
                  <option value="Entrenador/a Principal">Entrenador/a Principal</option>
                  <option value="Entrenador/a">Entrenador/a</option>
                  <option value="Delegado/a">Delegado/a</option>
                  <option value="Medico">Medico</option>
                  <option value="Fisioterapeuta">Fisioterapeuta</option>
                  <option value="Logistica">Logistica</option>
                </select>
                <label htmlFor={`rol${indexDire}`}>Rol</label>
              </div>
              <div className='d-flex align-items-center'>
                {indexDire === directivos.length - 1 && parseInt(idTorneoParticipando !== '' ? torneoEspecifico.jornadas[0].cantDirectivos : 0) !== directivos.length ? (
                  <Button icon="pi pi-plus" className='me-1' severity="success" raised style={{borderRadius: '50%'}} onClick={() => anadirCamposDirectivo(indexDire)}/>
                ):(
                  <></>
                )}
                <Button icon="pi pi-minus" severity="danger" raised style={{borderRadius: '50%'}} onClick={() => quitarCamposDirectivo(indexDire)}/>
              </div>
            </div>
            })}
            <Button icon="pi pi-check-circle" label='Registrar Directivos' className='btn btn-success' onClick={() => registrarDirectivos()}/>
          </div>
        </Dialog>
        <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Reporte ${clubElegido ? clubElegido.nombreclub : ''}`} modal className="p-fluid" onHide={() => setPdfUrl()}>
          <div className="field">
            <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
              <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <Button icon="pi pi-download" className='p-2' text tooltip='Descargar Reporte' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '10px'}} onClick={() => descargar()}></Button>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogPSC} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`Modificación de la Programación`} modal className="p-fluid" onHide={() => setDialogPSC(false)}>
          <div className="field">
            <form onSubmit={onSubmitPlanilla}>
              <div className='d-flex flex-wrap justify-content-between'>
                <div className="form-floating">
                  <input className='form-control mb-2' type="number" name="id" id='id' required style={{width: '120px'}}/>
                  <label htmlFor="id">No. Prueba</label>
                </div>
                <div className="form-floating">
                  <input className='form-control mb-2' type="number" name="serie" id='serie' style={{width: '120px'}}/>
                  <label htmlFor="serie">No. Serie</label>
                </div>
                <div className="form-floating">
                  <input className='form-control mb-2' type="number" name="carril" id='carril' style={{width: '120px'}}/>
                  <label htmlFor="carril">No. Carril</label>
                </div>
                <div className="form-floating">
                  <input className='form-control mb-2' type="text" maxLength={29} name="nombre" id='nombre' required style={{width: '270px'}} />
                  <label htmlFor="nombre">Nombre Completo</label>
                </div>
                <div className="form-floating">
                  <input className='form-control mb-2' type="number" name="identificacion" id='identificacion' style={{width: '150px'}}/>
                  <label htmlFor="identificacion">Identificación</label>
                </div>
                <div className="form-floating">
                  <input className='form-control mb-2' type="number" name="edad" id='edad' style={{width: '90px'}} onChange={(e)=> setEdadRegistrada(e.target.value)}/>
                  <label htmlFor="edad">Edad</label>
                </div>
                <div className="form-floating mb-2">
                  <select name="club" id="club" className='form-select' aria-label="Default select example" required style={{width: '190px'}}>
                    <option value="">Elige</option>
                    {clubes.map((club, indexClub)=>{
                      return <option key={indexClub} value={club.nombreclub}>{club.nombreclub}</option>
                    })}
                  </select>
                  <label htmlFor="club">Club</label>
                </div>
              </div>
              <hr />
              <div className='d-flex justify-content-around flex-wrap'>
                <Button type='submit' icon="pi pi-check-circle" label='Inscribir' className='btn btn-success w-25' onClick={()=> setInscribirEliminar('inscribir')}></Button>
                <Button type='submit' icon="pi pi-times" label='Eliminar' className='btn btn-danger w-25' onClick={()=> setInscribirEliminar('eliminar')}></Button>
              </div>
            </form>
            <hr />
            <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
              <b>Importante:</b> Este espacio está destinado exclusivamente para corregir cualquier error que no se haya corregido durante la programación, días antes del evento. Por otro lado, si no se proporciona la identificación del niño, no será tenido en cuenta en el ranking de ProWebSports.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogEnviarCorreo} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`Enviar archivo al correo`} modal className="p-fluid" onHide={() => setDialogEnviarCorreo(false)}>
          <div className="field">
            <form onSubmit={onSubmitEnviarCorreo}>
              <div className='d-flex flex-column'>
                <div className="form-floating mb-1">
                  <select name="modoArchivo" id="modoArchivo" className='form-select' required>
                    <option value="">Elige</option>
                    <option value="resultados">Resultados</option>
                    <option value="programacion">Programación</option>
                  </select>
                  <label htmlFor="modoArchivo">Archivo</label>
                </div>
                <div>
                  <input className='form-control mb-1' type="file" name="documento" id='documento' required onChange={(ev) => setArchivo(ev.target.files[0])}/>
                </div>
                <div className="alert alert-info alert-dismissible fade show mt-1 mb-1" role="alert">
                  <b>Maximo 8MB:</b> El peso del archivo es de <Tag severity={Math.floor(archivo.size / (1024 * 1024)) < 8 ? 'success' : 'danger'} icon={Math.floor(archivo.size / (1024 * 1024)) < 8 ? 'pi pi-check-circle' : 'pi pi-times-circle'} value={Number(archivo.size / (1024 * 1024)).toFixed(1) + 'MB'}></Tag>
                </div>
                {Math.floor(archivo.size / (1024 * 1024)) > 8 ?(
                  <div className="alert alert-warning alert-dismissible fade show mt-1 mb-1" role="alert">
                    <b>Recomendación</b> El archivo es demasiado grande, puedes comprimirlo a .rar o .zip entre otros e intentarlo de nuevo.
                  </div>
                ):(
                  <></>
                )}
              </div>
              <hr className='m-1'/>
              <div className='d-flex justify-content-around flex-wrap'>
                <Button disabled={Math.floor(archivo.size / (1024 * 1024)) < 8 || archivo.size === 0 ? false : true} type='submit' icon="pi pi-send" label='Enviar' className='btn btn-success w-25' onClick={()=> setInscribirEliminar('inscribir')}></Button>
              </div>
            </form>
            <hr className='m-1'/>
            <div className="alert alert-danger alert-dismissible fade show mt-1" role="alert">
              <b>Importante:</b> Este espacio está destinado exclusivamente para enviar los resultados o la programación del evento a los correos electrónicos de los equipos inscritos. Si el archivo es mayor a 8 MB, puede comprimirse en formatos .rar, .zip o cualquier otro formato de compresión disponible.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
        </Dialog>
      </motion.div>
    </>
  );
}

export default ControlClubs;