import React, { useState, useEffect } from 'react';
import TablaPlanilla from './TablaPlanilla'
import { motion } from "framer-motion";
import { toast } from 'react-hot-toast';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { pdfPapeletas, pdfPlanilla } from '../controllers/pdfs';
import Conctatenos from './Conctatenos';
import { saveAs } from 'file-saver';
import { datosAdmin } from '../controllers/administrador';
import { Button } from 'primereact/button';

const PlanillaInvitado = ({ 
  idTorneoParticipando,
  pruebasCompletas, 
  torneoEspecifico,
  planilla,
  usuario,
  fechaRegistroProgramacion,
  recordsMundial,
  recordsNacional}) => {

  const [ nadadoresCarriles, setNadadoresCarriles ] = useState([]);
  const [ pdfUrl, setPdfUrl] = useState();
  const [ colorPdf, setColorPdf] = useState({r: 96, g: 255, b: 226});
  const [ listaClubes, setListaClubes ] = useState([]);
  const [ clubElegido, setClubElegido ] = useState(usuario.nombreclub);
  const [ idiomaElegido, setIdiomaElegido ] = useState('espanol');

  function descargar(){
    saveAs(pdfUrl, `programacion ${datosAdmin.nombre}.pdf`);
  }

  function sacarColorRGB(e){
    const auxColor = colorPdf;
    const auxNad = [...nadadoresCarriles];
    switch (e.target.value) {
      case 'blanco':
        auxColor.r = 255;
        auxColor.g = 255;
        auxColor.b = 255;
        break;
      case 'amarillo':
        auxColor.r = 234;
        auxColor.g = 255;
        auxColor.b = 96;
        break;
      case 'azul':
        auxColor.r = 96;
        auxColor.g = 255;
        auxColor.b = 226;
        break;
      case 'rojo':
        auxColor.r = 255;
        auxColor.g = 96;
        auxColor.b = 96;
        break;
    
      default:
        break;
    }
    setColorPdf(auxColor);
    setNadadoresCarriles(auxNad);
  }

  const items = [
    {
      label: 'Programación .pdf',
      icon: 'pi pi-palette',
      command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, {nombreclub: clubElegido}, false, true, colorPdf, recordsMundial, recordsNacional, idiomaElegido)
    },
  ];

  function sacarDeportistas(){
    setNadadoresCarriles(planilla);
  }

  function crearPapeletas(){
    const auxPapeletas = toast.loading('Creando papeletas...\nEsto puede tardar unos segundos o minutos.');
    setTimeout(function() {
      pdfPapeletas(planilla, setPdfUrl, pruebasCompletas, usuario, auxPapeletas);
    }, 500);
  }

  useEffect(() =>{
    if(usuario.nombreclub === datosAdmin.nombre){
      const nuevosItems = [
        {
          label: 'Programación Llamados .pdf',
          icon: 'pi pi-microphone',
          command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, true, false, 'blanco', recordsMundial, recordsNacional, idiomaElegido)
        },
        {
          label: 'Papeletas .pdf',
          icon: 'pi pi-id-card',
          command: () => crearPapeletas()
        },
      ]

      items.push(...nuevosItems);
      items.reverse();
    }
  },[items])

  useEffect(() => {
    sacarDeportistas();
  }, [])

  useEffect(() =>{
    const auxClubes = new Set();
    planilla.forEach(pla => {
      pla.forEach((serie)=>{
        serie.nadadores.forEach((nad)=>{
          if(nad.nombre[0]){
            auxClubes.add(nad.nombreclub[0]);
          }
        })
      })
    });
    setListaClubes(Array.from(auxClubes));
  },[])

  return (
    <div className='position-relative'>
      <motion.div
      initial={{ opacity: 0}}
      animate={{ opacity: 1}}
      transition={{duration: 1}}
      >
        <div className="alert alert-info alert-dismissible fade show text-center mb-0" role="alert">
          <h4>¡ULTIMO REGISTRO PROGRAMACIÓN!</h4>
          <p className="mb-0">{fechaRegistroProgramacion}</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div className="alert alert-success alert-dismissible fade show text-start mb-0" role="alert">
          <h4>¡IMPORTANTE!</h4>
          <p className="mb-0">Este espacio está destinado para que puedas ver la programación con tus deportistas marcados y así estar al tanto de su participación. Sin embargo, ten en cuenta que debes esperar que se registre la programación. Si aún no ves a tus deportistas, es probable que no se haya actualizado la programación. No descargues la programación hasta que el anfitrión la haya confirmado.</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div className='bg-light pt-1 d-flex flex-wrap'>
          {usuario.nombreclub === datosAdmin.nombre ? (
            <div className="form-floating mb-1 ms-1">
              <select name="club" id="club" className='form-select' defaultValue={`${clubElegido}`} onChange={(e) => setClubElegido(e.target.value)}>
                {listaClubes.length !== 0 ? (
                  listaClubes.map((list, indexList)=>{
                    return <option key={indexList} value={list}>{list}</option>
                  })
                ):(
                  <></>
                )}
              </select>
              <label className='d-flex align-items-center' htmlFor="club" style={{fontFamily: "Poppins"}}>CLUBS</label>
            </div>
          ):(
            <></>
          )}
          <div className="form-floating mb-1 ms-1">
            <select name="color" id="color" className='form-select' defaultValue={'azul'} onChange={sacarColorRGB}>
              <option value="blanco">Blanco</option>
              <option value="amarillo">Amarillo</option>
              <option value="azul">Azul (Predeterminado)</option>
              <option value="rojo">Rojo</option>
            </select>
            <label className='d-flex align-items-center' htmlFor="color" style={{fontFamily: "Poppins"}}>COLOR RESALTADOR <span className='ms-1' style={{border: `8px solid rgb(${colorPdf.r + ',' + colorPdf.g + ',' + colorPdf.b})`, boxShadow: '0 0 6px black'}}></span></label>
          </div>
          <div className="form-floating mb-1 ms-1">
            <select id="idioma" className='form-select' defaultValue={'espanol'} onChange={(ev) => setIdiomaElegido(ev.target.value)}>
              <option value="espanol">Español (Predeterminado)</option>
              <option value="ingles">Ingles</option>
            </select>
            <label className='d-flex align-items-center' htmlFor="idioma" style={{fontFamily: "Poppins"}}>Idioma Programación</label>
          </div>
        </div>
        {pruebasCompletas.lenght !== 0 ?(
          pruebasCompletas.map((prue, indexPrue)=>{
            return <TablaPlanilla key={indexPrue} jornada={indexPrue + 1} pruebas={prue} nadadores={nadadoresCarriles.length !== 0 ? nadadoresCarriles : []} idTorneoParticipando={idTorneoParticipando} invitado={true} usuario={{nombreclub: clubElegido}} colorPdf={colorPdf}/>
          })
          ):(
            <></>
          )}
      </motion.div>
      <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
      <SpeedDial className='position-fixed speeddial-bottom-right planilla' model={items} direction="up" transitionDelay={180} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-danger" style={{bottom: '10px', right: '10px'}}/>
      <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Programación`} modal className="p-fluid" onHide={() => setPdfUrl()}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
          </div>
          <Button icon="pi pi-download" label='Descargar Programación' className='p-2 btn btn-success' onClick={() => descargar()}></Button>
        </div>
      </Dialog>
      <Conctatenos/>
    </div>
  )  
}

export default PlanillaInvitado;