import React, { useState, useEffect } from 'react';
import { registrarTablasPlanilla, sacarNadadoresDeLosClubs, traerCategorias, traerRelevos } from '../controllers/configuracionFirebase';
import TablaPlanilla from './TablaPlanilla'
import { motion } from "framer-motion";
import { toast } from 'react-hot-toast';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { pdfPapeletas, pdfPlanilla } from '../controllers/pdfs';
import { Button } from 'primereact/button';
import { saveAs } from 'file-saver';
import { datosAdmin } from '../controllers/administrador';

const Planilla = ({ 
  idTorneoParticipando,
  pruebasCompletas, 
  torneoEspecifico,
  planilla,
  usuario,
  fechaRegistroProgramacion,
  fechaGoogle,
  recordsMundial,
  recordsNacional}) => {

  const [ nadadoresCarriles, setNadadoresCarriles ] = useState([]);
  const [ deportistas, setDeportistas ] = useState([]);
  const [ deportistasRelevos, setDeportistasRelevos ] = useState([]);
  const [ pdfUrl, setPdfUrl] = useState();
  const [ fechaHoraRegistro, setFechaHoraRegistro ] = useState('');
  
  function descargar(){
    saveAs(pdfUrl, `programacion ${datosAdmin.nombre}.pdf`);
  }

  function registrarTodasPlanillas(){
    const auxPruebas = [];
    pruebasCompletas.forEach((prueCompl)=>{
      prueCompl.forEach((prue)=>{
        auxPruebas.push(prue);
      })
    })
    
    for (let i = 0; i < auxPruebas.length; i++) {
      registrarTablasPlanilla(idTorneoParticipando, auxPruebas[i], nadadoresCarriles.length !== 0 ? nadadoresCarriles[i] : [], fechaHoraRegistro);
    }
    toast.success('Todas las tablas se estan registrado...\nEsto puede tardar unos segundos o minutos.');
  }
  
  function revolver(){
    traerCategorias(pruebasCompletas, setNadadoresCarriles, [...deportistas, ...deportistasRelevos], parseInt(torneoEspecifico.carril), torneoEspecifico.series);
  }

  function crearPapeletas(){
    const auxPapeletas = toast.loading('Creando papeletas...\nEsto puede tardar unos segundos o minutos.');
    setTimeout(function() {
      pdfPapeletas(planilla, setPdfUrl, pruebasCompletas, usuario, auxPapeletas);
    }, 500);
  }

  const items = [
    {
        label: 'Programación Llamados .pdf',
        icon: 'pi pi-microphone',
        command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, true, false, 'blanco', recordsMundial, recordsNacional)
    },
    {
        label: 'Programación .pdf',
        icon: 'pi pi-file-pdf',
        command: () => pdfPlanilla(planilla, setPdfUrl, torneoEspecifico, pruebasCompletas, usuario, false, false, 'blanco', recordsMundial, recordsNacional)
    },
    {
        label: 'Papeletas .pdf',
        icon: 'pi pi-id-card',
        command: () => crearPapeletas()
    },
    {
        label: 'Revolver',
        icon: 'pi pi-sync',
        command: () => revolver()
    },
    {
        label: 'Registrar Programación',
        icon: 'pi pi-pencil',
        command: () => registrarTodasPlanillas()
    },
  ];
  
  useEffect(() => {
    sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
    traerRelevos(idTorneoParticipando, setDeportistasRelevos);
  }, [])

  useEffect(() => {
    traerCategorias(pruebasCompletas, setNadadoresCarriles, [...deportistas, ...deportistasRelevos], parseInt(torneoEspecifico.carril), torneoEspecifico.series);
  }, [deportistas])

  useEffect(() =>{
    const date = new Date(fechaGoogle);
    // Extraer y formatear la fecha y la hora
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0-11
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Crear la cadena en el formato deseado
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setFechaHoraRegistro(formattedDateTime);
  },[])

  return (
    <div className='position-relative'>
      <motion.div
      initial={{ opacity: 0}}
      animate={{ opacity: 1}}
      transition={{duration: 1}}
      >
        <div className="alert alert-info alert-dismissible fade show text-center mb-0" role="alert">
          <h4>¡ULTIMO REGISTRO PROGRAMACIÓN!</h4>
          <p className="mb-0">{fechaRegistroProgramacion}</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        {pruebasCompletas.lenght !== 0 ?(
          pruebasCompletas.map((prue, indexPrue)=>{
            return <TablaPlanilla key={indexPrue} jornada={indexPrue + 1} pruebas={prue} nadadores={nadadoresCarriles.length !== 0 ? nadadoresCarriles : []} idTorneoParticipando={idTorneoParticipando} usuario={usuario} fechaHoraRegistro={fechaHoraRegistro}/>
          })
          ):(
            <></>
          )}
      </motion.div>
      <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
      <SpeedDial className='position-fixed speeddial-bottom-right planilla' model={items} direction="up" transitionDelay={100} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-danger" style={{bottom: '10px', right: '10px'}}/>
      <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Programación`} modal className="p-fluid" onHide={() => setPdfUrl()}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
          </div>
          <Button icon="pi pi-download" label='Descargar Programación' className='p-2 btn btn-success' onClick={() => descargar()}></Button>
        </div>
      </Dialog>
    </div>
  );
}

export default Planilla;