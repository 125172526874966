export const diccionarioEsAEn = {
    "Calentamiento": "Warm-up",
    "Inicio Competencia": "Start of competition",
    "Jornada": "Session",
    "Serie": "Heat",
    "resultados": "Results",


    "Menores": "Minors",
    "Infantil A": "Children A",
    "Infantil B": "Children B",
    "Juvenil A": "Youth A",
    "Juvenil B": "Youth B",
    "Mayores": "Seniors",
    "Abierto": "Open",
    "Master": "Masters",


    "25 Libre": "25 Freestyle",
    "50 Libre": "50 Freestyle",
    '100 Libre': "100 Freestyle",
    '200 Libre': "200 Freestyle",
    '400 Libre': "400 Freestyle",
    '800 Libre': "800 Freestyle",
    '1500 Libre': "1500 Freestyle",
    '25 Espalda': "25 Backstroke",
    '50 Espalda': "50 Backstroke",
    '100 Espalda': "100 Backstroke",
    '200 Espalda': "200 Backstroke",
    '25 Pecho': "25 Breaststroke",
    '50 Pecho': "50 Breaststroke",
    '100 Pecho': "100 Breaststroke",
    '200 Pecho': "200 Breaststroke",
    '25 Mariposa': "25 Butterfly",
    '50 Mariposa': "50 Butterfly",
    '100 Mariposa': "100 Butterfly",
    '200 Mariposa': "200 Butterfly",
    '100 Combinado Ind.': "100 Individual Medley",
    '200 Combinado Ind.': "200 Individual Medley",
    '400 Combinado Ind.': "400 Individual Medley",
    '4x25 Relevo Libre':'4x25 Freestyle Relay',
    '4x50 Relevo Libre':'4x50 Freestyle Relay',
    '4x100 Relevo Libre':'4x100 Freestyle Relay',
    '4x200 Relevo Libre':'4x200 Freestyle Relay',
    '4x25 Relevo Combinado':'4x25 Medley Relay',
    '4x50 Relevo Combinado':'4x50 Medley Relay',
    '4x100 Relevo Combinado':'4x100 Medley Relay',
}