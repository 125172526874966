import React, { useState, useEffect, useRef } from "react";
import { buscarNombresListado, registrarUsuario, sendEmail } from '../controllers/configuracionFirebase';
import { toast } from "react-hot-toast";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { datosAdmin, departamentosColombia } from "../controllers/administrador";
import Swal from "sweetalert2";

const Registrar = ({ setLogin, loginv }) => {

  const [ nombres, setNombres ] = useState([]);
  const [ selectedClub, setSelectedClub ] = useState(null);
  const [ contrasena, setContrasena ] = useState('');
  const [ validarContrasena, setValidarContrasena ] = useState('');
  const [ dialogPedir, setDialogPedir ] = useState(false);

  const form = useRef();

  /* la funcion clickRegistrarse nos ayudara a pasar al otro formulario que es Login. */
  function clickRegistrarse() {
    setLogin(true);
  }

  function mostrarMensajeIntegracionNombreClub(){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0"><b>¿No encuentras el nombre</br>de tu equipo en la lista?</b><br/>
      Solicítalo y lo añadiremos<br/>rápidamente.
      </div>`,
      position: 'top-start',
      confirmButtonText: 'Solicitar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        setDialogPedir(true);
      }
    })
  }

  function pedirIntegracionNombreClub(e){
    e.preventDefault();

    const plataforma = e.target.plataforma.value;
    const nombreclub = e.target.nombre.value;
    const departamento = e.target.departamento.value;
    const contacto = e.target.contacto.value;
    const emails = [
      'prowebsports@gmail.com',
      'ebarbosa2499@gmail.com',
      'esnorcas@hotmail.com',
      'yuribarla14@gmail.com'
    ];
    const asunto = `Solicitud de ${nombreclub}`
    const text = `
    <h1 style="line-height: 1.2">¡Hola! Ingresar a la lista de autorizaciones en la plataforma de ${plataforma} al siguiente equipo.</h1>
    <p style="margin: 0"><b>Plataforma: </b><a href="${datosAdmin.plataforma}" target="_blank">${plataforma}</a></p>
    <p style="margin: 0"><b>Nombre club:</b> ${nombreclub}</p>
    <p style="margin: 0"><b>Departamento:</b> ${departamento}</p>
    <p style="margin: 0"><b>Contacto:</b> ${contacto}</p>
    `;

    const toastId = toast.loading('Enviando solicitud...');
    sendEmail(asunto, text, emails).then(() =>{
      toast.dismiss(toastId);
      toast.success('Solicitud enviada correctamente.');
    }).catch((err)=>{
      toast.dismiss(toastId);
      toast.success('¡UPS! Ocurrio un error. Intentalo de nuevo.');
    });
  }

  /* la funcion onSubmit traera todos los datos que se han ingresado al registrarse,
  y asi mismo validara si las credenciales son correctas o no. 
  hay que tener en cuenta que esto solo dejara registrar a los usuarios que tengan correos como:*/
  function onSubmit(e) {
    e.preventDefault();

    const nombreclub = selectedClub;
    const correo = e.target.email.value;
    const password = contrasena
    const passwordValidar = validarContrasena
    if(nombreclub){
      if(password === passwordValidar){
        registrarUsuario(correo, password, nombreclub);
        setSelectedClub(null);
        setContrasena('');
        setValidarContrasena('');
        e.target.reset();
      }else{
        toast.error('Las contraseñas no coinciden, por favor verifica.');
      }
    }else{
      toast.error('Para registrarse, es necesario elegir el nombre del club.');
    }
  }

  useEffect(() => {
    buscarNombresListado(setNombres);
  }, [setNombres])

  return (
    <div style={{ width: "300px" }}>
      <form
        className={
          loginv
            ? "column g-3 was-validated form-registrar-inativo form-prime"
            : "column g-3 was-validated form-prime"
        }
        onSubmit={onSubmit}
      >
        <div className="col-auto mb-4">
          <img
            className="logo-pantalla-media m-auto"
            style={{ width: "50vw" }}
            src={require("../img/img_logo.png")}
            alt="logo prowebsports"
          />
        </div>
        <div className="alert submit bg-gradient show mb-1" role="alert">
          <h1>
            ¡BIENVENIDO!
          </h1>
          <p className="mb-0">
            Regístrate Aquí
          </p>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className="pi pi-users"></i>
          </span>
          <Dropdown value={selectedClub} onChange={(e) => setSelectedClub(e.value)} filter options={nombres} placeholder={nombres.length + ' Clubs Disponibles'} className={`m-0 ${selectedClub ? '' : 'p-invalid'}`} onClick={() => mostrarMensajeIntegracionNombreClub()}/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className="pi pi-at"></i>
          </span>
          <InputText type="email" name="email" placeholder="E-mail@example.com" className="p-2" required/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className={contrasena !== '' && contrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
          </span>
          <Password value={contrasena} onChange={(e) => setContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
          className={`${contrasena !== '' && contrasena.length >= 6 ? '' : 'p-invalid'}`}
          promptLabel="Min. 6 Caracteres"
          weakLabel="Débil"
          mediumLabel="Media"
          strongLabel="Fuerte"/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className={contrasena === validarContrasena && validarContrasena !== '' && validarContrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
          </span>
          <Password value={validarContrasena} onChange={(e) => setValidarContrasena(e.target.value)} placeholder="Validar Contraseña" toggleMask minLength={6} required
          className={`${contrasena === validarContrasena && validarContrasena !== '' && validarContrasena.length >= 6 ? '' : 'p-invalid'}`}
          feedback={false}/>
        </div>
        <div className="col-auto">
          <Button type="submit" icon='pi pi-user' label="CREAR UNA CUENTA" className="btn submit w-100 mb-2"></Button>
        </div>
        <div className="col-auto">
          <p>
              ¿Ya tienes una cuenta?{" "}
            <button type="button" className="link" onClick={clickRegistrarse}>
              Iniciar sesión
            </button>
          </p>
        </div>
      </form>
      <Dialog visible={dialogPedir} maximizable style={{ width: '500px' }} breakpoints={{ '641px': '100%' }} header={`Solicitud para añadir el nombre del equipo`} modal className="p-fluid" onHide={() => setDialogPedir(false)}>
        <div className="field">
          <form onSubmit={pedirIntegracionNombreClub} ref={form}>
            <div className="form-floating">
              <input type="text" className='form-control mt-1' value={datosAdmin.nombre} name='plataforma' id='plataforma' required/>
              <label htmlFor='plataforma'>Plataforma</label>
            </div>
            <div className="form-floating">
              <input type="text" className='form-control mt-1' name='nombre' id='nombre' required/>
              <label htmlFor='nombre'>Nombre Equipo</label>
            </div>
            <div className="form-floating">
              <select name='departamento' id="departamento" className='form-select mt-1' required>
                {departamentosColombia.map((depar, indexDepar)=>{
                  return <option key={indexDepar} value={depar.dep}>{depar.dep}</option>
                })}
              </select>
              <label htmlFor="departamento">Departamento</label>
            </div>
            <div className="form-floating">
              <input type="number" className='form-control mt-1 mb-1' name='contacto' id='contacto' required/>
              <label htmlFor='contacto'>Numero de WhatsApp</label>
            </div>
            <Button type="submit" icon='pi pi-info-circle' label="Enviar solicitud" className="btn btn-primary w-100 mb-1"></Button>
          </form>
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <b>IMPORTANTE:</b><br />Esta solicitud debe ser realizada exclusivamente por el entrenador, quien será contactado posteriormente para proporcionar más información.
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Registrar;