import React, { useState, useEffect } from 'react';
import { registrarClasificados, traerFinales } from '../controllers/configuracionFirebase';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';
import { datosAdmin, pruebasRecords } from '../controllers/administrador';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';

const Finales = ({ resultados, torneoEspecifico, idTorneoParticipando, infoUsuario, listPruebas, listadoCategorias }) => {

  const [ finales, setFinales ] = useState();
  const [ pruebaElegida, setPruebaElegida ] = useState('');
  const [ categoriasDisponibles, setCategoriasDisponibles ] = useState([]);
  const [ categoriasElegidas, setCategoriasElegidas ] = useState([]);
  const [ cantPuestosElegidas, setCantPuestosElegidas ] = useState(16);
  const [ cantCarrilElegidas, setCantCarrilElegidas ] = useState(8);
  const [ resultadosFinales, setResultadosFinales ] = useState({});
  const [ numSeries, setNumSeries ] = useState({});

  const abecedario = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ];

  function sacarCategoriasElegidas(e){
    let auxCategoriasElegidas = [...categoriasElegidas];

    if(e.checked){
      auxCategoriasElegidas.push(e.value);
    }else{
      auxCategoriasElegidas.splice(auxCategoriasElegidas.indexOf(e.value), 1);
    }
    setCategoriasElegidas(auxCategoriasElegidas);
  }

  async function procesarPruebas(idTorneoParticipando, torneoEspecifico, finales, resultadosFinales, listadoCategorias, listPruebas, jor) {
    for (const prue of jor.pruebas) {
      // Realiza la acción
      registrarClasificados(
        idTorneoParticipando,
        torneoEspecifico.idFinales,
        { prueba: prue.prueba, nadadores: resultadosFinales[prue.prueba] },
        finales.jornadas,
        listadoCategorias,
        listPruebas
      );
  
      toast.success(`Prueba ${prue.prueba} clasificada correctamente`);
      // Espera 3 segundos
      await new Promise(resolve => setTimeout(resolve, 3000));
    }
  }

  function clasificarIndividual(){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0"><b>¿Esta seguro de clasificar<br/>solamente la prueba<br/>${pruebaElegida}?</b><br/>
      Una vez registrada no se<br/>podra deshacer los<br/> cambios.
      </div>`,
      confirmButtonText: 'Clasificar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        registrarClasificados(idTorneoParticipando, torneoEspecifico.idFinales, {prueba: pruebaElegida, nadadores: resultadosFinales[pruebaElegida]}, finales.jornadas, listadoCategorias, listPruebas);
        toast.success(`Prueba ${pruebaElegida} clasificada correctamente`);
      }
    })
  }

  function clasificarGeneral(){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0"><b>¿Esta seguro de clasificar<br/>todas las pruebas?</b><br/>
      Se clasificarán con la<br/>misma cantidad de<br/>puestos, carriles y las categorias elegidas.
      Una vez registradas no se podra deshacer los cambios.
      </div>`,
      confirmButtonText: 'Clasificar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        finales.jornadas.forEach((jor)=>{
          procesarPruebas(idTorneoParticipando, torneoEspecifico, finales, resultadosFinales, listadoCategorias, listPruebas, jor);
        })
      }
    })
  }
  
  useEffect(() =>{
    if(torneoEspecifico?.idFinales){
      traerFinales(torneoEspecifico.idFinales, setFinales);
    }
  },[])
  
  useEffect(() =>{
    function sacarCategoriasRepetidas(arr) {
      const auxCategorias = new Set();
      arr.forEach((cate)=>{
        auxCategorias.add(cate.categoria);
      })
      return Array.from(auxCategorias);
    }

    setCategoriasDisponibles(sacarCategoriasRepetidas(resultados));
  },[resultados])

  useEffect(() =>{
    if(finales){
      const auxPruebas = {};
      const auxNumSeries = {};
      finales.jornadas.forEach((jor)=>{
        jor.pruebas.forEach((prue)=>{
          if(!auxPruebas[prue.prueba]){
            auxPruebas[prue.prueba] = [];
          }
          if(!auxNumSeries[prue.prueba]){
            auxNumSeries[prue.prueba] = [];
          }
        })
      })

      Object.keys(auxPruebas).forEach((auxPrue)=>{
        let auxNadadores = [];
        const auxNum = [];
        resultados.forEach((result)=>{
          if(result.prueba.prueba === auxPrue && categoriasElegidas.includes(result.categoria)){
            auxNadadores.push(...result.nadadores.filter((nad)=>{return nad.tiempo !== "0:0:0" && nad.tiempo !== "61:0:0"}));
          }
        })

        auxNadadores = auxNadadores.slice(0, cantPuestosElegidas);
        auxNadadores.forEach((_, indexAuxNad)=>{
          if(Math.ceil(auxNadadores.length/cantCarrilElegidas) >= indexAuxNad + 1){
            auxNum.push((indexAuxNad) * cantCarrilElegidas);
          }
        })

        auxNadadores.sort((a, b) => {
          const tiempoA = a.tiempo; 
          const tiempoB = b.tiempo;
  
          const splitTiempoA = tiempoA.split(':').map(Number);
          const splitTiempoB = tiempoB.split(':').map(Number);
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];

          return tiemposA - tiemposB;
        });

        auxPruebas[auxPrue] = auxNadadores;
        auxNumSeries[auxPrue] = auxNum;
      })

      setResultadosFinales(auxPruebas);
      setNumSeries(auxNumSeries);
    }
  },[finales, resultados, categoriasElegidas, cantCarrilElegidas, cantPuestosElegidas])
  
  return (
    <>
      <div className='d-flex flex-wrap justify-content-between m-auto' style={{maxWidth: '850px'}}>
        <div className="form-floating pt-1">
          <select name="prueba" id="prueba" className='form-select' onChange={(ev) => setPruebaElegida(ev.target.value)}>
            <option value="">Elige la prueba</option>
            {pruebasRecords().map((prue, indexPrue)=>{
              return <option key={indexPrue} value={prue}>{prue}</option>
            })}
          </select>
          <label htmlFor="prueba">Prueba</label>
        </div>
        <div className="form-floating pt-1" style={{width: '150px'}}>
          <input type="number" id="puestos" className='form-control' defaultValue={cantPuestosElegidas} onChange={(ev) => setCantPuestosElegidas(ev.target.value)} />
          <label htmlFor="puestos">Cant. Puestos</label>
        </div>
        <div className="form-floating pt-1" style={{width: '150px'}}>
          <input type="number" id="carril" className='form-control' defaultValue={cantCarrilElegidas} onChange={(ev) => setCantCarrilElegidas(ev.target.value)} />
          <label htmlFor="carril">Cant. Carriles</label>
        </div>
      </div>
      <Accordion className='m-auto pt-1' style={{maxWidth: '850px'}}>
        <AccordionTab header="Categorias">
          <div className="d-flex flex-wrap justify-content-evenly">
            {categoriasDisponibles.map((cate, indexCate)=>{
              return <div key={indexCate} className="d-flex align-items-center" style={{width: '200px'}}>
              <Checkbox inputId={cate} name="pizza" value={cate} onChange={sacarCategoriasElegidas} checked={categoriasElegidas.includes(cate)}/>
              <label htmlFor={cate} className="ml-2">{cate}</label>
            </div>
            })}
          </div>
        </AccordionTab>
      </Accordion>
      {(infoUsuario.nombreclub === datosAdmin.nombre || infoUsuario.nombreclub === 'PROWEBSPORTS') 
      && pruebaElegida !== '' && cantCarrilElegidas !== 0 && cantPuestosElegidas !== 0 && categoriasElegidas.length !== 0?(
        <div className='d-flex flex-wrap justify-content-around m-auto pb-2' style={{maxWidth: '850px'}}>
          <Button icon='pi pi-tag' label='Clasificación Individual' className='btn btn-primary' onClick={() => clasificarIndividual()}></Button>
          <Button icon='pi pi-tags' label='Clasificación General' className='btn btn-success' onClick={() => clasificarGeneral()}></Button>
        </div>
      ):(
        <></>
      )}
      <div className='table-responsive'>
        {resultadosFinales[pruebaElegida] ?(
          <table className='table table-bordered table-striped table-hover'>
            <thead className='table-dark'>
              <tr>
                <th colSpan={6}>CLASIFICACIÓN A-Z</th>
              </tr>
              <tr>
                <th>Lgar</th>
                <th>Deportista</th>
                <th>Equipo</th>
                <th>Edad</th>
                <th>Identificación</th>
                <th>Tiempo</th>
              </tr>
            </thead>
            {resultadosFinales[pruebaElegida].map((result, indexResult)=>{
              return <tbody key={indexResult}>
                {numSeries[pruebaElegida].includes(indexResult) ? (
                  <tr className='table-dark'>
                    <th colSpan={6}>{`FINAL ${abecedario[numSeries[pruebaElegida].indexOf(indexResult)]}`}</th>
                  </tr>
                ):(
                  <></>
                )}
                <tr>
                  <td>{indexResult + 1}</td>
                  <td>{result.nombre}</td>
                  <td>{result.nombreclub}</td>
                  <td>{result.edad}</td>
                  <td>{infoUsuario.nombreclub === result.nombreclub 
                  || infoUsuario.nombreclub === datosAdmin.nombre
                  || infoUsuario.nombreclub === 'PROWEBSPORTS' ? result.identificacion : 'Oculto'}</td>
                  <td>{result.tiempo}</td>
                </tr>
              </tbody>
            })}
          </table>
        ):(
          <></>
        )}
      </div>
    </>
  )
}

export default Finales;