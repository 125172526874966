// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  onAuthStateChanged, 
  signOut, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  updateProfile, 
  sendEmailVerification, 
  sendPasswordResetEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc, getDocs, collection, updateDoc, deleteField, onSnapshot, deleteDoc, query, where} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase, ref as refDatabase, onValue, set } from "firebase/database";
import { toast } from "react-hot-toast";
import Swal from 'sweetalert2';
import { deleteDB, openDB } from 'idb';
import { datosAdmin } from "./administrador";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const firebaseConfigLogistica = {
  apiKey: process.env.REACT_APP_LOGISTICA_API_KEY,
  authDomain: process.env.REACT_APP_LOGISTICA_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_LOGISTICA_PROJECT_ID,
  storageBucket: process.env.REACT_APP_LOGISTICA_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_LOGISTICA_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_LOGISTICA_APP_ID,
  measurementId: process.env.REACT_APP_LOGISTICA_MEASUREMENT_ID
};

const firebaseConfigRankingPws = {
  apiKey: process.env.REACT_APP_RANKINGPWS_API_KEY,
  authDomain: process.env.REACT_APP_RANKINGPWS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_RANKINGPWS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_RANKINGPWS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_RANKINGPWS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_RANKINGPWS_APP_ID,
  measurementId: process.env.REACT_APP_RANKINGPWS_MEASUREMENT_ID
};

const firebaseConfigFamilia = {
  apiKey: process.env.REACT_APP_RESULTFAMILIAS_API_KEY,
  authDomain: process.env.REACT_APP_RESULTFAMILIAS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_RESULTFAMILIAS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_RESULTFAMILIAS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_RESULTFAMILIAS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_RESULTFAMILIAS_APP_ID,
  measurementId: process.env.REACT_APP_RESULTFAMILIAS_MEASUREMENT_ID
};

// Initialize Firebase
const db = initializeApp(firebaseConfig);
const firestore = getFirestore(db);
const storage = getStorage();
const database = getDatabase();

//Initialize Firebase Logistica
const dbLogistica = initializeApp(firebaseConfigLogistica, 'db2');
const firestoreLogistica = getFirestore(dbLogistica);

//Initialize Firebase Ranking Prowebsports
const dbRankingPws = initializeApp(firebaseConfigRankingPws, 'db3');
const firestoreRankingPws = getFirestore(dbRankingPws);
const auth = getAuth(dbRankingPws);
const storagePws = getStorage(dbRankingPws);
const messaging = getMessaging(dbRankingPws);

//Initialize Firebase resultados para familiares y demas Prowebsports
const dbFamilia = initializeApp(firebaseConfigFamilia, 'db4');
const firestoreFamilia = getFirestore(dbFamilia);

//Con esta funcion vamos a saber los cambios en la sesión.
export function sesionIniciada(setUsuario){
  onAuthStateChanged(auth, (usuarioFirebase) => {
    let datosUsuario = {};
    if(usuarioFirebase){
      datosUsuario = {
        nombreclub: auth.currentUser.displayName,
        id: usuarioFirebase ? usuarioFirebase.uid : "",
        photo: auth.currentUser.photoURL,
        email: auth.currentUser.email,
        conexion: false
      }

      let autorizado = false;
      traerClubesAutorizados().then((auto)=>{
        traerUsuariosPws(datosUsuario).then((info)=>{
          const buscarExistenciaAutorizado = auto.filter((autoriza) => {return autoriza.nombre === datosUsuario.nombreclub});
          if(info){
            if(buscarExistenciaAutorizado.length === 0){
              registrarNombreAutorizado([...auto, {
                abreviatura: info.abreviatura,
                autorizado: true,
                departamento: info.departamento,
                nombre: info.nombreclub
              }]);

              auto.push({
                abreviatura: info.abreviatura,
                autorizado: true,
                departamento: info.departamento,
                nombre: info.nombreclub
              });
            }

          }else{
            if(buscarExistenciaAutorizado.length !== 0){
              autorizado = true;
              datosUsuario.abreviatura = buscarExistenciaAutorizado[0].abreviatura
              datosUsuario.departamento = buscarExistenciaAutorizado[0].departamento
              registrarUsuarioPws(datosUsuario);
            }
          }
          
          auto.forEach((autoriza)=>{
            if(autoriza.nombre === datosUsuario.nombreclub && autoriza.autorizado){
              autorizado = true;
              datosUsuario.abreviatura = autoriza.abreviatura
              datosUsuario.departamento = autoriza.departamento
            }
          })

          if(autorizado){
            eliminarClubDelListado(datosUsuario.nombreclub);
            setUsuario(datosUsuario);
            if(datosUsuario.nombreclub === datosAdmin.nombre){
              validarActivacionPlataforma(datosAdmin.conexion).then((activada)=>{
                if(!activada){
                  cerrarSesion();
                  const alerta = Swal.mixin({
                    toast: true,
                    timer: '15000',
                    timerProgressBar: true,
                    showConfirmButton: false
                  })
                  alerta.fire({
                    html: `<div class="w-100 position-relative">
                    <img style="width: 100px; position: absolute; top: 13px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
                    <p style="z-index: 10; font-family: Poppins" class="mb-0">Esta cuenta ha sido inhabilitada. </br> Por favor,
                    comuniquese </br> con el equipo de </br> PROWEBSPORTS.
                    </div>`,
                    position: 'top-end',
                    padding: 2
                  })
                }
              })
            }
          }else{
            cerrarSesion();
            const alerta = Swal.mixin({
              toast: true,
            })
            alerta.fire({
              html: `<div class="w-100 position-relative">
              <img style="width: 100px; position: absolute; top: 33px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
              <p style="z-index: 10; font-family: Poppins" class="mb-0">No tienes autorización para <br/> ingresar a la plataforma. <br/>
              Comuniquese con el <br/> Club de Natación ${datosAdmin.nombre}.</p>
              </div>`,
              confirmButtonText: 'Entendido',
              confirmButtonColor: 'rgb(18, 53, 248)',
              position: 'top-end',
              padding: 2
            })
          }
        })
      })
    }else{
      setUsuario();
    }
  });
}

// Función para registrar un usuario
export async function registrarUsuario(email, password, nombre) {
  try {
    await createUserWithEmailAndPassword(auth, email, password).then(
      (usuarioFirebase) => {
        return usuarioFirebase;
      }
    );

    updateProfile(auth.currentUser, {
      displayName: nombre
    }).catch(() => {
      toast.error("Ocurrió un error al registrar su nombre.");
    });

    if (auth.currentUser && !auth.currentUser.emailVerified) {
      validarCorreoElectronico(email);
      cerrarSesion();
    }
  } catch (err) {
    toast.error("El correo electrónico  " + email + ", ya está registrado.");
  }
}

function mostrarDominioCorreo(email){
  const domain = email.split('@')[1];
  let inboxLink;

  // Determinar el enlace de la bandeja de entrada según el dominio
  switch (domain.toLowerCase()) {
    case 'gmail.com':
      inboxLink = `https://mail.google.com/mail/u/0/#inbox`;
      break;
    case 'hotmail.com':
    case 'live.com':
    case 'outlook.com':
      inboxLink = `https://outlook.live.com/mail/inbox`;
      break;
    case 'yahoo.com':
      inboxLink = `https://mail.yahoo.com/`;
      break;
    case 'icloud.com':
      inboxLink = `https://www.icloud.com/mail/`;
      break;
    case 'aol.com':
      inboxLink = `https://mail.aol.com/`;
      break;
    // Agregar más proveedores de correo según sea necesario
    default:
      inboxLink = `mailto:${email}`; // Si no hay un dominio específico, abre el cliente de correo
  }

  return inboxLink
}

// Función para validar el correo electrónico
async function validarCorreoElectronico(email) {
  try {
    const actionCodeSettings = {
      url: `${datosAdmin.plataforma}`,
      handleCodeInApp: true
    };
    // Enviar un correo electrónico de verificación al correo electrónico proporcionado
    sendEmailVerification(auth.currentUser, actionCodeSettings)
      .then(() => {
        const alerta = Swal.mixin({
          toast: true,
          timer: '10000',
          timerProgressBar: true,
          showConfirmButton: false
        })
        alerta.fire({
          html: `<div class="w-100 position-relative">
          <img style="width: 100px; position: absolute; top: 23px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
          <p style="z-index: 10; font-family: Poppins" class="mb-0">Se ha enviado un correo electrónico de verificación a ${email}, </br> cuando se verifique el </br> correo electrónico, podrá </br> iniciar sesión.</br>
          <a href='${mostrarDominioCorreo(email)}'>Ir a ${email}</a></div>`,
          position: 'top-start',
          padding: 2
        })
      })
      .catch(() => {
        const alerta = Swal.mixin({
          toast: true,
          timer: '10000',
          timerProgressBar: true,
          showConfirmButton: false
        })
        alerta.fire({
          html: `<div class="w-100 position-relative">
          <img style="width: 100px; position: absolute; top: 23px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
          <p style="z-index: 10; font-family: Poppins" class="mb-0">Se ha enviado un correo electrónico de verificación a ${email}, </br> cuando se verifique el </br> correo electrónico, podrá </br> iniciar sesión.</br>
          <a href='${mostrarDominioCorreo(email)}'>Ir a ${email}</a></div>`,
          position: 'top-start',
          padding: 2
        })
      });
  } catch (error) {
    toast.error(
      "Se ha producido un error con el envío del email de verificación."
    );
  }
}

// Función para restablecer el password mediante un correo que se le envia al usuario.
export function restablecerPassword(email) {
  const actionCodeSettings = {
    url: `${datosAdmin.plataforma}`,
    handleCodeInApp: true
  };
  sendPasswordResetEmail(auth, email, actionCodeSettings)
    .then(() => {
      const alerta = Swal.mixin({
        toast: true,
        timer: '10000',
        timerProgressBar: true,
        showConfirmButton: false
      })
      alerta.fire({
        html: `<div class="w-100 position-relative">
        <img style="width: 100px; position: absolute; top: 23px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
        <p style="z-index: 10; font-family: Poppins" class="mb-0">Se ha enviado un correo </br> electrónico a ${email} </br> para restablecer su </br> contraseña.<br/>
        <a href='${mostrarDominioCorreo(email)}'>Ir a ${email}</a>
        </div>`,
        position: 'top-end',
        padding: 2
      })
    })
    .catch(() => {
      const alerta = Swal.mixin({
        toast: true,
        timer: '10000',
        timerProgressBar: true,
        showConfirmButton: false
      })
      alerta.fire({
        html: `<div class="w-100 position-relative">
        <img style="width: 100px; position: absolute; top: 0; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
        <p style="z-index: 10; font-family: Poppins" class="mb-0">Aparentemente el </br> correo electrónico </br> ${email}, </br> no está registrado.
        </div>`,
        position: 'top-end',
        padding: 2
      })
    });
}

//Funcion para ingresar a la plataforma.
export async function login(email, password){
  signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      if (auth.currentUser && !auth.currentUser.emailVerified) {
        cerrarSesion();
        const alerta = Swal.mixin({
          toast: true,
          timer: '8000',
          timerProgressBar: true,
          showConfirmButton: false
        })
        alerta.fire({
          html: `<div class="w-100 position-relative">
          <img style="width: 100px; position: absolute; top: 23px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
          <p style="z-index: 10; font-family: Poppins" class="mb-0">Aparentemente no ha verificado </br> su correo electrónico, </br> verifique el correo </br> electrónico ${email}.
          </div>`,
          position: 'top-end',
          padding: 2
        })
      }
    })
    .catch((err) => {
      if(err.code === 'auth/wrong-password'){
        toast.error("Contraseña incorrecta. Por favor, intentalo de nuevo.");
      }
      if(err.code === 'auth/user-not-found'){
        toast.error("¡UPS! el correo " + email + ", no esta registrado aún.");
      }
      if(err.code === 'auth/user-disabled'){
        toast.error("Lo sentimos, pero esta cuenta ha sido inhabilitada, comunicate con el equipo de PROWEBSPORTS.");
      }
      if(err.code === 'auth/network-request-failed'){
        toast.error('Sin conexion a internet');
      }
      if(err.code === 'auth/invalid-login-credentials'){
        toast.error('Correo y/o Contraseña incorrecta.');
      }
    });
}

//Funcion para cerrar sesión.
export function cerrarSesion(){
  signOut(auth);
}

let unsubscribeConsolidado = null;

/*operaciones para el consolidado
*/
export function consolidado(idTorneo, setClubes){
  const clubesRef = collection(firestore, `TorneosTemporales/${idTorneo}/clubes`);
  unsubscribeConsolidado = onSnapshot(clubesRef, (snapshot) => {
    const consolidado = [];

    snapshot.forEach((doc) => {
      if (doc.data().nadadores) {
        consolidado.push(doc.data());
      }
    });
    setClubes(consolidado);
  });
}

// Función para cancelar la suscripción
export function cancelarSuscripcionConsolidado() {
  if (unsubscribeConsolidado) {
    unsubscribeConsolidado();
    unsubscribeConsolidado = null;
  }
}

//funcion para sacar conteo de medallas oro, plata y bronce
export function sacarMedalleriaTrofeoClub(resultados, categoriasElegidas){
  const trofeoClub = {};

  resultados.forEach((data) => {
    if (data.nadadores) {
      const categoria = data.categoria;
      // Verificar si la categoría está en el rango requerido
      const condicionCate = categoriasElegidas.length !== 0 ? categoriasElegidas.includes(categoria) : categoria.includes('');
      if (condicionCate){
        const nadadores = data.nadadores.filter(
          (nadador) => nadador.tiempo !== "0:0:0" && nadador.tiempo !== "61:0:0"
        );

        if(nadadores.length !== 1){
          nadadores.forEach((nadador, index) => {
            if (!trofeoClub[nadador.nombreclub]) {
              trofeoClub[nadador.nombreclub] = {
                oro: 0,
                plata: 0,
                bronce: 0,
                total: 0,
              };
            }
            if (index + 1 === 1 && nadador.tiempo !== '0:0:0') {
              trofeoClub[nadador.nombreclub].oro++;
            } else if (index + 1 === 2 && nadador.tiempo !== '0:0:0') {
              trofeoClub[nadador.nombreclub].plata++;
            } else if (index + 1 === 3 && nadador.tiempo !== '0:0:0') {
              trofeoClub[nadador.nombreclub].bronce++;
            }
            trofeoClub[nadador.nombreclub].total++;
          });
        }
      }
    }
  });
  // Calcular el total de medallas por club
  Object.values(trofeoClub).forEach((club) => {
    club.total = club.oro + club.plata + club.bronce;
  });
  const auxTrofeoClub = Object.entries(trofeoClub)
    .map(([club, values]) => ({
      club,
      ...values,
    }))
    .sort((a, b) => {
      if (b.oro !== a.oro) {
        return b.oro - a.oro; // Ordenar por medallas de oro de manera descendente
      } else if (b.plata !== a.plata) {
        return b.plata - a.plata; // Ordenar por medallas de plata de manera descendente
      } else {
        return b.bronce - a.bronce; // Ordenar por medallas de bronce de manera descendente
      }
    });
  return auxTrofeoClub;
}

export function sacarMedalleriaDeportistas(resultados, categoriasElegidas){
  const trofeoClub = {};

  resultados.forEach((data) => {
    if (data.nadadores) {
      const categoria = data.categoria;
      // Verificar si la categoría está en el rango requerido
      const condicionCate = categoriasElegidas.length !== 0 ? categoriasElegidas.includes(categoria) : categoria.includes('');
      if (condicionCate) {
        const nadadores = data.nadadores.filter(
          (nadador) => nadador.tiempo !== "0:0:0" && nadador.tiempo !== "61:0:0"
        );
        nadadores.forEach((nadador, index) => {
          if (!trofeoClub[nadador.nombre]) {
            trofeoClub[nadador.nombre] = {
              club: nadador.nombreclub,
              categoria,
              oro: 0,
              plata: 0,
              bronce: 0,
              total: 0,
            };
          }
          if (index + 1 === 1 && nadador.tiempo !== '0:0:0') {
            trofeoClub[nadador.nombre].oro++;
          } else if (index + 1 === 2 && nadador.tiempo !== '0:0:0') {
            trofeoClub[nadador.nombre].plata++;
          } else if (index + 1 === 3 && nadador.tiempo !== '0:0:0') {
            trofeoClub[nadador.nombre].bronce++;
          }
          trofeoClub[nadador.nombre].total++;
        });
      }
    }
  });
  // Calcular el total de medallas por club
  Object.values(trofeoClub).forEach((club) => {
    club.total = club.oro + club.plata + club.bronce;
  });
  const auxTrofeoClub = Object.entries(trofeoClub)
    .map(([deportista, values]) => ({
      deportista,
      ...values,
    }))
    .sort((a, b) => {
      if (b.oro !== a.oro) {
        return b.oro - a.oro; // Ordenar por medallas de oro de manera descendente
      } else if (b.plata !== a.plata) {
        return b.plata - a.plata; // Ordenar por medallas de plata de manera descendente
      } else {
        return b.bronce - a.bronce; // Ordenar por medallas de bronce de manera descendente
      }
    });
  return auxTrofeoClub;
}

function puntos8Carriles(trofeoClub, nadadores){
  nadadores.forEach((nadador, index) => {
    if (!trofeoClub[nadador.nombreclub]) {
      trofeoClub[nadador.nombreclub] = {
        punto1: 0,
        punto2: 0,
        punto3: 0,
        punto4: 0,
        punto5: 0,
        punto6: 0,
        punto7: 0,
        punto8: 0,
        punto9: 0,
        punto10: 0,
        punto11: 0,
        punto12: 0,
        punto13: 0,
        punto14: 0,
        punto15: 0,
        punto16: 0,
        total: 0,
      };
    }
    if (index + 1 === 1 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto1+=18;
    } else if (index + 1 === 2 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto2+=16;
    } else if (index + 1 === 3 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto3+=15;
    } else if (index + 1 === 4 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto4+=14;
    } else if (index + 1 === 5 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto5+=13;
    } else if (index + 1 === 6 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto6+=12;
    } else if (index + 1 === 7 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto7+=11;
    } else if (index + 1 === 8 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto8+=10;
    } else if (index + 1 === 9 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto9+=8;
    } else if (index + 1 === 10 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto10+=7;
    } else if (index + 1 === 11 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto11+=6;
    } else if (index + 1 === 12 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto12+=5;
    } else if (index + 1 === 13 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto13+=4;
    } else if (index + 1 === 14 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto14+=3;
    } else if (index + 1 === 15 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto15+=2;
    } else if (index + 1 === 16 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto16+=1;
    } 
    trofeoClub[nadador.nombreclub].total++;       
  });
}

function puntosRelevo8Carriles(trofeoClub, nadadores){
  nadadores.forEach((nadador, index) => {
    if (!trofeoClub[nadador.nombreclub]) {
      trofeoClub[nadador.nombreclub] = {
        punto1: 0,
        punto2: 0,
        punto3: 0,
        punto4: 0,
        punto5: 0,
        punto6: 0,
        punto7: 0,
        punto8: 0,
        punto9: 0,
        punto10: 0,
        punto11: 0,
        punto12: 0,
        punto13: 0,
        punto14: 0,
        punto15: 0,
        punto16: 0,
        total: 0,
      };
    }
    if (index + 1 === 1 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto1+=36;
    } else if (index + 1 === 2 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto2+=32;
    } else if (index + 1 === 3 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto3+=30;
    } else if (index + 1 === 4 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto4+=28;
    } else if (index + 1 === 5 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto5+=26;
    } else if (index + 1 === 6 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto6+=24;
    } else if (index + 1 === 7 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto7+=22;
    } else if (index + 1 === 8 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto8+=20;
    }
    trofeoClub[nadador.nombreclub].total++;       
  });
}

function puntos10Carriles(trofeoClub, nadadores){
  nadadores.forEach((nadador, index) => {
    if (!trofeoClub[nadador.nombreclub]) {
      trofeoClub[nadador.nombreclub] = {
        punto1: 0,
        punto2: 0,
        punto3: 0,
        punto4: 0,
        punto5: 0,
        punto6: 0,
        punto7: 0,
        punto8: 0,
        punto9: 0,
        punto10: 0,
        punto11: 0,
        punto12: 0,
        punto13: 0,
        punto14: 0,
        punto15: 0,
        punto16: 0,
        punto17: 0,
        punto18: 0,
        punto19: 0,
        punto20: 0,
        total: 0,
      };
    }
    if (index + 1 === 1 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto1+=22;
    } else if (index + 1 === 2 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto2+=20;
    } else if (index + 1 === 3 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto3+=19;
    } else if (index + 1 === 4 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto4+=18;
    } else if (index + 1 === 5 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto5+=17;
    } else if (index + 1 === 6 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto6+=16;
    } else if (index + 1 === 7 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto7+=15;
    } else if (index + 1 === 8 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto8+=14;
    } else if (index + 1 === 9 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto9+=13;
    } else if (index + 1 === 10 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto10+=12;
    } else if (index + 1 === 11 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto11+=10;
    } else if (index + 1 === 12 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto12+=9;
    } else if (index + 1 === 13 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto13+=8;
    } else if (index + 1 === 14 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto14+=7;
    } else if (index + 1 === 15 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto15+=6;
    } else if (index + 1 === 16 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto16+=5;
    } else if (index + 1 === 17 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto17+=4;
    } else if (index + 1 === 18 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto18+=3;
    } else if (index + 1 === 19 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto19+=2;
    } else if (index + 1 === 20 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto20+=1;
    } 
    trofeoClub[nadador.nombreclub].total++;       
  });
}

function puntosRelevo10Carriles(trofeoClub, nadadores){
  nadadores.forEach((nadador, index) => {
    if (!trofeoClub[nadador.nombreclub]) {
      trofeoClub[nadador.nombreclub] = {
        punto1: 0,
        punto2: 0,
        punto3: 0,
        punto4: 0,
        punto5: 0,
        punto6: 0,
        punto7: 0,
        punto8: 0,
        punto9: 0,
        punto10: 0,
        punto11: 0,
        punto12: 0,
        punto13: 0,
        punto14: 0,
        punto15: 0,
        punto16: 0,
        punto17: 0,
        punto18: 0,
        punto19: 0,
        punto20: 0,
        total: 0,
      };
    }
    if (index + 1 === 1 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto1+=40;
    } else if (index + 1 === 2 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto2+=36;
    } else if (index + 1 === 3 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto3+=34;
    } else if (index + 1 === 4 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto4+=32;
    } else if (index + 1 === 5 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto5+=30;
    } else if (index + 1 === 6 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto6+=28;
    } else if (index + 1 === 7 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto7+=26;
    } else if (index + 1 === 8 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto8+=24;
    }else if (index + 1 === 9 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto9+=22;
    }else if (index + 1 === 10 && nadador.tiempo !== '0:0:0') {
      trofeoClub[nadador.nombreclub].punto10+=20;
    }
    trofeoClub[nadador.nombreclub].total++;       
  });
}

export function sacarPuntosClub(resultados, nCarriles, categoriasElegidas){
  const trofeoClub = {};

  resultados.forEach((data) => {
    if (data.nadadores) {
      const categoria = data.categoria;
      const condicionCate = categoriasElegidas.length !== 0 ? categoriasElegidas.includes(categoria) : categoria.includes('');
      if(condicionCate){
        const nadadores = data.nadadores.filter(
          (nadador) => nadador.tiempo !== "0:0:0" && nadador.tiempo !== "61:0:0");
          
        if(nadadores.length !== 1){
          if(parseInt(nCarriles) <= 8){
            if(categoria.includes('Relevo')){
              puntosRelevo8Carriles(trofeoClub, nadadores);
            }else{
              puntos8Carriles(trofeoClub, nadadores);
            }
          }
  
          if(parseInt(nCarriles) === 10){
            if(categoria.includes('Relevo')){
              puntosRelevo10Carriles(trofeoClub, nadadores);
            }else{
              puntos10Carriles(trofeoClub, nadadores);
            }
          }
        }
      }
    }
  });
  // Calcular el total de medallas por club
  Object.values(trofeoClub).forEach((club) => {
    if(parseInt(nCarriles) <= 8){
      club.total = club.punto1 + club.punto2 + club.punto3 +
      club.punto4 + club.punto5 + club.punto6 + club.punto7 +
      club.punto8 + club.punto9 + club.punto10 + club.punto11 +
      club.punto12 + club.punto13 + club.punto14 + club.punto15 +
      club.punto16;
    }
    if(parseInt(nCarriles) === 10){
      club.total = club.punto1 + club.punto2 + club.punto3 +
      club.punto4 + club.punto5 + club.punto6 + club.punto7 +
      club.punto8 + club.punto9 + club.punto10 + club.punto11 +
      club.punto12 + club.punto13 + club.punto14 + club.punto15 +
      club.punto16 + club.punto17 + club.punto18 + club.punto19 +
      club.punto20;
    }
  });
  const auxTrofeoClub = Object.entries(trofeoClub)
    .map(([club, values]) => ({
      club,
      ...values,
    }))
    .sort((a, b) => {return b.total - a.total});
  
  return auxTrofeoClub;
}

//operacion para traer los ganadores del trofeo en tiempo real.
let unsubscribeTrofeo = null;

export async function trofeo(setResultados, idTorneo, setConexion, nombreclub) {
  let db;
  let auxResultados = [];
  if(nombreclub === datosAdmin.nombre || nombreclub === 'PROWEBSPORTS'){
    db = await openDB(idTorneo, 1);
    auxResultados = await recuperarResultadosTiemposLocales(db, 'resultados');
  }
  const tiempoQuery = collection(firestore, `TorneosTemporales/${idTorneo}/tiempos`);

  unsubscribeTrofeo = onSnapshot(tiempoQuery, async (snapshot) => {
    const auxResul = [];
    try {
      const tiempoSnapshot = snapshot.docs;
      if(tiempoSnapshot.length < auxResultados.length){
        setConexion(false);
        auxResultados.forEach((auxRes)=>{
          const data = auxRes;
          auxResul.push(data);
        })
      }else{
        setConexion(true);
        tiempoSnapshot.forEach((auxRes)=>{
          const data = auxRes.data();
          auxResul.push(data);
        })
      }
    } catch (error) {
      setConexion(false);
      auxResultados.forEach((auxRes)=>{
        const data = auxRes;
        auxResul.push(data);
      })
    }
    setResultados(auxResul);
  });
}

export function cancelarTrofeo(){
  if (unsubscribeTrofeo) {
    unsubscribeTrofeo();
    unsubscribeTrofeo = null;
  }
}

//autorizaciones
export async function actualizarClubesAutorizados(actualizarAutorizados){
  if(actualizarAutorizados){
    const docuRef = doc(firestore, `autorizacion/XKF4ZIAy7gkrtm7Batwk`);
    updateDoc(docuRef, {autorizados: actualizarAutorizados}).catch(() =>{
      toast.error('UPS!! Ocurrio un error, intentalo de nuevo o comunicate con el administrador de la plataforma.');
    })
  }
}

export async function eliminarClubesAutorizados(nombresAutorizados, nombre){
  if(nombresAutorizados.length !== 0){
    const docuRef = doc(firestore, `autorizacion/XKF4ZIAy7gkrtm7Batwk`);
    updateDoc(docuRef, {autorizados: nombresAutorizados}).then(() =>{
      toast.success('El equipo ' + nombre.nombre + ', ya no esta autorizado para registrarse/ingresar.',{
        icon: '😢'
      });
    }).catch(() =>{
      toast.error('UPS!! Ocurrio un error, intentalo de nuevo o comunicate con el administrador de la plataforma.');
    })
  }else{
    const deleteDoc = doc(firestore, `autorizacion/XKF4ZIAy7gkrtm7Batwk`);
    await updateDoc(deleteDoc, {
      autorizados: deleteField()
    });
  }
}

export function registrarNombreAutorizado(nombresAutorizados, nombre, sesion){
  const docuRef = doc(firestore, `autorizacion/XKF4ZIAy7gkrtm7Batwk`);
  if(nombre && sesion === 'si'){
    registrarNombresListado(nombre);
  }
  updateDoc(docuRef, {autorizados: nombresAutorizados}).then(() =>{
    if(nombre){
      toast.success('Equipo ' + nombre + ', autorizado.');
    }
  }).catch(() =>{
    toast.error('UPS!! Ocurrio un error, intentalo de nuevo o comunicate con el administrador de la plataforma.');
  })
}

export async function registrarNombresListado(club){
  const docuRef = doc(firestore, `listadoNombres/XKF4ZIAy7gkrtm7Batwk`);
  const docSnap = await getDoc(docuRef);
  if(docSnap.exists()){
    const auxClubes = [];
    docSnap.data().clubes.forEach((clu)=>{
      auxClubes.push(clu);
    })
    updateDoc(docuRef, {clubes: [...auxClubes, club]});
  }else{
    setDoc(docuRef, {clubes: [club]});
  }
}

export async function eliminarClubDelListado(club){
  const docuRef = doc(firestore, `listadoNombres/XKF4ZIAy7gkrtm7Batwk`);
  const docSnap = await getDoc(docuRef);
  if(docSnap.exists()){
    const clubEncontrado = docSnap.data().clubes.filter((clu)=>{return clu === club});
    const auxClubes = docSnap.data().clubes.filter((clu)=>{return clu !== club});
    if(clubEncontrado.length !== 0){
      updateDoc(docuRef, {clubes: [...auxClubes]});
    }
  }
}

let unsubscribeNombresListado = null;

export function buscarNombresListado(setNombres){
  unsubscribeNombresListado = onSnapshot(doc(firestore, "listadoNombres", `XKF4ZIAy7gkrtm7Batwk`), (doc) => {
    if(doc.data()){
      setNombres(doc.data().clubes.sort())
    }else{
      setNombres([])
    }
  });
}

export function cancelarNombresListado(){
  if (unsubscribeNombresListado) {
    unsubscribeNombresListado();
    unsubscribeNombresListado = null;
  }
}

let unsubscribeNombresAutorizados = null;

export function buscarNombresAutorizados(setNombresAutorizados){
  unsubscribeNombresAutorizados = onSnapshot(doc(firestore, "autorizacion", `XKF4ZIAy7gkrtm7Batwk`), (doc) => {
    if(doc.data()){
      setNombresAutorizados(doc.data().autorizados)
    }else{
      setNombresAutorizados([])
    }
  });
}

export function cancelarNombresAutorizados(){
  if (unsubscribeNombresAutorizados) {
    unsubscribeNombresAutorizados();
    unsubscribeNombresAutorizados = null;
  }
}

let unsubscribeAutorizaciones = null;

export function buscarAutorizaciones(setAutorizaciones){
  unsubscribeAutorizaciones = onSnapshot(doc(firestore, "autorizacion", `XKF4ZIAy7gkrtm7Batwk`), (doc) => {
    if(doc.data()){
      setAutorizaciones(doc.data().autorizados.map((user)=>{return user.autorizado}))
    }else{
      setAutorizaciones([])
    }
  });
}

export function cancelarAutorizaciones(){
  if (unsubscribeAutorizaciones) {
    unsubscribeAutorizaciones();
    unsubscribeAutorizaciones = null;
  }
}

// Función para eliminar las fotos existentes en la subcolección de convocatorias
async function eliminarFotosPerfilExistente(id) {
  const fotosRef = ref(storagePws, `${id}/perfil/`);
  const fotosList = await listAll(fotosRef);
  
  // Eliminar cada foto en la subcolección
  fotosList.items.forEach(async (item) => {
    await deleteObject(item);
  });
}

//operaciones para el perfil del usuario
//funcion para subir foto de perfil
export function subirFotoPerfil(file, id, setImgPerfil, usuario) {

  eliminarFotosPerfilExistente(id);

  const nombreFoto = ref(storagePws, `${id}/perfil/${file.name}`);
  const auxPerfil = uploadBytes(nombreFoto, file)
  .then(() => {
    getDownloadURL(nombreFoto).then((url) => {
      updateProfile(auth.currentUser, {
        photoURL: url,
      })
      setImgPerfil(url);
      usuario.photo = url;
    });
  })
  toast.promise(auxPerfil, {
    loading: 'Subiendo Foto...',
    success: () => "Tu foto ha sido cargada con éxito.",
    error: () => "Ocurrió un error al subir la foto."
  })
}

//funcion para elminar foto de perfil
export function eliminarFotoPerfil(setImgPerfil, file, usuario) {
  const auxPerfil = updateProfile(auth.currentUser, {
    photoURL: "",
  })
  .then(() => {
    const nombreFoto = ref(storagePws, `${file}`);
    deleteObject(nombreFoto)
      .then(() => {
        setImgPerfil();
        usuario.photo = null;
      })
  })

  toast.promise(auxPerfil, {
    loading: 'Eliminando Foto...',
    success: () => "Tu foto de perfil ha sido eliminada con éxito.",
    error: () => "Se produjo un error al eliminar la foto, inténtalo de nuevo."
  })
}

export function updateDatos(nuevosDatos) {
  if (nuevosDatos.nombre !== "") {
    updateProfile(auth.currentUser, {
      displayName: nuevosDatos.nombre,
    }).catch(() => {
      toast.error("Hubo un error al actualizar el nombre, intente nuevamente.");
    });
  }

  if (nuevosDatos.passwordNuevo !== "") {
    const credencial = EmailAuthProvider.credential(
      auth.currentUser.email,
      nuevosDatos.passwordActual
    );
    reauthenticateWithCredential(auth.currentUser, credencial)
      .then(() => {
        updatePassword(auth.currentUser, nuevosDatos.passwordNuevo)
          .then(() => {
            toast.success("Los datos se han actualizado correctamente.");
          })
          .catch(() => {
            toast.error(
              "Hubo un error al actualizar la contraseña, intente nuevamente."
            );
          });
      })
      .catch(() => {
        toast.error(
          "¡¡ups!! Parece que la contraseña actual es incorrecta, inténtalo de nuevo."
        );
      });
  }
}

// ------------------ configuracion para generar un id a cada torneo creado ----------------------

/* La funcion generarId() nos ayudara a generar un id unico con 20 caracteres */
export function generarId() {
  const caracteres =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let nuevaContrasena = "";
  for (let i = 0; i < 20; i++) {
    nuevaContrasena += caracteres.charAt(
      Math.floor(Math.random() * caracteres.length)
    );
  }
  return nuevaContrasena;
}

// ------------------ configuracion de torneos temporales ----------------------
let contadorNotificacionTorneos = 0;
export function notificarNuevoEvento(torneo){
  traerTokensNotificaciones().then((tokens)=>{
    toast.success(`Invitacion enviada a ${tokens.length} usuarios`);
    if(contadorNotificacionTorneos === 0){
      tokens.forEach((token)=>{
        const auxFechaTorneo = new Date(torneo.fechaTorneo);
        const auxFechaTorneoF = new Date(torneo.fechaTorneoF);
        const auxFechaInscripciones = new Date(torneo.fechaInscripciones);
        const fechaTorneoFormat = auxFechaTorneo.toLocaleString('es-ES',{ 
          year: 'numeric',
          month: 'long',
          day: 'numeric'})
        const fechaTorneoFFormat = auxFechaTorneoF.toLocaleString('es-ES',{ 
          year: 'numeric',
          month: 'long',
          day: 'numeric'})
        const fechaTorneoInscripcionesFormat = auxFechaInscripciones.toLocaleString('es-ES',{ 
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric', 
          hour12: true})
        const text = `🎉Estas cordialmente invitado al gran evento.🎉\n\n•🗓️ Fecha evento: ${fechaTorneoFormat} - ${fechaTorneoFFormat}\n•🗓️ Fecha cierre inscripciones: ${fechaTorneoInscripcionesFormat}\n•🏊🏼‍♂️ Piscina: ${torneo.piscina} metros\n•🏙️ Ciudad: ${torneo.ciudad}\n•🧭 Dirección: ${torneo.direccion}\n•💵 Club: $${parseInt(torneo.valorClub).toLocaleString()}\n•💵 Deportista: $${parseInt(torneo.valorDeportista).toLocaleString()}\n•📱Contacto: ${datosAdmin.cel}\n•✉️ E-mail: ${datosAdmin.email}`;
        notificacion(token, text, `${torneo.titulo} - ${datosAdmin.nombre}`);
      })
      contadorNotificacionTorneos++;
    }
  });
}

/* La funcion registrarTorneoTemporal nos ayudara a registrar el torneo que se esta editando en un espacio temporal */
export function registrarTorneoTemporal(idTorneo, torneo) {
  const docuRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
  setDoc(docuRef, torneo).then(()=>{
    registrarTorneoFamilia(idTorneo, torneo);
  });
}

/* La funcion registrarTorneoTemporal nos ayudara a registrar el torneo que se esta editando en un espacio temporal */
export function editarTorneoTemporal(idTorneo, torneo) {
  const docuRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
  try {
    updateDoc(docuRef, {
      titulo: torneo.titulo,
      descripcion: torneo.descripcion,
      carril: torneo.carril,
      valorClub: torneo.valorClub,
      valorDeportista: torneo.valorDeportista,
      direccion: torneo.direccion,
      fechaTorneo: torneo.fechaTorneo,
      fechaTorneoF: torneo.fechaTorneoF,
      edCumplida: torneo.edCumplida,
      regTiempo: torneo.regTiempo,
      publicidad: torneo.publicidad,
      idFinales: torneo.idFinales,
      fechaInscripciones: torneo.fechaInscripciones,
      ubicacionLatLng: torneo.ubicacionLatLng,
      ciudad: torneo.ciudad,
      piscina: torneo.piscina,
      cronometros: torneo.cronometros,
      series: torneo.series
    }).then(()=>{
      registrarTorneoFamilia(idTorneo, torneo);
    });
    toast.success('Torneo actualizado correctamente.');
  } catch (error) {
    toast.success('UPS! Ocurrio un error al actualizar el torneo. Intentalo de nuevo.');
  }
}

/* La funcion traerTorneosTemporales nos ayudara a traer todos los torneos que estan en el espacio temporal el cual se editaran. */
export function traerTorneosTemporales(setListaTorneos) {
  onSnapshot(collection(firestore, "TorneosTemporales"), (docc) => {
    const auxTorneo = [];
    if(docc.docs.length !== 0){
      docc.docs.map((user) => {
        return auxTorneo.push(user.data());
      });
    }

    auxTorneo.sort((a, b)=> new Date(a.fechaTorneo) - new Date(b.fechaTorneo));

    auxTorneo.forEach((auxTor)=>{
      const fecha = new Date(auxTor.fechaInscripciones);
      auxTor.fechaInscripciones = fecha.toLocaleString('en-US',{ 
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true})
    })
    
    setListaTorneos([
      ...auxTorneo
    ]);
  });
}

let unsubscribeCantNadadores = null;

export async function cantidadDeportistas(idTorneo) {
  const docuRef = collection(firestore, `TorneosTemporales/${idTorneo}/clubes`);
  const torneos = await getDocs(docuRef);
  
  let suma = 0;
  torneos.forEach((tor)=>{
    if(tor.exists()){
      suma += tor.data().nadadores.length;
    }else{
      suma = 0;
    }
  })
  return suma;
}

export function cancelarCantNadadores(){
  if (unsubscribeCantNadadores) {
    unsubscribeCantNadadores();
    unsubscribeCantNadadores = null;
  }
}

/* La funcion actualizarTorneoTemporal nos ayudara con los cambios del torneo. */
export function actualizarTorneoTemporal(idTorneo, torneo) {
  const docuRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
  updateDoc(docuRef, torneo);
}

/* La funcion eliminarTorneoTemporal nos ayudara con la eliminación de un curso que no se quiera publicar. */
export async function eliminarTorneoTemporal(idTorneo) {
  eliminarBaseDeDatosLocal(idTorneo);
  const docuRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
  const subColeccionClubes = collection(docuRef, 'clubes');
  const subColeccionPlanilla = collection(docuRef, 'planilla');
  const subColeccionTiempos = collection(docuRef, 'tiempos');
  const docSnapClubes = await getDocs(subColeccionClubes);
  const docSnapPlanilla = await getDocs(subColeccionPlanilla);
  const docSnapTiempos = await getDocs(subColeccionTiempos);
  
  docSnapClubes.forEach(async (sub)=>{
    if(sub.exists()){
      await deleteDoc(sub.ref);
    }
  })
  docSnapPlanilla.forEach(async (sub)=>{
    if(sub.exists()){
      await deleteDoc(sub.ref);
    }
  })
  docSnapTiempos.forEach(async (sub)=>{
    if(sub.exists()){
      await deleteDoc(sub.ref);
    }
  })
  deleteDoc(docuRef);
}
// ------------------ fin de configuracion de torneos temporales ----------------------

// ------------------ configuracion de planillas ----------------------

/* La funcion guardarJPPlanilla nos ayudara a registrar cada jornada con sus respetivas pruebas a planilla que se esta editando en el espacio temporal */
export function guardarJPPlanilla(idTorneo, jornadas){
  const docuRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
  updateDoc(docuRef, {jornadas});
}

// ------------------ fin configuracion de planillas ----------------------

// ------------------ configuracion de participacion club e inscripciones ----------------------

//esta funcion nos ayudara a traer los clubes autorizados y asi llevar un control de ellos.
export async function traerClubesAutorizados(){
  const docRef = doc(firestore, "autorizacion", `XKF4ZIAy7gkrtm7Batwk`);
  const docSnap = await getDoc(docRef)
  const nombre = docSnap.data().autorizados;
  return nombre;
}

//Esta funcion nos ayudara a registrar al club al darle en el boton participar
let contadorNotificacion = 0;
export async function registrarClubAlTorneo(id, club, idForanea, photoActual, torneoEspecifico) {
  if(id !== ''){
    const docuRef = doc(firestore, `TorneosTemporales/${id}`, `clubes/${idForanea}`);
    const clubesInscritos = await getDoc(docuRef);
    if(!clubesInscritos.exists()){
      traerClubesAutorizados().then((auto)=>{
        for (let i = 0; i < auto.length; i++) {
          if(auto[i].nombre === club.nombreclub){
            club.abreviatura = auto[i].abreviatura
          }
        }
        setDoc(docuRef, club).then(()=>{
          traerTokensNotificacionInscripciones().then((tokens)=>{
            if(contadorNotificacion === 0){
              tokens.forEach((token)=>{
                notificacion(token, `Se inscribió en ${torneoEspecifico.titulo}`, club.nombreclub);
              })
              contadorNotificacion++;
            }
          });
        });
      })
    }else{
      if(clubesInscritos.data().photo === null || clubesInscritos.data().photo !== photoActual){
        updateDoc(docuRef, {photo: club.photo});
      }
    }
  }
}

//esta funcion nos ayudara a registrar los nadadores para la competencia.
export async function registrarNadador(nombre, id, nadadores, idForanea){
  const docuRef = doc(firestore, `TorneosTemporales/${id}`, `clubes/${idForanea}`);

  updateDoc(docuRef, {nadadores}).then(() =>{
    toast('Deportista ' + nombre + ', se ha registrado correctamente.',{
      icon: '🏊🏊‍♀️'
    });
  }).catch(() =>{
    toast.error('UPS!! Ocurrio un error, intentalo de nuevo o comunicate con el administrador de la pagina.');
  })
}

//Esta funcion nos ayudara a traer los nadadores de un club especifico.
let unsubscribeNadadores = null;

export function traerNadadores(idTorneo, idClub, setNadadores, setNadadoresRelevo){
  if(idTorneo.length !== 0){
    const docRef = doc(firestore, `TorneosTemporales/${idTorneo}/clubes/${idClub}`);
    unsubscribeNadadores = onSnapshot(docRef, (docSnap) => {
      if(docSnap.exists()){
        const nombre = docSnap.data() ? docSnap.data().nadadores : [];
        const nombreRelevo = docSnap.data() ? docSnap.data().relevos : [];
        if (nombre.length !== 0) {
          nombre.sort(function (a, b) {
            return a.edad - b.edad;
          });
        }
        if(nombreRelevo.length !== 0) {
          nombreRelevo.sort(function (a, b) {
            return a.edad - b.edad;
          });
        }
        setNadadores(nombre);
        setNadadoresRelevo(nombreRelevo);
      }else{
        setNadadores([]);
      }
    });
  }
}

// Función para cancelar la suscripción
export function cancelarSuscripcionNadadores() {
  if (unsubscribeNadadores) {
    unsubscribeNadadores();
    unsubscribeNadadores = null;
  }
}

let unsubscribeTraerClubes = null;

//operaciones para traer los datos de los clubes inscritos
export function traerClubes(idTorneo, setClubes){
  if(idTorneo.length !== 0){
    const clubesRef = collection(firestore, `TorneosTemporales/${idTorneo}/clubes`);
    unsubscribeTraerClubes = onSnapshot(clubesRef, (snapshot) => {
      const clubesInscritos = [];
  
      snapshot.forEach((doc) => {
        if (doc.data().nadadores) {
          clubesInscritos.push(doc.data());
        }
      });

      clubesInscritos.sort((a, b)=> {
        if(a.nombreclub > b.nombreclub){
          return 1;
        }else if(a.nombreclub < b.nombreclub){
          return -1;
        }
        return 0;
      });
      
      setClubes(clubesInscritos);
    });
  }
}

// Función para cancelar la suscripción
export function cancelarSuscripcionTraerClubes() {
  if (unsubscribeTraerClubes) {
    unsubscribeTraerClubes();
    unsubscribeTraerClubes = null;
  }
}

//Esta funcion nos ayudara a modificar los datos del club.
export function registrarDirectivosClub(id, directivos, idTorneo){
  const docRef = doc(firestore, `TorneosTemporales/${idTorneo}/clubes/${id}`);
  updateDoc(docRef, { 
    directivos
  }
  ).then(() =>{
    toast.success('Directivos registrados correctamente.');
  });
}

export async function traerDirectivos(idTorneo, idClub, setDirectivos){
  const docuRef = doc(firestore, `TorneosTemporales/${idTorneo}/clubes/${idClub}`);
  const club = await getDoc(docuRef);

  if(club.exists()){
    if(club.data().directivos){
      setDirectivos(club.data().directivos);
    }
  }
}

//operaciones para eliminar nadadores
export async function eliminarNadador(nombre, id, idForanea, nadadores){
  const docuRef = doc(firestore, `TorneosTemporales/${id}/clubes/${idForanea}`);
  updateDoc(docuRef, {nadadores: nadadores}).then(() =>{
    toast.success('Deportista ' + nombre + ', se ha eliminado correctamente.',{
      icon: '😢'
    });
  }).catch(() =>{
    toast.error('UPS!! Ocurrio un error, intentalo de nuevo o comunicate con el administrador de la pagina.');
  })
}

//Esta funcion nos ayudara a traer un club especifico.
export async function traerClubesDelTorneo(id, idForanea){
  const docRef = doc(firestore, `TorneosTemporales/${id}`, `clubes/${idForanea}`);
  const docSnap = await getDoc(docRef)
  const clubes = docSnap.data().clubes;
  return clubes;
}

// ------------------ fin configuracion de participacion club e inscripciones ----------------------
// ------------------ configuracion de facturacion participacion ----------------------
export async function registrarFacturacionParticipacion(id, idForanea, pago){
  const docuRef = doc(firestore, `TorneosTemporales/${id}/clubes/${idForanea}`);
  updateDoc(docuRef, {pago}).then(() =>{
    toast.success(`Se actualizo el pago correctamente.`);
  }).catch(() =>{
    toast.error('UPS!! Ocurrio un error, intentalo de nuevo o comunicate con el administrador de la pagina.');
  })
}
// ------------------ fin configuracion de facturacion participacion ------------------
// ------------------ configuracion del JPC (Jornadas, Pruebas, Categorias) ----------------------

/* La funcion generarIdPrueba() nos ayudara a generar un id unico con 10 caracteres para cada prueba*/
export function generarIdPrueba() {
  const caracteres =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let nuevaContrasena = "";
  for (let i = 0; i < 10; i++) {
    nuevaContrasena += caracteres.charAt(
      Math.floor(Math.random() * caracteres.length)
    );
  }
  return nuevaContrasena;
}

//Esta funcion nos ayudara a registrar cada prueba en nuestras bases de datos.
export async function registrarPrueba(pruebas){
  const docuRef = doc(firestore, `jpc/${pruebas[0].categoria}`);
  const pruebasInscritas = await getDoc(docuRef);
  if(pruebasInscritas.exists()){
    const aux = [...pruebasInscritas.data().pruebas, ...pruebas];
    updateDoc(docuRef, {pruebas: aux});
    toast.success("Prueba registrada correctamente.");
  }else{
    const aux = [...pruebas];
    setDoc(docuRef, {pruebas: aux})
    toast.success("Prueba registrada correctamente.");
  }
}

//Esta funcion nos ayudara a registrar cada prueba en nuestras bases de datos.
export async function registrarCantPruebaPorCategoria(cantPruebas, categoria){
  const docuRef = doc(firestore, `jpc/${categoria}`);
  const pruebasInscritas = await getDoc(docuRef);
  if(pruebasInscritas.exists()){
    updateDoc(docuRef, {cantPruebas});
    toast.success("Cantidad de pruebas registrada correctamente.");
  }else{
    setDoc(docuRef, {cantPruebas})
    toast.success("Cantidad de pruebas registrada correctamente.");
  }
}

//Esta funcion nos ayudara a registrar cada prueba en nuestras bases de datos.
export async function eliminarPruebas(prueba, categoria){
  const docuRef = doc(firestore, `jpc/${categoria}`);
  const pruebasInscritas = await getDoc(docuRef);
  if(pruebasInscritas.exists()){
    const auxPruebas = [];
    for (let i = 0; i < pruebasInscritas.data().pruebas.length; i++) {
      if(pruebasInscritas.data().pruebas[i].prueba !== prueba){
        auxPruebas.push(pruebasInscritas.data().pruebas[i]);
      }
    }

    if(auxPruebas.length !== 0){
      updateDoc(docuRef, {pruebas: auxPruebas});
    }else{
      deleteDoc(docuRef);
    }
    toast.success("Prueba eliminada correctamente.");
  }
}

//Esta funcion nos ayudara a traer las pruebas para la seccion registro JPC.
export async function traerPruebasParaRegistroJPC(setPruebas){
  onSnapshot(collection(firestore, "jpc"), (docc) => {
    const auxPruebas = [];
    if(docc.docs.length !== 0){
      docc.docs.forEach((user) => {
         auxPruebas.push(...user.data().pruebas);
      });
    }
    setPruebas(auxPruebas);
  });
}

// ------------------ fin configuracion del JPC (Jornadas, Pruebas, Categorias) -------------------------------

// ------------------ configuracion del la automatizacion de planillas ----------------------
//operacion para registrar las tablas.
export async function registrarTablasPlanilla(id, pruebas, nadadoresCarriles, fecha){
  const docRef = doc(firestore, `TorneosTemporales/${id}/planilla/${pruebas.id}`);
  const docSnap = await getDoc(docRef);

  if(nadadoresCarriles[0]){
    for (let i = 0; i < nadadoresCarriles.length; i++) {
      for (let j = 0; j < nadadoresCarriles[i].nadadores.length; j++){
        if(nadadoresCarriles[i].nadadores[j].identificacion[0]){
          const deport = pruebas.deportistas.filter((depor)=>{return depor.identificacion === nadadoresCarriles[i].nadadores[j].identificacion[0]})
          if(deport.length !== 0){
            const pruebaEncontrada = deport[0].records.filter((prue)=>{return prue.prueba === pruebas.prueba.id})
            nadadoresCarriles[i].nadadores[j].record = pruebaEncontrada[0].record;
          }else{
            nadadoresCarriles[i].nadadores[j].record = '0:0:0';
          }
          if(!docSnap.exists()){
            setDoc(docRef, {
              id: pruebas.id,
              fechaR: fecha,
              planilla: nadadoresCarriles
            });
          }else{
            updateDoc(docRef, {
              id: pruebas.id,
              fechaR: fecha,
              planilla: nadadoresCarriles
            });
          }
        }
      }
    }
  }else{
    if(!docSnap.exists()){
      setDoc(docRef, {
        id: pruebas.id,
        fechaR: fecha,
        planilla: []
      });
    }else{
      updateDoc(docRef, {
        id: pruebas.id,
        fechaR: fecha,
        planilla: []
      });
    }
  }
}

export async function traerTodasTablasPlanilla(id, setPlanilla, setFechaRegistroProgramacion){
  if(id.length !== 0){
    const docRef = collection(firestore, `TorneosTemporales/${id}/planilla`);
    // Escucha cambios en la colección "planilla"
    onSnapshot(docRef, (querySnapshot) => {
      const updatedPlanilla = [];
      const planilla = [];
      querySnapshot.forEach((doc) => {
        const planillaDoc = doc.data();
        updatedPlanilla.push(planillaDoc);
      });
  
      // Ordena la planilla por algún criterio si es necesario
      updatedPlanilla.sort((a, b) => a.id - b.id);

      updatedPlanilla.forEach((pla)=>{
        planilla.push(pla.planilla);
      })

      const fechaRegistro = new Date(updatedPlanilla[0]?.fechaR).toLocaleString('es-ES',{ 
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        weekday: 'long'
      });

      setPlanilla(planilla);
      setFechaRegistroProgramacion(fechaRegistro !== 'Invalid Date' ? fechaRegistro : 'No se ha registrado aún.');
    });
  }
}

export async function sacarNadadoresDeLosClubs(id, setDeportistas){
  if(id.length !== 0){
    const docRef = collection(firestore, `TorneosTemporales/${id}/clubes`);
    const docSnap = await getDocs(docRef);
    const auxDeportistas = [];
    docSnap.forEach((club)=>{
        auxDeportistas.push(...club.data().nadadores)
    })
    setDeportistas(auxDeportistas);
  }
}

function unirYBuscarPrueba(jornadas, pruebaId){
  let nombrePrueba = '';
  jornadas.forEach((jor)=>{
    jor.pruebas.forEach((prue)=>{
      if(prue.id === pruebaId){
        nombrePrueba = prue.prueba;
      }
    })
  })
  return nombrePrueba;
}

//planillas
export async function organizarPorTiempos(cc, tiempos, jornadas, piscina){
  const deport = doc(firestoreRankingPws, `ranking/${cc + ''}`);
  const docDeport = await getDoc(deport);
  const auxTiempos = [];
  if(docDeport.exists()){
    tiempos.forEach((prue)=>{
      const pruebaEncontrada = docDeport.data().pruebas.filter((p)=>{return p.prueba === unirYBuscarPrueba(jornadas, prue.prueba) && p.piscina === piscina});
      if(pruebaEncontrada.length === 0){
        const aux = {
          prueba: prue.prueba,
          record: prue.record
        }
        
        auxTiempos.push(aux);
      }else{

        const auxTiempoA = prue.record.split(':');
        const auxTiempoB = pruebaEncontrada[0].record.split(':');

        const auxMilisegundosA = parseInt(auxTiempoA[0]) * 60 * 1000 + parseInt(auxTiempoA[1]) * 1000 + parseInt(auxTiempoA[2]);
        const auxMilisegundosB = parseInt(auxTiempoB[0]) * 60 * 1000 + parseInt(auxTiempoB[1]) * 1000 + parseInt(auxTiempoB[2]);

        const aux = {
          prueba: prue.prueba,
          record: prue.record
        }

        if(auxMilisegundosA >= auxMilisegundosB || prue.record === '0:0:0'){
          aux.record =  pruebaEncontrada[0].record;
        }
        
        auxTiempos.push(aux);
      }
    })
  }else{
    tiempos.forEach((prue)=>{
      const aux = {
        prueba: prue.prueba,
        record: prue.record
      }
      auxTiempos.push(aux);
    })
  }
  return auxTiempos;
}

export async function traerCategorias(pruebas, setNadadoresCarriles, deportistas, carril, modoSeries){
  const auxDeportistas = [];
  const auxPruebas = []
  pruebas.forEach((prue)=>{
    prue.forEach((pru)=>{
      auxPruebas.push(pru);
    })
  })
  auxPruebas.forEach((auxPrue)=>{
    if(auxPrue.genero === 'Mixto'){
      const auxDeportista = deportistas.filter((deport)=>{
        return (deport.pruebas.includes(auxPrue.prueba.id) &&
        (deport.edad >= parseInt(auxPrue.edadInicio) && 
        deport.edad <= parseInt(auxPrue.edadFin)))
      })
      if(auxDeportista.length !== 0){
        auxPrue.deportistas = [...auxDeportista];
      }
    }else{
      const auxDeportista = deportistas.filter((deport)=>{
        return (deport.pruebas.includes(auxPrue.prueba.id) && 
        deport.genero === auxPrue.genero &&
        (deport.edad >= parseInt(auxPrue.edadInicio) && 
        deport.edad <= parseInt(auxPrue.edadFin)))
      })
      if(auxDeportista.length !== 0){
        auxPrue.deportistas = [...auxDeportista];
      }
    }
  })
  auxPruebas.forEach(async(prue)=>{
    auxDeportistas.push(ensayoNadadores(prue.deportistas, carril, prue.prueba.id, modoSeries));
  })
  setNadadoresCarriles(auxDeportistas);
}

//operacion para sacar las series necesarias.
function ensayoNadadores(nadadores, carril, idPrueba, modoSeries){
  const dividirNSeries = nadadores.length/carril;
  const nSeriesEnsayo = sacarNseriesEnsayo(dividirNSeries);
  const nSeries = sacarNseriesEnsayo(dividirNSeries).length;
  const carriles = organizarNadadores(nadadores, nSeries, carril, idPrueba, modoSeries);

  for (let i = nSeries - 1; i >= 0 ; i--) {
    nSeriesEnsayo[i] = {Serie: i + 1, nadadores: []};
    for (let j = 0; j < carriles[i].length; j++) {
      const equipo = carriles[i][j].map((user)=>{return user.equipo});
      const records = carriles[i][j].map((user)=>{return user.records});
      const categoria = carriles[i][j].map((user)=>{return user.categoria});
      if(equipo[0]){
        const edades = carriles[i][j].map((user)=>{return user.edades});
        nSeriesEnsayo[i].nadadores.push({carril: j + 1, nombre: carriles[i][j].map((user)=>{return user.nombre}), club: carriles[i][j].map((user)=>{return user.club}), identificacion: carriles[i][j].map((user)=>{return user.identificacion}), nombreclub: carriles[i][j].map((user)=>{return user.nombreclub}), edad: carriles[i][j].map((user)=>{return user.edad}), categoria: categoria[0] ? categoria[0] : [], records: records[0] ? records[0] : [], equipo: equipo[0], edades: edades[0]});
      }else{
        nSeriesEnsayo[i].nadadores.push({carril: j + 1, nombre: carriles[i][j].map((user)=>{return user.nombre}), club: carriles[i][j].map((user)=>{return user.club}), identificacion: carriles[i][j].map((user)=>{return user.identificacion}), nombreclub: carriles[i][j].map((user)=>{return user.nombreclub}), edad: carriles[i][j].map((user)=>{return user.edad}), categoria: categoria[0] ? categoria[0] : [], records: records[0] ? records[0] : []});
      }
    }
  }
    return nSeriesEnsayo;
}

//operacion para calcular el numero de series que salen en cada categoria
function sacarNseriesEnsayo(n){
  const nSeries = [];
  if(n % 1 === 0){
    for (let i = 0; i < Math.floor(n); i++) {
      nSeries.push({});
    }
  }else{
    for (let i = 0; i < Math.floor(n) + 1; i++) {
      nSeries.push({});
    }
  }
  return nSeries;
}

//operacion para organizar los nadadores.
function organizarNadadores(nadadores, nSeries, carril, idPrueba, modoSeries) {
  if(modoSeries === 'rapidas'){
    const nCarriles = carril;
  
    // Filtrar y ordenar nadadores con tiempo registrado
    const nadadoresConTiempo = nadadores
      .filter(nadador =>
        nadador.records.some(record => record.prueba === idPrueba && record.record !== '0:0:0')
      )
      .sort((a, b) => {
        const tiempoA = a.records.find(record => record.prueba === idPrueba)?.record;
        const tiempoB = b.records.find(record => record.prueba === idPrueba)?.record;
        return tiempoToSeconds(tiempoA) - tiempoToSeconds(tiempoB);
      });
    
    // Filtrar nadadores sin tiempo registrado y mezclarlos aleatoriamente
    const nadadoresSinTiempo = nadadores
      .filter(nadador =>
        nadador.records.every(record => record.prueba !== idPrueba || record.record === '0:0:0')
      )
      .sort(() => Math.random() - 0.5); // Mezclar aleatoriamente
    
    // Unir las listas: primero los nadadores con tiempo, luego los sin tiempo
    const nadadoresOrdenados = nadadoresConTiempo.concat(nadadoresSinTiempo);
    
    // Dividir en series
    const series = Array.from({ length: nSeries }, () => []);
    nadadoresOrdenados.forEach((nadador, index) => {
      const serieIndex = Math.floor(index / nCarriles);
      if (serieIndex < nSeries) {
        series[serieIndex].push(nadador);
      }
    });
  
    // Organizar carriles dentro de cada serie
    const carriles = series.map(serie => {
      const centralCarril = Math.floor(nCarriles / 2);
      const serieCarriles = Array.from({ length: nCarriles }, () => []);
    
      let offset = 0;
    
      // Colocar nadadores de la serie en los carriles centrales hacia los extremos
      serie.forEach(nadador => {
        let carrilIndex;
        if (offset % 2 === 0) {
          carrilIndex = centralCarril + offset / 2;
        } else {
          carrilIndex = centralCarril - (offset + 1) / 2;
        }
        offset++;
      
        // Agregar nadador al carril correspondiente
        serieCarriles[carrilIndex].push(nadador);
      });
    
      return serieCarriles;
    });
  
    return carriles.reverse();
  }else{
    const nCarriles = carril;

    // Filtrar los nadadores que tienen tiempos registrados para la prueba
    const nadadoresConTiempo = nadadores.filter(nadador =>
      nadador.records.some(record => record.prueba === idPrueba && record.record !== '0:0:0')
    );
  
    // Ordenar los nadadores por tiempo si tienen tiempo registrado
    nadadoresConTiempo.sort((a, b) => {
      const tiempoA = a.records.find(record => record.prueba === idPrueba)?.record;
      const tiempoB = b.records.find(record => record.prueba === idPrueba)?.record;
      return tiempoToSeconds(tiempoA) - tiempoToSeconds(tiempoB);
    });
  
    // Filtrar los nadadores sin tiempo
    const nadadoresSinTiempo = nadadores.filter(nadador =>
      nadador.records.every(record => record.prueba !== idPrueba || record.record === '0:0:0')
    );
  
    // Mezclar los nadadores con tiempo y los de tiempo 0
    const nadadoresMezclados = nadadoresConTiempo.concat(nadadoresSinTiempo);
  
    // Distribuir los nadadores de cada club entre las series de forma equitativa
    const clubes = {};
    nadadoresMezclados.forEach(nadador => {
      if (!clubes[nadador.nombreclub]) {
        clubes[nadador.nombreclub] = [];
      }
      clubes[nadador.nombreclub].push(nadador);
    });
  
    // Distribuir los nadadores de cada club en series separadas
    const seriesPorClub = Object.values(clubes);
    const series = Array.from({ length: nSeries }, () => []);
    let serieIndex = 0;
    seriesPorClub.forEach(club => {
      club.forEach(nadador => {
        series[serieIndex % nSeries].push(nadador);
        serieIndex++;
      });
    });
  
    // Mezclar las series para evitar que los nadadores del mismo club estén en la misma serie
    series.forEach(serie => {
      serie.sort(() => Math.random() - 0.5);
    });
  
    // Distribuir los nadadores de cada serie en los carriles, colocando primero a los más rápidos en los carriles centrales
    const carriles = Array.from({ length: nSeries }, () =>
      Array.from({ length: nCarriles }, () => [])
    );
  
    series.forEach((serie, serieIndex) => {
      // Filtrar los nadadores con tiempo en la serie actual
      const nadadoresConTiempoEnSerie = serie.filter(nadador =>
        nadador.records.some(record => record.prueba === idPrueba && record.record !== '0:0:0')
      );
    
      // Ordenar los nadadores con tiempo por tiempo de menor a mayor
      nadadoresConTiempoEnSerie.sort((a, b) => {
        const tiempoA = a.records.find(record => record.prueba === idPrueba)?.record;
        const tiempoB = b.records.find(record => record.prueba === idPrueba)?.record;
        return tiempoToSeconds(tiempoA) - tiempoToSeconds(tiempoB);
      });
    
      const centralCarril = Math.floor(nCarriles / 2);
      let offset = 0;
    
      // Colocar a los nadadores en los carriles centrales, expandiéndose hacia los laterales
      nadadoresConTiempoEnSerie.forEach(nadador => {
        let carrilIndex;
      
        if (offset % 2 === 0) {
          carrilIndex = centralCarril + offset / 2;
        } else {
          carrilIndex = centralCarril - (offset + 1) / 2;
        }
      
        offset++;
      
        // Asegurarse de no exceder el límite de nadadores en un carril
        while (
          carriles[serieIndex][carrilIndex].length >= Math.ceil(serie.length / nCarriles) ||
          carriles[serieIndex][carrilIndex].some(n => n.nombreclub === nadador.nombreclub)
        ) {
          carrilIndex = (carrilIndex + 1) % nCarriles; // Mover al siguiente carril
        }
      
        carriles[serieIndex][carrilIndex].push(nadador);
      });
    
      // Colocar a los nadadores sin tiempo en los carriles centrales
      const nadadoresSinTiempoEnSerie = serie.filter(nadador =>
        nadador.records.every(record => record.prueba !== idPrueba || record.record === '0:0:0')
      );
    
      nadadoresSinTiempoEnSerie.forEach(nadador => {
        let carrilIndex;
      
        if (offset % 2 === 0) {
          carrilIndex = centralCarril + offset / 2;
        } else {
          carrilIndex = centralCarril - (offset + 1) / 2;
        }
      
        offset++;
      
        // Asegurarse de no exceder el límite de nadadores en un carril
        while (
          carriles[serieIndex][carrilIndex].length >= Math.ceil(serie.length / nCarriles) ||
          carriles[serieIndex][carrilIndex].some(n => n.nombreclub === nadador.nombreclub)
        ) {
          carrilIndex = (carrilIndex + 1) % nCarriles; // Mover al siguiente carril
        }
      
        carriles[serieIndex][carrilIndex].push(nadador);
      });
    });
  
    return carriles.reverse();
  }
}

// Función auxiliar para convertir tiempo en formato 'HH:MM:SS' a segundos
function tiempoToSeconds(tiempo) {
  const [horas, minutos, segundos] = tiempo.split(':').map(Number);
  return horas * 3600 + minutos * 60 + segundos;
}

// ------------------ fin configuracion del la automatizacion de planillas ----------------------
//------------------- configuracion de datos locales -----------------------------
export async function abrirBaseDeDatosLocal(id){
  if(id !== ''){
    const dbTiempos = await openDB(id, 1, {
      upgrade(dbTiempos) {
        dbTiempos.createObjectStore('resultados', { keyPath: 'id', autoIncrement: true });
      },
    });
  }
};

async function agregarTiempoLocal(prueba, id) {
  if(id !== ''){
    const db = await openDB(id, 1);
    const datoExistente = await verificarResultadosTiemposLocalesExistente(db, 'resultados', prueba.id);
    const tx = db.transaction('resultados', 'readwrite');
    const store = tx.objectStore('resultados');
    if(datoExistente){
      store.put(prueba);
    }else{
      store.add(prueba);
    }
    await tx.done;
  }
}

async function verificarResultadosTiemposLocalesExistente(db, almacenamiento, clave) {
  const tx = db.transaction(almacenamiento);
  const store = tx.objectStore(almacenamiento);
  return await store.get(clave);
}

async function recuperarResultadosTiemposLocales(db, id) {
  const tx = db.transaction(id);
  const store = tx.objectStore(id);
  const datos = await store.getAll();
  return datos;
}

export async function backupTiemposLocales(id, setBackup) {
  if(id !== ''){
    const db = await openDB(id, 1);
    const tx = db.transaction('resultados');
    const store = tx.objectStore('resultados');
    const datos = await store.getAll();
    setBackup(datos);
  }
}

async function eliminarBaseDeDatosLocal(id){
  const toastId = toast.loading('Eliminando tiempos en cache para guardar nuevos tiempos...');
  deleteDB(id).then(()=>{
    toast.dismiss(toastId);
    toast.success('Tiempos Eliminados con exito.');
  })
}
//------------------- fin configuracion de datos locales -----------------------------
// ------------------ configuracion de los tiempos -----------------------------
//verificar y limpiar el almacenamiento local
let contadorMensajeBDLocal = 0;
export async function verificarLimpiar(id) {
  if(id !== ''){
    const db = await openDB(id, 1);
    const auxResultadosLocal = await recuperarResultadosTiemposLocales(db, 'resultados');
    const docRef = collection(firestore, `TorneosTemporales/${id}/tiempos`);
    const auxResultados = await getDocs(docRef);
    var condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      fetch('https://www.google.com/', { // Check for internet connectivity
        mode: 'no-cors',
      }).then(() => {
        if(auxResultadosLocal.length !== 0 && auxResultados.empty && contadorMensajeBDLocal === 0){
          contadorMensajeBDLocal++;
          eliminarBaseDeDatosLocal(id);
        }
      })
    }
  }
}

//tiempos Registrar
export async function registrarTiempos(id, idTiempo, prueba, auxTiemposRegistrados){
  const auxPrueba = {
    categoria: prueba.categoria, 
    id: prueba.id, 
    nadadores: prueba.nadadores, 
    prueba: prueba.prueba, 
    seriesRegistradas: auxTiemposRegistrados[prueba.id]
  }
  try {
    const docRef = doc(firestore, `TorneosTemporales/${id}/tiempos/${idTiempo}`);
    const docSnap = await getDoc(docRef)
    if(!docSnap.exists()){
      setDoc(docRef, prueba);
      toast.success('Tiempos registrados correctamente.')
    }else{
      updateDoc(docRef, prueba);
      toast.success('Tiempos actualizados correctamente.')
    }
    agregarTiempoLocal(auxPrueba, id);
  } catch (error) {
    agregarTiempoLocal(auxPrueba, id);
    toast.error('Sin conexión.')
    toast.success('Tiempos actualizados correctamente en el almacenamiento local.')
  }
}

//operacion para traer las tablas de cada prueba
let unsubscribePlanillas = null;

export async function traerTablasPlanilla(id, setNadadoresCarriles, idPlanilla){
  const docRef = doc(firestore, `TorneosTemporales/${id}/planilla/${idPlanilla}`);
  unsubscribePlanillas = onSnapshot(docRef, (docSnap) => {
    if(docSnap.exists){
      setNadadoresCarriles(docSnap.data().planilla);
    }else{
      setNadadoresCarriles([]);
    }
  });
}

// Función para cancelar la suscripción
export function cancelarSuscripcionPlanillas() {
  if (unsubscribePlanillas) {
    unsubscribePlanillas();
    unsubscribePlanillas = null;
  }
}

//operacion para traer los resultados en tiempo real.
let unsubscribeTiempos = null;

export async function traerResultados(id, setResultados, setConexion, nombreclub){
  if(id !== ''){
    let db;
    let auxResultados;
    if(nombreclub === datosAdmin.nombre || nombreclub === 'PROWEBSPORTS'){
      db = await openDB(id, 1);
      auxResultados = await recuperarResultadosTiemposLocales(db, 'resultados');
    }
    const tiemposCollectionRef = collection(firestore, `TorneosTemporales/${id}/tiempos`);
    
    unsubscribeTiempos = onSnapshot(tiemposCollectionRef, (querySnapshot) => {
      const resultados = querySnapshot.docs
        .filter((doc) => doc.exists)
        .map((doc) => doc.data())
        .sort((a, b) => a.id - b.id);
  
        if(nombreclub === datosAdmin.nombre || nombreclub === 'PROWEBSPORTS'){
          if(resultados.length < auxResultados.length){
            setConexion(false);
            auxResultados.sort((a, b) => a.id - b.id);
            setResultados(auxResultados);
          }else{
            setConexion(true);
            setResultados(resultados);
          }
        }else{
          setResultados(resultados);
        }
    }, (error) =>{
      if(nombreclub === datosAdmin.nombre || nombreclub === 'PROWEBSPORTS'){
        setConexion(false);
        auxResultados.sort((a, b) => a.id - b.id);
        setResultados(auxResultados);
      }
    });
  }
}

export function cancelarTiemposResultados(){
  if (unsubscribeTiempos) {
    unsubscribeTiempos();
    unsubscribeTiempos = null;
  }
}

// ------------------ fin configuracion de los tiempos ----------------------------
// ------------------ configuracion ranking -----------------------------
//esta funcion nos ayudara a registrar todos los nadadores y asi tener un registro general de ellos.
export async function registrarDeportistaGeneral(id, deport, departamento){
  const docRef = doc(firestoreRankingPws, `ranking/${id}`);
  const docSnap = await getDoc(docRef);
  let hoy = new Date();
  let cumple = !docSnap.exists() ? deport.fechaNacimiento.split('-') : docSnap.data().verificado === 'Verificado' ? docSnap.data().fechaNacimiento.split('-') : deport.fechaNacimiento.split('-');
  let cumpleanosAno = parseInt(cumple[0]);
  let edad = hoy.getFullYear() - cumpleanosAno;

  if(!docSnap.exists()){
    const auxNadador = {
      club: deport.nombreclub,
      nombre: deport.nombre,
      identificacion: deport.identificacion,
      fechaNacimiento: deport.fechaNacimiento,
      edad: edad + '',
      genero: deport.genero,
      departamento,
      pruebas: [],
      verificado: deport.verificado
    }
    setDoc(docRef, auxNadador);
    toast.success('Deportista registrado en el ranking de PROWEBSPORTS correctamente.');
  }else{
    updateDoc(docRef, {
      club: deport.nombreclub, 
      fechaNacimiento: docSnap.data().verificado === 'Verificado' ? docSnap.data().fechaNacimiento : deport.fechaNacimiento, 
      edad: edad + '',
      genero: deport.genero,
      verificado: docSnap.data().verificado === 'Verificado' ? 'Verificado' : deport.verificado
    });
  }
}

function escogerTimeCorrecto(recordRegistrado, recordGuardado){
  const dividirTiempoA = recordRegistrado.split(':');
  const dividirTiempoB = recordGuardado.split(':');
  const tiempoMinA = dividirTiempoA[0];
  const tiempoSegA = dividirTiempoA[1];
  const tiempoMiliA = dividirTiempoA[2];
  const tiempoMinB = dividirTiempoB[0];
  const tiempoSegB = dividirTiempoB[1];
  const tiempoMiliB = dividirTiempoB[2];
  const tiempoA = parseInt(tiempoMinA) * 60 * 1000 + parseInt(tiempoSegA) * 1000 + parseInt(tiempoMiliA);
  const tiempoB = parseInt(tiempoMinB) * 60 * 1000 + parseInt(tiempoSegB) * 1000 + parseInt(tiempoMiliB);
  if(recordGuardado === '0:0:0' && recordRegistrado !== '0:0:0'){
    return recordRegistrado;
  }else if(recordRegistrado === '0:0:0' && recordGuardado !== '0:0:0'){
    return recordGuardado;
  }else if(recordGuardado === '0:0:0' && recordRegistrado === '0:0:0'){
    return '0:0:0';
  }else{
    if(tiempoA <= tiempoB){
      return recordRegistrado;
    }else{
      return recordGuardado;
    }
  }
}

function pruebasEstablecidasRanking(){
  const auxPruebas = [
    '25 Libre',
    '50 Libre',
    '100 Libre',
    '200 Libre',
    '400 Libre',
    '800 Libre',
    '1500 Libre',

    '25 Espalda',
    '50 Espalda',
    '100 Espalda',
    '200 Espalda',

    '25 Pecho',
    '50 Pecho',
    '100 Pecho',
    '200 Pecho',

    '25 Mariposa',
    '50 Mariposa',
    '100 Mariposa',
    '200 Mariposa',

    '100 Combinado Ind.',
    '200 Combinado Ind.',
    '400 Combinado Ind.',
  ];

  return auxPruebas;
}

//funcion para ir registrando los records y asi poder mostrar el mejoramiento de los nadadores en el ranking de prowebsports.
export async function registrarRecordsPws(id, prueba, tiempo, recordActual, posicion, nCarriles, distanciaPiscina){
  const pruebaEncontrada = pruebasEstablecidasRanking().filter((prue)=>{return prue === prueba.prueba})
  if(pruebaEncontrada.length !== 0){
    const docRef = doc(firestoreRankingPws, `ranking/${id}`);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()){
      const fechaTorneo = new Date();
      const dia = fechaTorneo.getDate();
      const mes = fechaTorneo.getMonth() + 1;
      const anio = fechaTorneo.getFullYear();
      const fecha = anio + '-' + String(mes).padStart(2, '0') + '-' + String(dia).padStart(2, '0');
      const auxRecords = {
        fecha,
        prueba: prueba.prueba,
        tiempo,
        record: tiempo,
        pg: tiempo === '0:0:0' ? '0' : parseInt(nCarriles) === 10 ? puntosEstablecidos10CarrilesFecna(0, posicion) : puntosEstablecidos8CarrilesFecna(0, posicion),
        pa: '0',
        piscina: distanciaPiscina
      }
    
      const recordsGuardados = docSnap.data().pruebas.filter((rec) => {return (rec.prueba === prueba.prueba && rec.piscina !== distanciaPiscina) || (rec.prueba !== prueba.prueba && (rec.piscina !== distanciaPiscina || rec.piscina === distanciaPiscina))});
      const recordsAntiguo = docSnap.data().pruebas.filter((rec) => {return rec.prueba === prueba.prueba && rec.piscina === distanciaPiscina});
        
      let records = [];
      if(recordsAntiguo.length !== 0){
        if(recordsAntiguo[0].fecha === fecha){
          const restarPuntos = parseInt(recordsAntiguo[0].pa - recordsAntiguo[0].pg);
          if(parseInt(nCarriles) === 10){
            auxRecords.pa = parseInt(puntosEstablecidos10CarrilesFecna(restarPuntos, posicion)) >= 0 ? puntosEstablecidos10CarrilesFecna(restarPuntos, posicion) : recordsAntiguo[0].pg;
          }
          if(parseInt(nCarriles) <= 8){
            auxRecords.pa = parseInt(puntosEstablecidos8CarrilesFecna(restarPuntos, posicion)) >= 0 ? puntosEstablecidos8CarrilesFecna(restarPuntos, posicion) : recordsAntiguo[0].pg;
          }
        }else{
          if(parseInt(nCarriles) === 10){
            auxRecords.pa = puntosEstablecidos10CarrilesFecna(parseInt(recordsAntiguo[0].pa), posicion);
          }
          if(parseInt(nCarriles) <= 8){
            auxRecords.pa = puntosEstablecidos8CarrilesFecna(parseInt(recordsAntiguo[0].pa), posicion);
          }
        }
    
        const dividirTiempoA = escogerTimeCorrecto(recordActual, recordsAntiguo[0].record).split(':');
        const dividirTiempoB = tiempo.split(':');
        const tiempoMinA = dividirTiempoA[0];
        const tiempoSegA = dividirTiempoA[1];
        const tiempoMiliA = dividirTiempoA[2];
        const tiempoMinB = dividirTiempoB[0];
        const tiempoSegB = dividirTiempoB[1];
        const tiempoMiliB = dividirTiempoB[2];
        const tiempoA = parseInt(tiempoMinA) * 60 * 1000 + parseInt(tiempoSegA) * 1000 + parseInt(tiempoMiliA);
        const tiempoB = parseInt(tiempoMinB) * 60 * 1000 + parseInt(tiempoSegB) * 1000 + parseInt(tiempoMiliB);
        
        if(tiempoA <= tiempoB){
          if(escogerTimeCorrecto(recordActual, recordsAntiguo[0].record) === '0:0:0'){
            auxRecords.record = tiempo;
          }else{
            auxRecords.record = escogerTimeCorrecto(recordActual, recordsAntiguo[0].record);
          }
          records = [auxRecords, ...recordsGuardados];
        }else{
          if(tiempo === '0:0:0'){
            auxRecords.record = escogerTimeCorrecto(recordActual, recordsAntiguo[0].record);
          }
          records = [auxRecords, ...recordsGuardados];
        }
      }else{
        if(parseInt(nCarriles) === 10){
          auxRecords.pa = puntosEstablecidos10CarrilesFecna(0, posicion);
        }
        if(parseInt(nCarriles) <= 8){
          auxRecords.pa = puntosEstablecidos8CarrilesFecna(0, posicion);
        }
        records = [auxRecords, ...recordsGuardados];
      }
  
      if(tiempo !== '61:0:0' && distanciaPiscina !== 'ninguno'){
        updateDoc(docRef, {
          pruebas: records
        })
      }
    }
  }
}

export function puntosEstablecidos8CarrilesFecna(puntos, posicion){
  let auxPuntos = '0';
  switch (posicion) {
    case 0:
      auxPuntos = `${puntos + 18}`
      break;
    case 1:
      auxPuntos = `${puntos + 16}`
      break;
    case 2:
      auxPuntos = `${puntos + 15}`
      break;
    case 3:
      auxPuntos = `${puntos + 14}`
      break;
    case 4:
      auxPuntos = `${puntos + 13}`
      break;
    case 5:
      auxPuntos = `${puntos + 12}`
      break;
    case 6:
      auxPuntos = `${puntos + 11}`
      break;
    case 7:
      auxPuntos = `${puntos + 10}`
      break;
    case 8:
      auxPuntos = `${puntos + 8}`
      break;
    case 9:
      auxPuntos = `${puntos + 7}`
      break;
    case 10:
      auxPuntos = `${puntos + 6}`
      break;
    case 11:
      auxPuntos = `${puntos + 5}`
      break;
    case 12:
      auxPuntos = `${puntos + 4}`
      break;
    case 13:
      auxPuntos = `${puntos + 3}`
      break;
    case 14:
      auxPuntos = `${puntos + 2}`
      break;
    case 15:
      auxPuntos = `${puntos + 1}`
      break;
    default:
      break;
  }
  return auxPuntos;
}

export function puntosEstablecidos10CarrilesFecna(puntos, posicion){
  let auxPuntos = '0';
  switch (posicion) {
    case 0:
      auxPuntos = `${puntos + 22}`
      break;
    case 1:
      auxPuntos = `${puntos + 20}`
      break;
    case 2:
      auxPuntos = `${puntos + 19}`
      break;
    case 3:
      auxPuntos = `${puntos + 18}`
      break;
    case 4:
      auxPuntos = `${puntos + 17}`
      break;
    case 5:
      auxPuntos = `${puntos + 16}`
      break;
    case 6:
      auxPuntos = `${puntos + 15}`
      break;
    case 7:
      auxPuntos = `${puntos + 14}`
      break;
    case 8:
      auxPuntos = `${puntos + 13}`
      break;
    case 9:
      auxPuntos = `${puntos + 12}`
      break;
    case 10:
      auxPuntos = `${puntos + 10}`
      break;
    case 11:
      auxPuntos = `${puntos + 9}`
      break;
    case 12:
      auxPuntos = `${puntos + 8}`
      break;
    case 13:
      auxPuntos = `${puntos + 7}`
      break;
    case 14:
      auxPuntos = `${puntos + 6}`
      break;
    case 15:
      auxPuntos = `${puntos + 5}`
      break;
    case 16:
      auxPuntos = `${puntos + 4}`
      break;
    case 17:
      auxPuntos = `${puntos + 3}`
      break;
    case 18:
      auxPuntos = `${puntos + 2}`
      break;
    case 19:
      auxPuntos = `${puntos + 1}`
      break;
    default:
      break;
  }
  return auxPuntos;
}

function rangoEdades(edad, auxNadadores, nad, edadI, edadFin){
  if(edad >= edadI && edad <= edadFin){
    if (!auxNadadores[`${edadI} - ${edadFin}`]) {
      auxNadadores[`${edadI} - ${edadFin}`] = [];
    }
    auxNadadores[`${edadI} - ${edadFin}`].push(nad);
  }
}

const parseEdad = (edad) => {
  if (edad.includes('-')) {
    // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
    return parseInt(edad.split('-')[1], 10);
  }
  if (edad.includes('y Mas')) {
    // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
    return parseInt(edad.split('-')[0], 10);
  }
  // Si es un número único, lo convertimos a entero
  return parseInt(edad, 10);
};

//esta funcion nos ayudara a traer los ranking.
export async function traerRanking(setRanking, setEdadesRanking){
  const docRef = collection(firestoreRankingPws, `ranking`);
  onSnapshot(docRef, (querySnapshot) => {
    const auxRanking = querySnapshot.docs
        .filter((doc) => doc.exists)
        .map((doc) => doc.data());

    const auxNadadores = {};
    auxRanking.forEach((nad)=>{
      const edad = parseInt(nad.edad);
      if(edad >= 5 && edad <= 18){
        if (!auxNadadores[edad]) {
            auxNadadores[edad] = [];
        }
        auxNadadores[edad].push(nad);
      }
      rangoEdades(edad, auxNadadores, nad, 19, 24);
      rangoEdades(edad, auxNadadores, nad, 25, 29);
      rangoEdades(edad, auxNadadores, nad, 30, 34);
      rangoEdades(edad, auxNadadores, nad, 35, 39);
      rangoEdades(edad, auxNadadores, nad, 40, 44);
      rangoEdades(edad, auxNadadores, nad, 45, 49);
      rangoEdades(edad, auxNadadores, nad, 50, 54);
      rangoEdades(edad, auxNadadores, nad, 55, 59);
      rangoEdades(edad, auxNadadores, nad, 60, 64);
      rangoEdades(edad, auxNadadores, nad, 65, 69);
      rangoEdades(edad, auxNadadores, nad, 70, 74);
      rangoEdades(edad, auxNadadores, nad, 75, 79);
      if(edad >= 80){
        if (!auxNadadores['80 y Mas']) {
            auxNadadores['80 y Mas'] = [];
        }
        auxNadadores['80 y Mas'].push(nad);
      }
    })

    const auxEdadesRanking = [];
    Object.keys(auxNadadores).forEach((value, index)=>{
      const objEdadesRanking = {
        edad: value
      }
      auxEdadesRanking.push(objEdadesRanking);
    })
    auxEdadesRanking.sort((a,b)=> parseEdad(a.edad) - parseEdad(b.edad));
    setEdadesRanking(auxEdadesRanking);
    setRanking(auxNadadores);
  })
}

export async function traerDeportistaGeneral(id, nombreclub, calcularEdad){
  const docRef = doc(firestoreRankingPws, `ranking/${id}`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){   
    if(nombreclub !== docSnap.data().club){
      toast.error('Se ha encontrado un deportista con esta identificación, pero el deportista no es de tu club, el cual no podemos mostrar su información.');
      return null;
    }else{
        const auxDeport = {
        club: docSnap.data().club,
        edad: calcularEdad(docSnap.data().fechaNacimiento),
        nombre: docSnap.data().nombre.trim(),
        fechaNacimiento: docSnap.data().fechaNacimiento,
        genero: docSnap.data().genero,
        identificacion: docSnap.data().identificacion,
        verificado: docSnap.data().verificado
      }
      return auxDeport;
    }
  }else{
    toast.error('El deportista que intenta buscar, no se encuentra en nuestras bases de datos.');
    return null;
  }
}

//----------------- fin configuracion ranking --------------------
//-------------------- configuracion de fotos y documentos -----------------
export function subirFotoTorneoEditar(file, id, idTorneo) {
  const nombreFoto = ref(storage, `${id}/torneos/${file.name}`);
  uploadBytes(nombreFoto, file)
    .then(() => {
      getDownloadURL(nombreFoto)
        .then((url) => {
          const docRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
          try {
            updateDoc(docRef, {image: url});
            toast.success('Tu foto ha sido cargada con éxito.');
          } catch (error) {
            toast.error('UPS! Ocurrio un error. Intentalo de nuevo.');
          }
        })
        .catch(() => {
          toast.error(
            "¡¡ups!! Ocurrió un error al mostrar la foto, prueba a elegir la foto o súbela de nuevo."
          );
        });
    })
    .catch(() => {
      toast.error(
        "¡¡ups!! Ocurrió un error al cargar la foto, intente nuevamente o cargue otra foto."
      );
    });
}

//funcion para elminar foto de curso
export function eliminarFotoTorneo(setImgCurso, id, file, idTorneo) {
  const nombreFoto = ref(storage, `${id}/torneos/${file}`);
  deleteObject(nombreFoto)
    .then(() => {
      setImgCurso();
      eliminarFotoTorneoFirestore(idTorneo);
      toast.success("La foto del torneo ha sido eliminada con éxito.");
    })
    .catch(() => {
      toast.error(
        "Se produjo un error al eliminar la foto, inténtalo de nuevo."
      );
    });
}

//funcion para elminar foto de curso en firestore
export function eliminarFotoTorneoFirestore(idTorneo) {
  const docRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
  try {
    updateDoc(docRef, {image: ""});
  } catch (error) {
    toast.error('UPS! Ocurrio un error. Intentalo de nuevo.');
  }
}

//funcion para subir las convocatorias de torneo
export function subirConvocatoriaTorneo(file, id, setImgCurso) {
  const nombreFoto = ref(storage, `${id}/convocatorias/${file.name}`);
  uploadBytes(nombreFoto, file)
    .then(() => {
      toast.success("La convocatoria ha sido cargada con éxito.");
      getDownloadURL(nombreFoto)
        .then((url) => {
          setImgCurso(url);
        })
        .catch(() => {
          toast.error(
            "¡¡ups!! Ocurrió un error al mostrar la convocatoria, prueba a elegir la convocatoria o súbela de nuevo."
          );
        });
    })
    .catch(() => {
      toast.error(
        "¡¡ups!! Ocurrió un error al cargar la convocatoria, intente nuevamente o cargue otra convocatoria."
      );
    });
}

export function subirConvocatoriaTorneoEditar(file, id, idTorneo) {
  const nombreFoto = ref(storage, `${id}/convocatorias/${file.name}`);
  uploadBytes(nombreFoto, file)
    .then(() => {
      getDownloadURL(nombreFoto)
        .then((url) => {
          const docRef = doc(firestore, `TorneosTemporales/${idTorneo}`);
          try {
            updateDoc(docRef, {convocatoria: url});
            toast.success('La convocatoria ha sido cargada con éxito.');
          } catch (error) {
            toast.error('UPS! Ocurrio un error. Intentalo de nuevo.');
          }
        })
        .catch(() => {
          toast.error(
            "¡¡ups!! Ocurrió un error al mostrar la convocatoria, prueba a elegir la convocatoria o súbela de nuevo."
          );
        });
    })
    .catch(() => {
      toast.error(
        "¡¡ups!! Ocurrió un error al cargar la convocatoria, intente nuevamente o cargue otra convocatoria."
      );
    });
}

/* La funcion eliminar nos ayudara a borrar las fotos y archivos del usuario, pero antes debera reutenticarse
para poder eliminar. */
export async function traerArchivos(id, setArchivos) {
  const nombreFoto = ref(storage, `${id}`);
  const archivos = [];

  try {
    const item = await listAll(nombreFoto);

    for (const pre of item.prefixes) {
      const auxRuta = ref(storage, `${pre.fullPath}`);
      const ruta = await listAll(auxRuta);

      for (const i of ruta.items) {
        const downloadURL = await getDownloadURL(i);
        archivos.push({
          name: i.name, // Nombre del archivo
          url: downloadURL, // URL del archivo
          ruta: i.fullPath,
          tipo: pre.name
        });
      }
    }

    setArchivos(archivos);
  } catch (error) {
    console.error("Error al listar archivos:", error);
  }
}

//funcion para elminar foto de curso
export function eliminarArchivo(id, tipo, file) {
  const nombreFoto = ref(storage, `${id}/${tipo}/${file}`);
  deleteObject(nombreFoto)
    .then(() => {
      toast.success("El archivo se ha sido eliminada con éxito.");
    })
    .catch(() => {
      toast.error(
        "Se produjo un error al eliminar el archivo, inténtalo de nuevo."
      );
    });
}

//------------------- fin configuracion de fotos y documentos ---------------
// -------------------- configuracion cierre de inscripciones ---------------------

export function cerrarInscripcionesTorneo(id, inscripcionesCerradas, cerradoManual){
  const docRef = doc(firestore, `TorneosTemporales/${id}`);
  try {
    updateDoc(docRef, {inscripcionesCerradas});
    if(cerradoManual){
      toast.success(inscripcionesCerradas ? 'Inscripciones cerradas correctamente.' : 'Inscripciones abierta correctamente.');
    }
  } catch (error) {
    toast.error('UPS! Ocurrio un error. Intentalo de nuevo.');
  }
}

// -------------------- fin configuracion cierre de inscripciones ---------------------
// -------------------- configuracion reinicio torneo -------------------------
export async function reinicioTotalTorneo(id){
  eliminarBaseDeDatosLocal(id);
  const docuRef = doc(firestore, `TorneosTemporales/${id}`);
  const subColeccionClubes = collection(docuRef, 'clubes');
  const subColeccionPlanilla = collection(docuRef, 'planilla');
  const subColeccionTiempos = collection(docuRef, 'tiempos');
  const docSnapClubes = await getDocs(subColeccionClubes);
  const docSnapPlanilla = await getDocs(subColeccionPlanilla);
  const docSnapTiempos = await getDocs(subColeccionTiempos);

  try {
    docSnapClubes.forEach(async (sub)=>{
      if(sub.exists()){
        await deleteDoc(sub.ref);
      }
    })
    docSnapPlanilla.forEach(async (sub)=>{
      if(sub.exists()){
        await deleteDoc(sub.ref);
      }
    })
    docSnapTiempos.forEach(async (sub)=>{
      if(sub.exists()){
        await deleteDoc(sub.ref);
      }
    })

    reiniciarResultadosFamilia(id);

    toast.success('Reiniciando el torneo..., esto puede tardar unos segundos o minutos.');
  } catch (error) {
    toast.error('UPS! Ocurrio un error. Intentalo de nuevo.');
  }
}
// -------------------- fin configuracion reinicio torneo ------------------------
// ------------------- configuracion inscripciones relevos ----------------
export async function registrarRelevo(id, relevos, idForanea, modo){
  const docuRef = doc(firestore, `TorneosTemporales/${id}`, `clubes/${idForanea}`);
  updateDoc(docuRef, {relevos}).then(() =>{
    if(modo === 'registrar'){
      toast('El relevo se ha registrado correctamente.',{
        icon: '🏊🏊‍♀️'
      });
    }else{
      toast('El relevo se ha eliminado correctamente.',{
        icon: '🏊🏊‍♀️'
      });
    }
  }).catch(() =>{
    toast.error('UPS!! Ocurrio un error, intentalo de nuevo o comunicate con el administrador de la pagina.');
  })
}

export async function traerRelevos(idTorneo, setNadadoresRelevo){
  if(idTorneo !== ''){
    const docRef = collection(firestore, `TorneosTemporales/${idTorneo}/clubes`);
    const docSnap = await getDocs(docRef);
    const nadadoresRelevo = [];
    const auxNadadoresRelevo = docSnap.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data().relevos);
    auxNadadoresRelevo.forEach((auxNad)=>{
      auxNad.forEach((nad)=>{
        nadadoresRelevo.push(nad);
      })
    })
    setNadadoresRelevo(nadadoresRelevo);
  }
}

//Esta funcion nos ayudara a traer los nadadores de todos los clubes.
export async function traerNadadoresRelevos(idTorneo, setNadadores){
  if(idTorneo !== ''){
    const docRef = collection(firestore, `TorneosTemporales/${idTorneo}/clubes`);
    const docSnap = await getDocs(docRef);
    const nadadores = [];
    const auxNadadores = docSnap.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data().nadadores);
    auxNadadores.forEach((auxNad)=>{
      auxNad.forEach((nad)=>{
        nadadores.push(nad);
      })
    })
    setNadadores(nadadores);
  }
}

export async function traerPlanillaRelevos(id, idPlanilla){
  if(id !== ''){
    const docRef = doc(firestore, `TorneosTemporales/${id}/planilla/${idPlanilla}`);
    const docSnap = await getDoc(docRef);
    let auxPlanilla;
    if(docSnap.exists()){
      auxPlanilla = docSnap.data();
    }
    return auxPlanilla;
  }
}

export async function registrarPlanillaRelevos(id, idPlanilla, planilla){
  if(id !== ''){
    const docRef = doc(firestore, `TorneosTemporales/${id}/planilla/${idPlanilla}`);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()){
      updateDoc(docRef, {planilla: planilla.planilla}).then(()=>{
        toast.success("Relevo registrado correctamente.");
      })
    }else{
      setDoc(docRef, {id: parseInt(idPlanilla), planilla: planilla.planilla}).then(()=>{
        toast.success("Relevo registrado correctamente.");
      })
    }
  }
}
// ------------------- fin configuracion inscripciones relevos ----------------
// -------------------  configuracion inscripciones PSC(Pruebas, Series, Carriles) ----------------
export async function traerPlanillaPSC(id, idPlanilla){
  if(id !== ''){
    const docRef = doc(firestore, `TorneosTemporales/${id}/planilla/${idPlanilla}`);
    const docSnap = await getDoc(docRef);
    let auxPlanilla;
    if(docSnap.exists()){
      auxPlanilla = docSnap.data();
    }
    return auxPlanilla;
  }
}

export async function registrarPlanillaPSC(id, idPlanilla, planilla){
  if(id !== ''){
    const docRef = doc(firestore, `TorneosTemporales/${id}/planilla/${idPlanilla}`);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()){
      updateDoc(docRef, {planilla: planilla.planilla}).then(()=>{
        toast.success(`Prueba ${idPlanilla} actualizada correctamente.`);
      })
    }
  }
}
// ------------------- fin configuracion inscripciones PSC(Pruebas, Series, Carriles) ----------------
//----------------------- configuracion enviar y validar datos a prowebsports --------------
async function validarActivacionPlataforma(nombreclub){
  const docuRef = doc(firestoreRankingPws, `clientes/${nombreclub}`);
  const docSnap = await getDoc(docuRef);
  return docSnap.data().pf;
}

export async function edicionesDelTorneo(idTorneo, nombreclub, ediccion){
  const docuRef = doc(firestoreRankingPws, `clientes/${nombreclub}`);
  const docSnap = await getDoc(docuRef);
  if(docSnap.exists()){
    const torneoEditado = docSnap.data().torneos.filter((torn)=>{return torn.id === idTorneo});
    torneoEditado[0].titulo = ediccion.titulo;
    torneoEditado[0].fecha = ediccion.fechaTorneo;
    torneoEditado[0].fechaF = ediccion.fechaTorneoF;
    torneoEditado[0].publicidad = ediccion.publicidad;
    torneoEditado[0].fechaInscripciones = ediccion.fechaInscripciones;
    const torneosNoEditados = docSnap.data().torneos.filter((torn)=>{return torn.id !== idTorneo});
    const auxTorneos = [...torneoEditado, ...torneosNoEditados];
    updateDoc(docuRef, {torneos: auxTorneos});
  }
}

export async function torneoRegistradoPWS(id, club, titulo, fecha, fechaF, publicidad, fechaInscripciones) {
  const clubMinus = club.toLowerCase();
  let nombreclub = '';
  if (clubMinus.indexOf(' ') !== -1) {
    nombreclub = clubMinus.replace(/ /g, "-");
  } else {
    nombreclub = clubMinus;
  }

  const auxTorneo = {
    id,
    titulo, 
    fecha,
    fechaF,
    publicidad,
    fechaInscripciones,
    pagado: false,
    deportistas: "0"
  }

  if(nombreclub !== ''){
    const docuRef = doc(firestoreRankingPws, `clientes/${nombreclub}`);
    const docSnap = await getDoc(docuRef);
    if(docSnap.exists()){
      const torneosGuardados = [...docSnap.data().torneos, auxTorneo];
      updateDoc(docuRef, {torneos: torneosGuardados});
    }
  }
}

export async function deportistaRegistradoPWS(club, idTorneo) {
  const clubMinus = club.toLowerCase();
  let nombreclub = '';
  if (clubMinus.indexOf(' ') !== -1) {
    nombreclub = clubMinus.replace(/ /g, "-");
  } else {
    nombreclub = clubMinus;
  }
  const docuRef = doc(firestoreRankingPws, `clientes/${nombreclub}`);
  const docSnap = await getDoc(docuRef);
  if(docSnap.exists()){
    const auxTorneoGuardados = docSnap.data().torneos.filter((tor)=>{return tor.id !== idTorneo});
    const auxTorneoActual = docSnap.data().torneos.filter((tor)=>{return tor.id === idTorneo});
    auxTorneoActual[0].deportistas = (parseInt(auxTorneoActual[0].deportistas) + 1) + "";
    const torneos = [...auxTorneoGuardados, ...auxTorneoActual];

    updateDoc(docuRef, {torneos});
  }
}

async function deportistaRegistradoFinalesPWS(club, idTorneo, cantDeport) {
  const clubMinus = club.toLowerCase();
  let nombreclub = '';
  if (clubMinus.indexOf(' ') !== -1) {
    nombreclub = clubMinus.replace(/ /g, "-");
  } else {
    nombreclub = clubMinus;
  }
  const docuRef = doc(firestoreRankingPws, `clientes/${nombreclub}`);
  const docSnap = await getDoc(docuRef);
  if(docSnap.exists()){
    const auxTorneoGuardados = docSnap.data().torneos.filter((tor)=>{return tor.id !== idTorneo});
    const auxTorneoActual = docSnap.data().torneos.filter((tor)=>{return tor.id === idTorneo});
    auxTorneoActual[0].deportistas = (parseInt(auxTorneoActual[0].deportistas) + cantDeport) + "";
    const torneos = [...auxTorneoGuardados, ...auxTorneoActual];

    updateDoc(docuRef, {torneos});
  }
}

export async function deportistaEliminadoPWS(club, idTorneo) {
  const clubMinus = club.toLowerCase();
  let nombreclub = '';
  if (clubMinus.indexOf(' ') !== -1) {
    nombreclub = clubMinus.replace(/ /g, "-");
  } else {
    nombreclub = clubMinus;
  }
  const docuRef = doc(firestoreRankingPws, `clientes/${nombreclub}`);
  const docSnap = await getDoc(docuRef);
  if(docSnap.exists()){
    const auxTorneoGuardados = docSnap.data().torneos.filter((tor)=>{return tor.id !== idTorneo});
    const auxTorneoActual = docSnap.data().torneos.filter((tor)=>{return tor.id === idTorneo});
    auxTorneoActual[0].deportistas = (parseInt(auxTorneoActual[0].deportistas) - 1) + "";
    const torneos = [...auxTorneoGuardados, ...auxTorneoActual];

    updateDoc(docuRef, {torneos});
  }
}

//----------------------- fin configuracion enviar y validar datos a prowebsports --------------
//---------------------- configuracion eventos finales ----------------------
let unsubscriboFinales = null;

export function traerFinales(idFinales, setFinales) {
  unsubscriboFinales = onSnapshot(doc(firestore, `TorneosTemporales/${idFinales}`), (docc) => {
    const auxTorneo = docc.data();
    setFinales(auxTorneo);
  });
}

// Función para cancelar la suscripción
export function cancelarSuscripcionFinales() {
  if (unsubscriboFinales) {
    unsubscriboFinales();
    unsubscriboFinales = null;
  }
}

function sacarCategoriaInscita(listPruebas, pruebasId, edadRegistrada, sexo){ 
  let auxCategoria = new Set();
  pruebasId.forEach((idsPrue)=>{
    const auxListPruebas = listPruebas.filter((listPrue)=>{return listPrue.id === idsPrue});
    if(auxListPruebas.length !== 0){
      auxListPruebas.forEach((lisPrue)=>{
        const relevo = lisPrue.prueba.match(/Relevo/g);
        if(!relevo){
          lisPrue.requisitos.forEach((req)=>{
            if(req.genero === 'Mixto'){
              if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
                auxCategoria.add(lisPrue.categoria);
              }
            }else{
              if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada && req.genero === sexo){
                auxCategoria.add(lisPrue.categoria);
              }
            }
          })
        }
      })
    }
  })
  return Array.from(auxCategoria);
}

export function sacarEdadDeLaCategoria(categoria, edadRegistrada, listadoCategorias, listPruebas, pruebasId){
  let categoriaAsignada = `${edadRegistrada}`;
  categoria.forEach((cate)=>{
    const auxCategoria = listadoCategorias.filter((listCate)=>{return listCate.valor === cate});
    const auxPrueba = listPruebas.filter((listPrue)=>{return listPrue.categoria === cate});
    if(auxPrueba.length !== 0){
      pruebasId.forEach((prueId)=>{
        const auxPruebaId = auxPrueba.filter((auxPrue)=>{return auxPrue.id === prueId});
        if(auxPruebaId.length !== 0){
          auxPruebaId[0].requisitos.forEach((req)=>{
            if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
              if(auxCategoria[0].premiacion === 'separados'){
                if(auxCategoria[0].grupos.length !== 0){
                  const auxGrupo = auxCategoria[0].grupos.filter((gru)=>{return parseInt(gru.edadInicio) <= edadRegistrada && parseInt(gru.edadFin) >= edadRegistrada});
                  if(auxGrupo.length !== 0){
                    categoriaAsignada = auxGrupo[0].nombre;
                  }
                }else{
                  categoriaAsignada = `${req.edadInicio === req.edadFin ? req.edadInicio : req.edadInicio + ' - ' + req.edadFin}`;
                }
              }else{
                categoriaAsignada = `${req.edadInicio === req.edadFin ? req.edadInicio : req.edadInicio + ' - ' + req.edadFin}`;
              }
            }
          })
        } 
      })
    }
  })
  return categoriaAsignada;
}

function sacarClasificados(prueba, finales){
  let pruebaConMMinimas;
  const auxClasificados = {};
  finales.forEach((jor)=>{
    pruebaConMMinimas = jor.pruebas.filter((prue)=>{return prue.prueba === prueba.prueba.prueba})[0];
  })

  prueba.nadadores.forEach((nad)=>{
    const auxObjNad = {
      nombreclub: nad.nombreclub,
      identificacion: nad.identificacion,
      pruebas: [pruebaConMMinimas.idPrueba],
      records: [{
        prueba: pruebaConMMinimas.idPrueba,
        record: nad.tiempo
      }]
    }

    const nombreclub = nad.nombreclub;
    if (!auxClasificados[nombreclub]) {
        auxClasificados[nombreclub] = [];
    }
    auxClasificados[nombreclub].push(auxObjNad);
  })

  return auxClasificados;
}

async function sacarIdClub(idTorneo, prueba, finales, listadoCategorias, listPruebas){
  const torneo = doc(firestore, `TorneosTemporales/${idTorneo}`);
  const clubes = collection(torneo, 'clubes');
  const auxObjClubes = sacarClasificados(prueba, finales);
  const auxClubes = [];
  for(let nombreclub in auxObjClubes){
    const auxNadadores = [];
    const consultaTorneo = query(clubes, where("nombreclub", '==', `${nombreclub}`));
    const queryClubes = await getDocs(consultaTorneo);
    const auxClub = queryClubes.docs.filter((club)=> club.exists).map((club)=> club.data());
    auxObjClubes[nombreclub].forEach((club)=>{
      const auxNadador = auxClub[0].nadadores.filter((nad)=>{return nad.identificacion === club.identificacion})[0];
      auxNadador.pruebas = club.pruebas;
      auxNadador.records = club.records;
      auxNadador.categoria = sacarEdadDeLaCategoria(sacarCategoriaInscita(listPruebas, auxNadador.pruebas, auxNadador.edad, auxNadador.genero), auxNadador.edad, listadoCategorias, listPruebas, auxNadador.pruebas);
      auxNadadores.push(auxNadador);
    })
    auxClub[0].nadadores = auxNadadores;
    auxClubes.push(auxClub[0]);
  }
  return auxClubes;
}

export async function registrarClasificados(idTorneo, idFinales, prueba, finales, listadoCategorias, listPruebas){
  const auxClubes = await sacarIdClub(idTorneo, prueba, finales, listadoCategorias, listPruebas);
  auxClubes.forEach(async(club)=>{
    const docuRef = doc(firestore, `TorneosTemporales/${idFinales}/clubes/${club.idClub}`);
    const docSnap = await getDoc(docuRef);
    if(docSnap.exists()){
      const nadadoresGuardados = docSnap.data().nadadores;
      const auxNadadoresClasificados = [];

      const auxNadadoresGuardados = nadadoresGuardados.filter(nadB => !club.nadadores.some(nad => nadB.identificacion === nad.identificacion));
      auxNadadoresClasificados.push(...auxNadadoresGuardados);

      club.nadadores.forEach((nad)=>{
        const auxNadadores = nadadoresGuardados.filter((nadB)=>{return nadB.identificacion === nad.identificacion})[0];
        if(auxNadadores){
          nad.records.forEach((prue)=>{
            const auxRecords = auxNadadores.records.filter((prueB)=>{return prueB.prueba !== prue.prueba});
            if(auxRecords.length !== 0){
              const idsPruebas = [];

              auxRecords.forEach((auxR)=>{
                idsPruebas.push(auxR.prueba);
              })

              nad.pruebas = [...idsPruebas, ...nad.pruebas];
              nad.records = [...auxRecords, ...nad.records];
            }
          })

          auxNadadoresClasificados.push(nad);
        }else{
          auxNadadoresClasificados.push(nad);
        }
      })

      if(auxNadadoresClasificados.length !== 0){
        updateDoc(docuRef, {nadadores: auxNadadoresClasificados}).then(() =>{
          let auxCantDeport = 0;
          club.nadadores.forEach((nad)=>{
            const auxNadadores = nadadoresGuardados.filter((nadB)=>{return nadB.identificacion === nad.identificacion});
            if(auxNadadores.length === 0){
              auxCantDeport++;
            }
          })
          deportistaRegistradoFinalesPWS(datosAdmin.conexion, idFinales, auxCantDeport);
        });
      }
    }else{
      setDoc(docuRef, club).then(() =>{
        deportistaRegistradoFinalesPWS(datosAdmin.conexion, idFinales, club.nadadores.length);
      });
    }
  })
}

//---------------------- fin configuracion eventos finales ----------------------
//---------------------- configuracion categorias firebase ----------------------
export async function registrarCategoria(categorias){
  const docRef = doc(firestore, `categorias/lWXRreZYu66eC6rgzy6r`);
  const docSnap = await getDoc(docRef)
  if(!docSnap.exists()){
    setDoc(docRef, {categorias});
    toast.success('Categorias registradas correctamente.');
  }else{
    updateDoc(docRef, {categorias});
    toast.success('Categorias Actualizadas correctamente.');
  }
}

let unsubscribeCategoria = null;
export async function traerListadoCategoria(setListadoCategorias){
  const docRef = collection(firestore, `categorias`);
  unsubscribeCategoria = onSnapshot(docRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data())

      setListadoCategorias(resultados[0].categorias);
  });
}

export function cancelarCategoria(){
  if (unsubscribeCategoria) {
    unsubscribeCategoria();
    unsubscribeCategoria = null;
  }
}
//---------------------- fin configuracion categorias firebase ------------------
//---------------------- configuracion resultados familia ----------------------
//acciones al ingresar via qr
export function ingresarViaQrCode(usuario, setDialogResultados){
  if(!usuario){
    setDialogResultados(true);
  }
}

//tiempos Registrar
export async function registrarTorneoFamilia(idTorneo, torneoEspecifico){
  const docRef = doc(firestoreFamilia, `${datosAdmin.conexion}/${idTorneo}`);
  const docSnap = await getDoc(docRef)
  if(!docSnap.exists()){
    setDoc(docRef, {titulo: torneoEspecifico.titulo});
  }else{
    updateDoc(docRef, {titulo: torneoEspecifico.titulo});
  }
}

export async function registrarTiemposFamilia(idTorneo, idTiempo, prueba){
  const docRef = doc(firestoreFamilia, `${datosAdmin.conexion}/${idTorneo}/tiempos/${idTiempo}`);
  const docSnap = await getDoc(docRef)
  if(!docSnap.exists()){
    setDoc(docRef, prueba);
  }else{
    updateDoc(docRef, prueba);
  }
}

//operacion para traer los resultados en tiempo real.
let unsubscribeTiemposFamilia = null;

export async function traerTitulosTorneosFamilia(setTitulosTorneos){
  const tiemposCollectionRef = collection(firestoreFamilia, `${datosAdmin.conexion}`);
  
  unsubscribeTiemposFamilia = onSnapshot(tiemposCollectionRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => {
        const data = doc.data();
        data.idTorneo = doc.id;
        return data;
      });

      setTitulosTorneos(resultados);
  });
}

export async function traerResultadosFamilia(idTorneo, setResultados){
  const tiemposCollectionRef = collection(firestoreFamilia, `${datosAdmin.conexion}/${idTorneo}/tiempos`);
  
  unsubscribeTiemposFamilia = onSnapshot(tiemposCollectionRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data())
      .sort((a, b) => a.id - b.id);

      setResultados(resultados);
  });
}

export function cancelarTiemposResultadosFamilia(){
  if (unsubscribeTiemposFamilia) {
    unsubscribeTiemposFamilia();
    unsubscribeTiemposFamilia = null;
  }
}

export async function reiniciarResultadosFamilia(idTorneo){
  const docRef = doc(firestoreFamilia, `${datosAdmin.conexion}/${idTorneo}`);
  const subColeccionTiempos = collection(docRef, 'tiempos');
  const docSnapTiempos = await getDocs(subColeccionTiempos);
  docSnapTiempos.forEach(async (sub)=>{
    if(sub.exists()){
      await deleteDoc(sub.ref);
    }
  })
}
//---------------------- fin configuracion resultados familia ----------------------
//---------------------- configuracion reacciones ----------------------
export async function registrarVisualizaciones(idTorneo){
  const docRef = doc(firestoreFamilia, `${datosAdmin.conexion}/${idTorneo}`);
  const docSnap = await getDoc(docRef);
  if(docSnap.exists()){
    updateDoc(docRef, {visualizaciones: parseInt(!docSnap.data().visualizaciones ? 1 : docSnap.data().visualizaciones + 1)});
  }
}

export async function registrarReaccion(reacciones){
  const docRef = doc(firestoreFamilia, `reaccion/reacciones`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){   
    updateDoc(docRef, reacciones);
  }else{
    setDoc(docRef, reacciones);
  }
}

export async function traerReacciones(setReacciones){
  const tiemposCollectionRef = collection(firestoreFamilia, `reaccion`);
  
  onSnapshot(tiemposCollectionRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data())

      setReacciones(...resultados);
  });
}
//---------------------- fin configuracion reacciones ----------------------
//---------------------- configuracion notificaciones ----------------------
const notificacion = async (token, body, anfitrion) =>{
  // URL de la función de Firebase
  const functionUrl = 'https://sendnotification-6fbfrucvna-uc.a.run.app/sendNotification';

  // Token FCM del dispositivo y detalles de la notificación
  const deviceToken = token.token;
  const notificationTitle = anfitrion;
  const notificationBody = body;
  try {
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: deviceToken,
        title: notificationTitle,
        body: notificationBody,
        image: 'https://firebasestorage.googleapis.com/v0/b/prowebsports.appspot.com/o/HI6odVOH7VMfigHbeDN1v90wjHF2%2Fperfil%2Fmarca%20de%20agua.png?alt=media&token=8eb496c4-16b4-4f94-a820-dc89272f0c8d',
        plataforma: datosAdmin.plataforma
      })
    });

    if (response.ok) {
      await response.json();
    } else {
      await response.json();
    }
  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
}

export const requestNotificationPermission = async (usuario) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Obtener el token de FCM
      if(!localStorage.getItem('idNotificacion')){
        const token = await getToken(messaging, { vapidKey: "BGCcYZV4qjRot9baYQEnke2hGxckV2ajaw1FRrS1Mel1baUP53JtYOIVO-ahjGMwtFg4-KsjLVHf2s1xmND-QR4" });
        if (token) {
          guardarInfoUsuario(usuario, token);
        }

        localStorage.setItem('idNotificacion', token);
      }
    }else{
      if(localStorage.getItem('idNotificacion')){
        localStorage.removeItem('idNotificacion');
      }
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

async function guardarInfoUsuario(usuario, token) {
  const docRef = doc(firestoreRankingPws, `notificaciones/${usuario.id}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){   
    setDoc(docRef, {tokens: [{token, plataforma: datosAdmin.conexion, nombreclub: usuario.nombreclub, dispositivo: detectarDispositivo()}]});
  }else{
    const auxTokens = docSnap.data().tokens;
    const buscarToken = auxTokens.findIndex((auxTok)=>{return auxTok.plataforma === datosAdmin.conexion && auxTok.dispositivo === detectarDispositivo()});
    if(buscarToken !== -1){
      auxTokens[buscarToken] = {token, plataforma: datosAdmin.conexion, nombreclub: usuario.nombreclub, dispositivo: detectarDispositivo()};
    }else{
      auxTokens.push({token, plataforma: datosAdmin.conexion, nombreclub: usuario.nombreclub, dispositivo: detectarDispositivo()});
    }
    updateDoc(docRef, {tokens: auxTokens});
  }
}

async function traerTokensNotificacionInscripciones() {
  const docRef = collection(firestoreRankingPws, `notificaciones`);
  const docSnap = await getDocs(docRef);
  const auxTokens = [];
  docSnap.forEach((docc)=>{
    docc.data().tokens.forEach((doccTok)=>{
      if(doccTok.plataforma === datosAdmin.conexion){
        auxTokens.push(doccTok);
      }
    })
  })
  return auxTokens;
}

async function traerTokensNotificaciones() {
  const docRef1 = collection(firestoreRankingPws, `notifiTorneos`);
  const docRef2 = collection(firestoreRankingPws, `notificaciones`);
  const docSnap1 = await getDocs(docRef1);
  const docSnap2 = await getDocs(docRef2);
  const auxTokens = [];
  docSnap1.forEach((docc)=>{
    auxTokens.push(docc.data());
  })
  docSnap2.forEach((docc)=>{
    docc.data().tokens.forEach((doccTok)=>{
      auxTokens.push(doccTok);
    })
  })
  return auxTokens;
}

onMessage(messaging, (payload) => {
  const { title, body, image, plataforma } = payload.data;
  const auxTituloBody = body.split('•');
  const alerta = Swal.mixin({
    confirmButtonText: "¡Quiero Participar!",
    confirmButtonColor: 'rgb(40, 167, 69)',
    showCancelButton: true,
    cancelButtonText: 'Salir'
  })
  alerta.fire({
    title,
    html: `<p class="text-start"><b>${auxTituloBody[0]}</b><br/>
    ${auxTituloBody[1]}<br/>
    ${auxTituloBody[2]}<br/>
    ${auxTituloBody[3]}<br/>
    ${auxTituloBody[4]}<br/>
    ${auxTituloBody[5]}<br/>
    ${auxTituloBody[6]}<br/>
    ${auxTituloBody[7]}<br/>
    ${auxTituloBody[8]}<br/>
    ${auxTituloBody[9]}</p>`
  }).then((result)=>{
    if(result.isConfirmed){
      window.open(plataforma, '_blank');
    }
  })
});

function detectarDispositivo() {
  const userAgent = navigator.userAgent.toLowerCase();
  
  if (userAgent.includes('mobile')) {
    return 'movil';
  } else {
    return 'pc';
  }
}
//---------------------- fin configuracion notificaciones ----------------------
//---------------------- configuracion info usuarios de prowebsports ----------------------
export async function registrarUsuarioPws(datosUsuario){
  const docRef = doc(firestoreRankingPws, `usuarios/${datosUsuario.id}`);
  setDoc(docRef, {
    nombreclub: datosUsuario.nombreclub,
    abreviatura: datosUsuario.abreviatura,
    departamento: datosUsuario.departamento,
    email: datosUsuario.email
  });
}

export async function traerUsuariosPws(datosUsuario){
  const docRef = doc(firestoreRankingPws, `usuarios/${datosUsuario.id}`);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data() : null;
}
//---------------------- fin configuracion info usuarios de prowebsports ------------------
// ------------------ configuracion para records Mundiales y Nacionales ----------------------
export async function traerRecords(setRecordsMundial, setRecordsNacional){
  const mundial = doc(firestoreRankingPws, `records/mundial`);
  const nacional = doc(firestoreRankingPws, `records/colombia`);
  const docMundial = await getDoc(mundial);
  const docNacional = await getDoc(nacional);
  if(docMundial.exists()){
    setRecordsMundial(docMundial.data());
  }
  if(docNacional.exists()){
    setRecordsNacional(docNacional.data());
  }
}
// ------------------ fin configuracion para records Mundiales y Nacionales ----------------------
// ------------------ configuracion para enviar correos ----------------------
export const sendEmailDocumento = async (pdfBase64, asunto, text, emails, nombreArchivo) => {
  const url = "https://sendemaildocumento-6fbfrucvna-uc.a.run.app/sendEmailDocumento";

  const payload = {
    emails: emails,
    subject: asunto,   
    text: text, 
    pdfBase64: pdfBase64,
    fileName: nombreArchivo
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    const data = await response.json();
    if (response.ok) {
      console.log("Correo enviado exitosamente:", data);
    } else {
      console.error("Error al enviar el correo:", data);
    }
  } catch (error) {
    console.error("Error en la solicitud:", error);
  }
};

export const sendEmail = async (asunto, text, emails) => {
  const url = "https://sendgmail-6fbfrucvna-uc.a.run.app/sendGmail";

  const payload = {
    emails: emails,
    subject: asunto,   
    text: text,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    const data = await response.json();
    if (response.ok) {
      console.log("Correo enviado exitosamente:", data);
    } else {
      console.error("Error al enviar el correo:", data);
    }
  } catch (error) {
    console.error("Error en la solicitud:", error);
  }
};
// ------------------ fin configuracion para enviar correos ----------------------
//---------------------- configuracion cronometros ----------------------
// Función para registrar datos
export function registrarCronometro(id, datosCronometro) {
  // Referencia a la ubicación específica en Realtime Database
  const cronometroRef = refDatabase(database, `carril${id}`);

  // Establece los datos en la ubicación especificada
  set(cronometroRef, datosCronometro)
  .then(() => {
    console.log('Datos registrados correctamente en Firebase');
  })
  .catch((error) => {
    console.error('Error registrando datos en Firebase:', error);
  });
}

export function traerEstadosCronometros(setDatosCronometros, id){
  const starCountRef = refDatabase(database, `carril${id}`);
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    if(data !== null){
      setDatosCronometros(prevDatos => ({
        ...prevDatos,
        [data.carril]: data
      }));
    }
  });
}
//---------------------- fin configuracion cronometros ----------------------








//---------------------- configuracion logistica --------------------------
//Esta funcion nos ayudara a modificar los datos del club.
export async function registrarDatosAfiliado(afiliado, ti){
  const docRef = doc(firestoreLogistica, `afiliados/${ti}`);
  const docSnap = await getDoc(docRef);
  if(docSnap.exists()){
    updateDoc(docRef, afiliado
    ).then(() =>{
      toast.success('Deportista renovado correctamente.');
    });
  }else{
    setDoc(docRef, afiliado
    ).then(() =>{
      toast.success('Deportista afiliado correctamente.');
    });
  }
}

export function registrarAcum(acum, ti, observ){
  const docRef = doc(firestoreLogistica, `afiliados/${ti}`);
  updateDoc(docRef, {acum: acum + '', observ}
  ).then(() =>{
    toast.success('Saldo Actualizado correctamente.');
  });
}

export function registrarPagado(afi, ti, index){
  const docRef = doc(firestoreLogistica, `afiliados/${ti}`);
  afi.hist[index].title = 'Pagado';
  updateDoc(docRef, {hist: afi.hist}
  ).then(() =>{
    toast.success('Historial Actualizado correctamente.');
  });
}

export function registrarDebiendo(afi, ti, index){
  const docRef = doc(firestoreLogistica, `afiliados/${ti}`);
  afi.hist[index].title = 'Debiendo';
  updateDoc(docRef, {hist: afi.hist}
  ).then(() =>{
    toast.success('Historial Actualizado correctamente.');
  });
}

export function registrarNinguno(afi, ti, index){
  const docRef = doc(firestoreLogistica, `afiliados/${ti}`);
  afi.hist[index].title = 'Ninguno';
  updateDoc(docRef, {hist: afi.hist}
  ).then(() =>{
    toast.success('Historial Actualizado correctamente.');
  });
}

export function traerAfiliados(setAfiliados, precios){
  const afiliadosCollectionRef = collection(firestoreLogistica, `afiliados`);

  onSnapshot(afiliadosCollectionRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data())
      .sort((a, b) => parseInt(a.edad) - parseInt(b.edad));

      resultados.forEach((afi)=>{
        const fechaActual = new Date().getTime();
        const fechaPago = new Date(afi.fechaPago).getTime();
        const distancia = fechaPago - fechaActual;

        // Convertir la diferencia de milisegundos a días
        const distanciaEnDias = distancia / (1000 * 60 * 60 * 24);

        // Calcular el número aproximado de meses
        const mesesFaltantes = Math.floor(distanciaEnDias / 30);
        if(afi.afiliado){
          if(mesesFaltantes <= 0){
            let mesesDebe = 1;
            afi.hist.forEach((his)=>{
              if(his.title === 'Debiendo'){
                mesesDebe++;
              }
            })
            mesesDebe = -mesesDebe; 
            if(mesesDebe >= mesesFaltantes){
              afi.totalAPagar = Math.abs(mesesDebe * ((precios ? precios.mensu : 0)));
              if(afi.totalAPagar > 0){
                afi.pagoMensu = 'No';
              }
            }else{
              afi.totalAPagar = Math.abs(mesesFaltantes * ((precios ? precios.mensu : 0)));
              if(afi.totalAPagar > 0){
                afi.pagoMensu = 'No';
              }
            }
          }else{
            afi.totalAPagar = 0;
          }
        }else{
          let mesesDebe = 0;
          afi.hist.forEach((his)=>{
            if(his.title === 'Debiendo'){
              mesesDebe++;
            }
          })
          afi.totalAPagar = Math.abs(mesesDebe * ((precios ? precios.mensu : 0)));
        }
      })

    setAfiliados(resultados);
  });
}

export async function retirarAfiliacion(afi){
  const afiliadosCollectionRef = doc(firestoreLogistica, `afiliados/${afi.ti}`);
  const docSnap = await getDoc(afiliadosCollectionRef);
  if(docSnap.exists()){
    updateDoc(afiliadosCollectionRef, {
      afiliado: false
    })
  }else{
    toast.error('¡UPS! ocurrio un error. Por favor intentalo de nuevo.');
  }
}

export function eliminarAfiliado(ti, setSelectedAfiliado){
  const afiliadosCollectionRef = doc(firestoreLogistica, `afiliados/${ti}`);
  deleteDoc(afiliadosCollectionRef).then(()=>{
    toast.success('Afiliado eliminado correctamente.');
    setSelectedAfiliado(null);
  });
}

//Esta funcion nos ayudara a modificar los datos de la numeracion factura.
export async function registrarNumeracionFactura(numeracion){
  const date = new Date();
  const anio = date.getFullYear();
  const docRef = doc(firestoreLogistica, `nFactura/${anio + ''}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, {factura: numeracion}
    ).then(() =>{
      toast.success('Numeración registrada correctamente.');
    });
  }else{
    updateDoc(docRef, {factura: numeracion}
    ).then(() =>{
      toast.success('Numeración actualizada correctamente.');
    });
  }
}

export async function registrarPrecios(precios){
  const docRef = doc(firestoreLogistica, `precios/jOHmeqaTL2oyP7YDGfLe`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, precios
    ).then(() =>{
      toast.success('Precios registrados correctamente.');
    });
  }else{
    updateDoc(docRef, precios
    ).then(() =>{
      toast.success('Precios actualizados correctamente.');
    });
  }
}

export async function traerPrecios(setPreciosEstablecidos){
  const docRef = collection(firestoreLogistica, `precios`);
  onSnapshot(docRef, (snapshot)=>{
    let auxPrecios = {};
    snapshot.forEach((precio)=>{
      auxPrecios = precio.data();
    })
    setPreciosEstablecidos(auxPrecios);
  })
}

//Esta funcion nos ayudara a modificar los datos de insc. y mensual.
export async function registrarInscripYMens(descripcion, cc){
  const docRef = doc(firestoreLogistica, `afiliados/${cc}`);
  const docSnap = await getDoc(docRef);
  const fecha = new Date();
  const fechaPago = fecha.toLocaleString('en-US',{
    year: 'numeric',
    day: 'numeric',
    month: 'numeric'
  })
  let fechaFormateada = new Date(fechaPago);
  fechaFormateada.setMonth(fechaFormateada.getMonth() + 1);
  fechaFormateada.setDate(5);
  fechaFormateada = fechaFormateada.toLocaleString('en-US',{
    year: 'numeric',
    day: 'numeric',
    month: 'numeric'
  })
  if(docSnap.exists()){
    if(descripcion === 'Inscripcion'){
      updateDoc(docRef, {pagoInsc: 'Si'}
      ).then(() =>{
        toast.success('Inscripción actualizada correctamente.');
      });
    }
    
    if(descripcion === 'Mensualidad' || descripcion === 'Mensualidad y\nEntradas'){
      updateDoc(docRef, {pagoMensu: 'Si', fechaPago: fechaFormateada}
      ).then(() =>{
        toast.success('Mensualidad actualizada correctamente.');
      });
    }

    if(descripcion === 'Inscripcion y\nMensualidad' || descripcion === 'Inscripcion y\nMensualidad y\nEntradas'){
      updateDoc(docRef, {pagoInsc: 'Si', pagoMensu: 'Si', fechaPago: fechaFormateada}
      ).then(() =>{
        toast.success('Inscripción y Mensualidad actualizada correctamente.');
      });
    }
  }
}

export async function cambiarEstadoInscYOMensu(descripcion, cc, selectedAfiliado, setSelectedAfiliado){
  const docRef = doc(firestoreLogistica, `afiliados/${cc}`);
  const docSnap = await getDoc(docRef);
  if(docSnap.exists()){
    if(descripcion === 'Inscripcion'){
      updateDoc(docRef, {pagoInsc: 'Si'}
      ).then(() =>{
        selectedAfiliado.pagoInsc = 'Si';
        setSelectedAfiliado(selectedAfiliado);
        toast.success('Inscripción actualizada correctamente.');
      });
    }
    
    if(descripcion === 'Mensualidad'){
      const fecha = new Date();
      const fechaPago = fecha.toLocaleString('en-US',{
        year: 'numeric',
        day: 'numeric',
        month: 'numeric'
      })
      let fechaFormateada = new Date(fechaPago);
      fechaFormateada.setMonth(fechaFormateada.getMonth() + 1);
      fechaFormateada.setDate(5);
      fechaFormateada = fechaFormateada.toLocaleString('en-US',{
        year: 'numeric',
        day: 'numeric',
        month: 'numeric'
      })
      updateDoc(docRef, {pagoMensu: 'Si', fechaPago: fechaFormateada}
      ).then(() =>{
        selectedAfiliado.pagoMensu = 'Si';
        setSelectedAfiliado(selectedAfiliado);
        toast.success('Mensualidad actualizada correctamente.');
      });
    }
  }
}

//Esta funcion nos ayudara a traer los datos de la evaluación.
export async function traerNFactura(setNumeroFactura){
  const date = new Date();
  const anio = date.getFullYear();
  const docRef = collection(firestoreLogistica, `nFactura`);
  onSnapshot(docRef, (snapshot)=>{
    let auxNFactura = 'ninguna';
    snapshot.forEach((numeracion)=>{
      if(numeracion.exists()){
        if(numeracion.id === (anio + '')){
          auxNFactura = numeracion.data().factura;
        }
      }else{
        auxNFactura = 'ninguna';
      }
    })
    setNumeroFactura(auxNFactura);
  })
}

//funcion para asistencias
export async function registrarAsistencia(afi, asistencias, funcion){
  const afiliadosCollectionRef = doc(firestoreLogistica, `afiliados/${afi.ti}`);
  const docSnap = await getDoc(afiliadosCollectionRef);
  if(docSnap.exists()){
    updateDoc(afiliadosCollectionRef, {
      asistencias
    }).then(() =>{
      toast.success(`Asistencia ${funcion} correctamente.`);
    })
  }else{
    toast.error('¡UPS! ocurrio un error. Por favor intentalo de nuevo.');
  }
}
//---------------------- fin configuracion logistica ----------------------